<main class="main" id="top">
  <div class="container" data-layout="container">
    <div class="row flex-center min-vh-100 py-6 text-center">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
        <div class="card min-w-700px" >
          <div class="card-body p-4 p-sm-5">
            <div class="display-1 text-danger"><i class="far fa-frown"></i></div>
            <p class="lead mt-4 text-sans-serif font-weight-semi-bold mb-0">You do not have permission to view the content.</p>
            <span class="text-sans-serif fs-12">Contact you administrator if you need help</span>
            <hr />
            <a class="btn btn-primary btn-sm mt-3" [routerLink]="'/'"><span class="fas fa-chevron-left mr-2"></span>Go Back</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
