import { OnInit, Component } from '@angular/core';
import { ReqResService } from '../../service/req-res.service';
import { PaginationInstance } from 'ngx-pagination';
import { PluginService } from '../../service/plugin.service';
declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-specodes',
  templateUrl: './specodes.component.html',
  styleUrls: ['./specodes.component.css']
})
export class SpecodesComponent implements OnInit {
  loading = false;
  specode: any[];
  sortReverse: boolean;
  sortType: string = 'default';
  searchSpecode: string = '';

  specodeObj: any = {};
  specodeObjTemp: any = {};

  //del variables
  delId: number;
  delType: string;
  delCount: number;
  delBulkArray: any = [];
  delType2: string;
  specodeCount: number = 0;
  maxCount: number = 0;
  sortdirection: string = 'D';

  public _pluginservice;
  constructor(private reqRes: ReqResService, private pluginservice: PluginService) {
    this._pluginservice = this.pluginservice;
  };
  ngOnInit(): void {
    this.sortReverse = true;
    this.sortType = 'default';
    let json = {
      "Search": '',
      "Count": this.specodeCount,
      "Direction": 'D',
      "SortType": this.sortType
    };
    this.getSpecode(JSON.stringify(json), 0);
    this._pluginservice.masterSelectedRefresh('specodeBulk', []);
    this._pluginservice.checkedList = [];
  }

  searchInputOperation() {
    $("[data-toggle='tooltip']").tooltip('hide');
  }
  openFilter() {
    $(`#divSearchFilter`).removeClass("d-none")
    $(`#divSearchFilter`).addClass("d-inline-block")
    $(`#divSearchFilter`).animate({ right: '0px' }, "slow");
    $(`#btnSearchFilter`).hide();
    $(`#inputSearchFilter`).focus();
  }
  closeFilter() {
    $(`#divSearchFilter`).removeClass("d-inline-block")
    $(`#divSearchFilter`).addClass("d-none")
    $(`#btnSearchFilter`).show();
  }
  //================================================================
  changeStatus(param, id, status, param2): void {
    if (param2 == 'changeStatus') {
      status = status ? 1 : 0;
    }
    let statusObj: any = {};
    statusObj.param = param;
    statusObj.id = id;
    statusObj.status = status;
    this.reqRes.postData('/Global/ChangeStatus', statusObj).subscribe(data => {
      if (param == 'specode' && param2 == 'delete') {
        let index = this.specode.findIndex(item => item.id == id);
        this.specode.splice(index, 1);
        toastr.success('Done!', '', { timeOut: 1000 });
      }
      else if (param == 'specode' && param2 == 'changeStatus') {
      }
    }, error => {
      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  sortData(type) {
    this.sortType = type;
    this.specodeCount = 0;
    this.sortdirection = (this.sortReverse) ? 'A' : 'D';
    this.searchSpecode = (this.searchSpecode) ? this.searchSpecode : '';
    let json = {
      "Search": this.searchSpecode,
      "Count": this.specodeCount,
      "Direction": this.sortdirection,
      "SortType": this.sortType
    };
    this.getSpecode(JSON.stringify(json), 0);
  }

  getSearchData(search) {
    this.searchSpecode = (search) ? search : '';
    this.specodeCount = 0;
    this.sortType = (this.searchSpecode) ? 'scvalue' : 'default';
    let json = {
      "Search": this.searchSpecode,
      "Count": this.specodeCount,
      "Direction": (this.searchSpecode) ? 'A' : 'D',
      "SortType": this.sortType
    };
    $("div[id^=threeNo] button").prop('disabled', false);
    this.getSpecode(JSON.stringify(json), 0)
  }

  getSpecode(json, ope) {
    this.loading = true;
    let GridParametrs: any = {};
    GridParametrs.type = 'specode';
    GridParametrs.json = json;
    this.reqRes.postData('/Settings/GetSettingsViewData', GridParametrs).subscribe(data => {
      this.loading = false;
      this.specode = (ope == 0) ? this.specode = data.specodeData : this.specode.concat(data.specodeData);
      this.maxCount = data.maxCount;
    //  this._pluginservice.masterSelectedRefresh('roleBulk');
      if (this._pluginservice.checkedList.length == 1) {
        setTimeout(() => {
          $("div[id^= 'threeNo'] button").prop('disabled', true);
          $("#threeNo" + this.checkItemId + " button").prop('disabled', false);
        }, 500)
      } else if (this._pluginservice.checkedList.length > 1) {
        setTimeout(() => {
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      }
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }
  checkItemId: number = 0;
  getCheckedId(id) {
    this.checkItemId = id;
  }

  onScrollData(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight && this.specodeCount <= this.maxCount) {
      this.specodeCount = this.specodeCount + 25;
      let json = {
        "Search": this.searchSpecode,
        "Count": this.specodeCount,
        "Direction": this.sortdirection,
        "SortType": this.sortType
      };
      this.getSpecode(JSON.stringify(json), 1);
      //$("div[id^=threeNo] button").prop('disabled', false);
      //this._pluginservice.UnCheckAll(this.specode);
    }
  }

  modalDelete(delId, delType, delType2, bulkArray) {
    this.delId = delId;
    this.delType = delType;
    this.delType2 = delType2;
    this.delBulkArray = bulkArray;
    if (bulkArray.length == 0) {
      this.delCount = 1;
    } else {
      this.delCount = this._pluginservice.checkedList.length;
    }
    $('#modalDelete').modal('show');
  }

  deleteItem(id, type, type2, array) {
    $("div[id^=threeNo] button").prop('disabled', false);
    $('#modalDelete').modal('hide');
    if (array.length == 0) {
      this.changeStatus(type, id, -1, type2);
    } else {
      let ids = this._pluginservice.bulkActions(array[0]);
      ids.forEach((id) => {
        let index = this.specode.findIndex(item => item.id == id);
        this.specode.splice(index, 1);
      });
    }
    this._pluginservice.masterSelectedRefresh(`specodeBulk`);
  }

  modalDeleteClose() {
    $('#modalDelete').modal('hide');
  }
  //================================================================
  //#region Modal Specode
  modalTitle: string = '';
  editSpecode(id) {
    this.modalTitle = (id == 0) ? 'New Specode' : 'Edit Specode';
    this.reqRes.getData('/Settings/GetSettingsEditData/' + id + '/specode').subscribe(data => {
      this.specodeObj = data;
      //Clone Project Type Modal Information to Temp Obj //
      const cloneOfObject = JSON.parse(JSON.stringify(this.specodeObj));
      this.specodeObjTemp = cloneOfObject;
      /////////////////////////////////////////////
      $('#specodeModal').modal('show');
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error);
      })
  }
  modalSpecodeClose() {
    let result = this.pluginservice.compareObjects(this.specodeObj, this.specodeObjTemp, ["id"])
    if (result) {
      if (confirm('Close without saving??')) {
        $('#specodeModal').modal('hide');
      }
    }
    else {
      $('#specodeModal').modal('hide');
    }
  }
  saveSpecode() {
    this._pluginservice.masterSelectedRefresh('specodeBulk');
    $('#btnspecode').attr('disabled', 'disabled');
    this.specodeObj.order = parseInt(this.specodeObj.order);
    this.reqRes.postData('/Settings/SpecodeOperation', this.specodeObj).subscribe(data => {
      setTimeout(function () {
        $('#btnspecode').removeAttr('disabled');
      }, 1000)
      let returnedObj = data;
      if (this.specodeObj.id == 0) {
        this.specode.unshift(data);
      }
      else {
        let index = this.specode.findIndex(item => item.id == returnedObj.id);
        this.specode[index] = returnedObj;
      }
      this.specode = this.specode.sort((a, b) => a.name.localeCompare(b.name));
      toastr.success('Done!', '', { timeOut: 1000 });
      $('#specodeModal').modal('hide');
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error);
      })
  }
  changeType() {
    let index = this.specodeObj.typeList.findIndex(item => item.id == this.specodeObj.type);
    this.specodeObj.order = this.specodeObj.type == '' ? 0 : this.specodeObj.typeList[index]?.order;
    this.specodeObj.specodeTitle = this.specodeObj.type == '' ? '' : this.specodeObj.typeList[index]?.desc;
  }
  //#endregion Specode
  //================================================================
}

