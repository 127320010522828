<div class="row">
  <div class="col-12 mb-4">
    <div class="card mt-3">
      <div class="card-header">
        <div class="row align-items-center justify-content-between">
          <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
            <h5 class="mb-0 text-nowrap py-2 py-xl-0">Товары</h5>
          </div>
          <div class="col-8 col-sm-auto ml-auto text-right pl-0">
            <div class="dashboard-actions">
              <div id="divSearchFilter" class="d-inline-block position-relative mr-2">
                <input type="search" class="form-control" placeholder="Поиск..." [(ngModel)]="searchItem" (change)="getSearchData(searchItem)" (keyup)="getSearchData(searchItem)">
              </div>
              <div class="btn-group" id="itemGroupButtons" style="margin-top: -3px;">
                <button class="btn btn-success" (click)="itemOperation(0,false)"
                        data-toggle="modal" type="button">
                  <span class="fas fa-plus d-inline-block d-sm-none"
                        data-fa-transform="shrink-3 down-2"></span>
                  <span class="d-none d-sm-inline-block">
                    Новый товар
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div [style.opacity]="loading ? '0' : '1'">
          <div class="card-body p-0 card-scroll" (scroll)="onScrollData($event)">
            <div class="falcon-data-table">
              <table class="table table-sm">
                <thead class="thead-dark sticky-header">
                  <tr>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-300px" (click)="sortType = 'name';sortReverse = !sortReverse;sortData('name');">
                      Товар
                      <span *ngIf="sortType == 'name' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'name' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 align-middle min-w-30px">
                    </th>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-200px" (click)="sortType = 'group';sortReverse = !sortReverse;sortData('group');">
                      Группа
                      <span *ngIf="sortType == 'group' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'group' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-200px" (click)="sortType = 'vendor';sortReverse = !sortReverse;sortData('vendor');">
                      Поставщик
                      <span *ngIf="sortType == 'vendor' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'vendor' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-100px" (click)="sortType = 'name';sortReverse = !sortReverse;sortData('price');">
                      Цена
                      <span *ngIf="sortType == 'price' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'price' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>

                    <th class="pr-1 py-2 align-middle min-w-60px">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="btn-reveal-trigger tr-hover" *ngFor="let i of itemData">
                    <th class="align-middle">
                      <a href="javascript:void(0)" (click)="itemOperation(i.id,false)">
                        {{i.name}}
                      </a>
                    </th>
                    <td class="align-middle ">
                      <span *ngIf="i.img!=0"><i class="far fa-image"></i></span>
                    </td>
                    <td class="align-middle">
                      {{i.group}}
                    </td>
                    <td class="align-middle ">
                      {{i.vendor}}
                    </td>
                    <td class="align-middle ">  {{i.price | number : '1.2-2'}}<span *ngIf="i.price"> руб.</span></td>
                    <td class="min-w-60px">
                      <div class="d-flex justify-content-between">
                        <span class="px-1 cursor-pointer grid-icons">
                          <!--<i class="fas fa-copy" (click)="itemOperation(i.id,false)" title="Дублировать"></i>-->
                          <span (click)="printBarcode(i.id)"><i class="fas fa-print"></i></span>
                        </span>

                        <span class="px-1 text-danger grid-icons cursor-pointer" (click)="modalDelete(i.id,'itemStatus','itemStatus')">
                          <i class="fas fa-trash"  title="Удалить"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody class="text-center w-100 py-3 bg-light" *ngIf="itemData?.length==0">
                  <tr>
                    <td colspan="8" class="px-2 py-3 bg-light text-center w-100">
                      <p class="text-center bold-text font-size18px m-0">Данные недоступны</p>
                      <p class="text-center m-0 text-gray font-size13px">Нам нечего показать прямо сейчас</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ngx-loading [show]="loading" class="z-index1000"></ngx-loading>
        </div>
      </div>
    </div>
  </div>
</div>


<!--Modal Item-->
<div class="modal fade px-0" id="modal_item" tabindex="-1" costCatalogue="dialog" aria-labelledby="modal_item" data-backdrop="static" data-keyboard="false"
     aria-hidden="true" (keydown.esc)="modalItemClose()">
  <div class="modal-dialog modal-width-500px" costCatalogue="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{modalTitle}}
        </h5>
        <button class="close" type="button" aria-label="Close" (click)="modalItemClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-scroll modal-padding" name="costCatalogueModal" id="costCatalogueModalBody">

        <div class="row">
          <div class="col-6">
            <div >
              <label class="form-label" for="name">Наименование <span class="text-danger">*</span></label>
              <input class="form-control" id="name" type="text" title="Наименование" [(ngModel)]="itemObj.name" required appTrimLeft autocomplete="off" tabindex="1" />
            </div>
          </div>
          <div class="col-6">
            <div >
              <div class="d-flex justify-content-between align-items-center">
                <label for="vendor">
                  Поставщик
                </label>
                <button class="btn btn-link btn-sm p-0 mb-1 fs-13" type="button" tabindex="2" (click)="addVendor()">Добавить</button>
              </div>
              <select class="form-control" id="vendor" name="vendor" title="Поставщик" [(ngModel)]="itemObj.vendor" tabindex="2">
                <option [ngValue]="0">Выберите</option>
                <option [ngValue]="v.id" *ngFor="let v of itemObj.vendorList">{{v.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div >
              <div class="d-flex justify-content-between align-items-center">
                <label for="group" >
                  Группа <span class="text-danger">*</span>
                </label>
                <button class="btn btn-link btn-sm p-0 mb-1 fs-13" type="button" tabindex="2" (click)="addGroup()">Добавить</button>
              </div>
              <select class="form-control select2" id="group" name="group" title="Группа" [(ngModel)]="itemObj.group" tabindex="3" (change)="changeGroup(itemObj.group)">
                <option value="0">Выберите</option>
                <option [ngValue]="gl.id" *ngFor="let gl of itemObj.groupList">{{gl.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <label for="subGroup">
                  Подгруппа
                </label>
                <button class="btn btn-link btn-sm p-0 mb-1 fs-13" type="button" tabindex="2" (click)="addSubGroup()">Добавить</button>
              </div>
              <select class="form-control select2" id="subGroup" name="subGroup" title="Подгруппа" [(ngModel)]="itemObj.subGroup" tabindex="4">
                <option value="0">Выберите</option>
                <option [ngValue]="sgl.id" *ngFor="let sgl of itemObj.subGroupList">{{sgl.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-1 mt-2">
          <div class="col-12">
            <div>
              <label class="form-label" for="desc">Комментарий</label>
              <textarea class="form-control" id="desc" rows="3" [(ngModel)]="itemObj.desc" appTrimLeft autocomplete="off" maxLength="500" tabindex="5"></textarea>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <div>
              <label class="form-label">Цена</label>
              <div class="input-group">
                <span class="dol-sign"><i class="fas fa-ruble-sign"></i></span>
                <input class="form-control text-right pl-25px" id="price" name="price" [(ngModel)]="itemObj.price" mask="separator" appSelectedInput thousandSeparator="," autocomplete="off" tabindex="6">
              </div>
            </div>
          </div>
          <div class="col-6">
            <div>
              <label class="form-label">Скидочная цена </label>
              <div class="input-group">
                <span class="dol-sign"><i class="fas fa-ruble-sign"></i></span>
                <input class="form-control text-right pl-25px" id="priceWithDiscount" name="priceWithDiscount" [(ngModel)]="itemObj.priceWithDiscount" mask="separator" appSelectedInput thousandSeparator="," autocomplete="off" tabindex="7">
              </div>
            </div>
          </div>
        </div>




        <div class="row mt-4">
          <div class="col-12">
            <div>
              <div class="row">
                <div class="fallback hidden d-none">
                  <input type="file" id="file" name="file" (change)="fileChangeEvent($event)" class="form-control d-none" tabindex="8" accept="image/*" />
                </div>
                <div class="col-auto">
                  <div class="dz-preview dz-preview-single mt-0">
                    <div class="dz-preview-cover d-flex align-items-center justify-content-center mb-3 mb-md-0">
                      <div class="avatar avatar-3xl img-choices" *ngIf="itemObj.img!=''">
                        <span class="fancy-hover-remove fs-22 text-white" (click)="removeImage()"><i class="fas fa-trash"></i></span>
                        <img style="width: 100% !important; height: 99% !important; border-radius: 5px" [src]="itemObj.img" />
                      </div>
                    </div>
                    <div class="avatar avatar-3xl" *ngIf="itemObj.img==''">
                      <img style="width: 100% !important; height: 99% !important; border-radius: 5px" src=" assets/img/icons/img.png" />
                    </div>
                  </div>
                </div>
                <div class="col-md my-0">
                  <div class="dz-message dropzone-area px-2 py-2" (click)="clickToUpload()">
                    <div class="text-center">
                      <img class="mr-2" src="assets/img/icons/cloud-upload.svg" width="25" alt="" />Загрузить изображение
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



       

      </div>
              <div class="modal-footer modal-footer-fixed">
                <button class="btn btn-falcon-default" type="button" (click)="modalItemClose()">Отмена</button>
                <button class="btn btn-primary" id="btItemSave" type="button" [disabled]="!itemObj.name || itemObj.group==0" (click)="saveItem()">
                  <div class="g_spinner" *ngIf="spinner"></div>
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!--Modal Vendor-->
        <div class="modal fade px-0" id="modal_vendor" tabindex="-1" costCatalogue="dialog" aria-labelledby="modal_vendor" data-backdrop="static" data-keyboard="false"
             aria-hidden="true" (keydown.esc)="modalVendorClose()">
          <div class="modal-dialog modal-width-400px" costCatalogue="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Добавить поставщик
                </h5>
                <button class="close" type="button" aria-label="Close" (click)="modalVendorClose()">
                  <span class="font-weight-light" aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body modal-body-scroll modal-padding" name="vendorModal" id="vendorModalBody">

                <div class="row">
                  <div class="col-12">
                    <div>
                      <label class="form-label" for="name">Наименование <span class="text-danger">*</span></label>
                      <input class="form-control" id="name" type="text" title="Наименование" [(ngModel)]="vendorObj.name" required appTrimLeft autocomplete="off" tabindex="1" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer modal-footer-fixed">
                <button class="btn btn-falcon-default" type="button" (click)="modalVendorClose()">Отмена</button>
                <button class="btn btn-primary" id="btnVendorSave" type="button" [disabled]="!vendorObj.name" (click)="saveVendor()">
                  <div class="g_spinner" *ngIf="spinner"></div>
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!--Modal Group-->
        <div class="modal fade px-0" id="modal_group" tabindex="-1" costCatalogue="dialog" aria-labelledby="modal_group" data-backdrop="static" data-keyboard="false"
             aria-hidden="true" (keydown.esc)="modalGroupClose()">
          <div class="modal-dialog modal-width-400px" costCatalogue="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Добавить группа
                </h5>
                <button class="close" type="button" aria-label="Close" (click)="modalGroupClose()">
                  <span class="font-weight-light" aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body modal-body-scroll modal-padding" name="groupModal" id="groupModalBody">

                <div class="row">
                  <div class="col-12">
                    <div>
                      <label class="form-label" for="name">Наименование <span class="text-danger">*</span></label>
                      <input class="form-control" id="name" type="text" title="Наименование" [(ngModel)]="groupObj.name" required appTrimLeft autocomplete="off" tabindex="1" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer modal-footer-fixed">
                <button class="btn btn-falcon-default" type="button" (click)="modalGroupClose()">Отмена</button>
                <button class="btn btn-primary" id="btnGroupSave" type="button" [disabled]="!groupObj.name" (click)="saveGroup()">
                  <div class="g_spinner" *ngIf="spinner"></div>
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!--Modal Sub Group-->
        <div class="modal fade px-0" id="modal_subgroup" tabindex="-1" costCatalogue="dialog" aria-labelledby="modal_subgroup" data-backdrop="static" data-keyboard="false"
             aria-hidden="true" (keydown.esc)="modalSubGroupClose()">
          <div class="modal-dialog modal-width-400px" costCatalogue="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Добавить подгруппа
                </h5>
                <button class="close" type="button" aria-label="Close" (click)="modalSubGroupClose()">
                  <span class="font-weight-light" aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body modal-body-scroll modal-padding" name="subGroupModal" id="subGroupModalBody">
                <div class="row">
                  <div class="col-12">
                    <div>
                      <label class="form-label" for="addgroup">Группа <span class="text-danger">*</span></label>
                      <select class="form-control select2" id="addgroup" name="addgroup" title="Группа" [(ngModel)]="subGroupObj.group" tabindex="1">
                        <option value="0">Выберите</option>
                        <option value="{{gl.id}}" *ngFor="let gl of subGroupObj.groupList">{{gl.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div>
                      <label class="form-label" for="name">Наименование <span class="text-danger">*</span></label>
                      <input class="form-control" id="name" type="text" title="Наименование" [(ngModel)]="subGroupObj.name" required appTrimLeft autocomplete="off" tabindex="2" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer modal-footer-fixed">
                <button class="btn btn-falcon-default" type="button" (click)="modalSubGroupClose()">Отмена</button>
                <button class="btn btn-primary" id="btnGroupSave" type="button" [disabled]="!subGroupObj.name" (click)="saveSubGroup()">
                  <div class="g_spinner" *ngIf="spinner"></div>
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!-- Modal Image-->
        <div class="modal fade mt-5" id="modal_file" tabindex="-1" role="dialog" aria-labelledby="modal_file" data-backdrop="static" data-keyboard="false"
             aria-hidden="true">
          <div class="modal-dialog max-width-40per" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Image</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span class="font-weight-light" aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body modal-body-scroll modal-el-scroll" style="margin-bottom:0 !important">
                <image-cropper [imageChangedEvent]="imageChangedEvent"
                               [maintainAspectRatio]="true"
                               [aspectRatio]="8 / 8"
                               format="png"
                               (imageCropped)="imageCropped($event)"

                               [resizeToWidth]="400"
                 [resizeToHeight]="400"

                               ></image-cropper>
                

                <img [src]="croppedImage" class="w-100px h-100px" />
              </div>
              <div class="modal-footer">
                <button class="btn btn-falcon-default btn-sm" type="button" data-dismiss="modal" (click)="closeFile()">Close</button>
                <button class="btn btn-primary btn-sm" type="button" (click)="selectImage()"> <div class="g_spinner" *ngIf="spinner"></div> Select</button>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!-- Modal Delete-->
        <div class="modal fade z-index10001 pr-0" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             data-backdrop="static" data-keyboard="false" aria-hidden="true" (keydown.esc)="modalDeleteClose()">
          <div class="modal-dialog modal-dialog-center modal-delete-width" role="document">
            <div class="modal-content">
              <div class="modal-header border-bottom-0">
                <div class="modal-delete-header-icondiv">
                  <i class="far fa-times-circle text-center modal-delete-circle-icon"></i>
                </div>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close"
                        (click)="modalDeleteClose()">
                  <span class="font-weight-light" aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="text-center fs-20 fw-600">Ты уверен?</p>
                <p class="text-muted fs-13 pl-3 pr-3 text-center">Вы действительно хотите удалить эту запись? Этот процесс нельзя отменить.</p>
              </div>
              <div class="modal-delete-footer">
                <button class="btn btn-sm mr-2 modal-delete-footer-cancel" type="button" data-dismiss="modal" (click)="modalDeleteClose()">Отменить</button>
                <button class="btn btn-danger btn-sm" type="button" (click)="deleteItem(delId,delType,delType2)">
                  <div class="g_spinner" *ngIf="spinner"></div>
                  Удалить
                </button>
              </div>
            </div>
          </div>
        </div>
