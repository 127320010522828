<html>
<head>
  <style>
    @media print {
      @page {
        size: A4;
        margin: 0;
      }

      #printDiv, p {
        page-break-inside: avoid;
      }

      html, body {
        width: 297mm;
        height: 140mm;
        -webkit-print-color-adjust: exact;
      }
    }

  </style>
</head>
<body class="bg-white">
  <!--<div id="printDiv" style="width:100%; background-color: #fff; display: flex; justify-content: space-between">
    <div class="mr-4">
      <img id="barcode" style="width: 240px;" />
      <div style="padding-left: 5px; padding-right: 5px; width: 100%; font-weight: 600; font-size: 24px; color: #000; font-family: monospace; text-align: center">{{itemPrintData.name}}</div>
      <div style="padding-left: 5px; padding-right: 5px; width: 100%; font-weight: 600; font-size: 24px; color: #000; font-family: monospace; text-align: center">{{itemPrintData.price}}p</div>
    </div>
    <div class="mr-4">
      <img id="barcode" style="width: 240px; " />
      <div style="padding-left: 5px; padding-right: 5px; width: 100%; font-weight: 600; font-size: 24px; color: #000; font-family: monospace; text-align: center ">{{itemPrintData.name}}</div>
      <div style="padding-left: 5px; padding-right: 5px; width: 100%; font-weight: 600; font-size: 24px; color: #000; font-family: monospace; text-align: center ">{{itemPrintData.price}}p</div>
    </div>
    <div class="mr-4">
      <img id="barcode" style="width: 240px; " />
      <div style="padding-left: 5px; padding-right: 5px; width: 100%; font-weight: 600; font-size: 24px; color: #000; font-family: monospace; text-align: center ">{{itemPrintData.name}}</div>
      <div style="padding-left: 5px; padding-right: 5px; width: 100%; font-weight: 600; font-size: 24px; color: #000; font-family: monospace; text-align: center ">{{itemPrintData.price}}p</div>
    </div>
    <div>
      <img id="barcode" style="width: 240px; " />
      <div style="padding-left: 5px; padding-right: 5px; width: 100%; font-weight: 600; font-size: 24px; color: #000; font-family: monospace; text-align: center ">{{itemPrintData.name}}</div>
      <div style="padding-left: 5px; padding-right: 5px; width: 100%; font-weight: 600; font-size: 24px; color: #000; font-family: monospace; text-align: center ">{{itemPrintData.price}}p</div>
    </div>
  </div>-->


  <div id="printDiv" style="{{itemPrintData.sectionStyle}}">
    <div class="row">
      <div class="col-sm-12 p-0">
        <div style="{{itemPrintData.barcodeStyle}}">
          <img id="barcode"  style="{{itemPrintData.barcodeImageStyle}}"/>
          <div style="{{itemPrintData.barcodeItemNameStyle}}">{{itemPrintData.name}}</div>
          <div style="{{itemPrintData.barcodeItemPriceStyle}}">{{itemPrintData.price}} &#8381;</div>
        </div>
      </div>
    </div>
  </div>


</body>
</html>
