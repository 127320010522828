import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ReqResService } from '../../service/req-res.service';
import { AuthService } from '../../service/auth/auth.service'
import { PluginService } from '../../service/plugin.service';
import { PreviousRouteService } from '../../service/previous-route.service'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css'],
})
export class AppLayoutComponent implements OnInit {
  favoritetMenus: any;
  menus: any;
  toggleButtonTitle: string;

  //password variable
  userData: any = [];
  formValue: any;
  userPasswordForm: FormGroup;
  submitted = false;
  userPassword: string;
  errorPassword: string = "";
  reportIssue: string = "";
  //
  public _authService;

  public static _userId: number = 0;
  public static _image: string = "";
  public static _firstName: string = "";
  public static _lastName: string = "";
  recentProjectsList: any = [];
  recentProspectsList: any = [];
  constructor(private reqRes: ReqResService, private authService: AuthService,
    private pluginService: PluginService, private formBuilder: FormBuilder) {
    this._authService = this.authService;
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    console.log('Back button pressed');
    $('.modal-backdrop').remove();
  }
  @HostListener('click', ['$event'])
  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover'
    });
    $('[data-toggle="tooltip"]').on('mouseleave', function () {
      $(this).tooltip('dispose');
    });

  }
  bindAccordinMenuOpe: boolean = false;
  ngOnInit(): void {
    //this.getMenus();
    //this.getUserInfo();

   
    this.pluginService.mouserOverVertical();
    this.userPasswordForm = this.formBuilder.group({
      ope: 2,
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }



  getMenus() {
    this.reqRes.getData('/Layout/GetMenus/Menu').subscribe(data => {
      this.menus = data;
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      })
  }
  getUserInfo() {
    this.reqRes.getData('/Layout/GetUserInfo').subscribe(data => {
      AppLayoutComponent._userId = data.id;
      AppLayoutComponent._image = data.image;
      AppLayoutComponent._firstName = data.firstName;
      AppLayoutComponent._lastName = data.lastName;
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      })
  }
  logOut() {
    this.authService.isNotAuthencatedUrl = "/login";
    this._authService.logout();
  }
  spinner: boolean = false;
  //#region "Password"

  resertForm() {
    this.userPasswordForm.setValue({
      ope: 2,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  }
  getUserData(type) {
    this.reqRes.getData('/Settings/GetUserData/' + type).subscribe(data => {
      this.userData = data;
      this.userPassword = data[0].password;
      this.resertForm();
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      })
  }
  onSubmit() {
    //this.submitted = true;
    //this.spinner = true;
    //this.formValue = this.userPasswordForm.value;
    //this.reqRes.postData('/Settings/UserOperation', this.formValue).subscribe(data => {
    //  if (!data.error) {
    //    toastr.success("Done!", '', { timeOut: 1000 });
    //    $("#modal_password").modal("hide");
    //  } else {
    //    this.errorPassword = data.error;
    //    this.resertForm();
    //  }
    //  this.spinner = false;
    //}, error => {
    //  toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
    //  this.resertForm();
    //});
  }
  onPasswordKeyup() {
    this.errorPassword = "";
  }

}
