import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PluginService } from '../../service/plugin.service';
import { ReqResService } from '../../service/req-res.service';
declare var $: any;
declare var window: any;
declare var toastr: any;
declare var JsBarcode: any;
@Component({
  selector: 'app-barcodeprint',
  templateUrl: './barcodeprint.component.html',
  styleUrls: ['./barcodeprint.component.css']
})
export class BarcodeprintComponent implements OnInit {

  id: number = 0;
  barcode: string = '';
  itemPrintData: any = {};
  constructor(private titleService: Title, private router: Router, private route: ActivatedRoute, private reqRes: ReqResService, private pluginservice: PluginService) {
    this.route.paramMap.subscribe(paramMap => {
      this.id = Number(paramMap.get('id'));
    
    


      this.getBarcodePrintData(this.id);
    });
  }
  ngOnInit(): void {
  }
  barcodeCount: any = [];

  getBarcodePrintData(id) {
    this.reqRes.getData('/Item/GetItemPrintData/' + id).subscribe(data => {
      this.itemPrintData = data;



      this.barcodeCount = Array(data.barcodeCount).fill(0).map((x, i) => i);

      setTimeout(() => {

        JsBarcode("#barcode", this.itemPrintData.barcode, {
          format: "CODE128",
          width: 4,
          height: 70,
          displayValue: false
        });


      }, 200)


      setTimeout(function () {
        window.print();
      }, 1500)
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error);
      })
  }
}
