import { Directive, ElementRef, EventEmitter, Output} from '@angular/core';
import { NgModel } from '@angular/forms';
declare var $: any;

@Directive({
  selector: '[appClearInjectionSymbol]'
})

export class ClearInjectionSymbolDirective {
  constructor(private element: ElementRef, private ngModel: NgModel) { }

  ngOnInit() {
    let that = this;
    $(that.element.nativeElement).bind("keyup", function () {
      setTimeout
        (() => {
          that.ngModel.update.emit(that.ngModel.value.replace("'", '').replace("/", ''));
        }, 100);
    });
  }
}
