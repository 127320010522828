<main class="main" id="top">

    <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
      <a class="navbar-brand">Favorit</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarsExample03">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link"  [routerLink]="['/home']">Продажи <span class="sr-only"></span></a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="['/warehouse']">Склад</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link"  [routerLink]="['/items']">Товары</a>
          </li>
        </ul>
        <form class="form-inline my-2 my-md-0">
          <input class="form-control" type="text" placeholder="Поиск">
        </form>
      </div>
    </nav>

    <div class="container">
      <div class="content" style="margin-left:unset!important;">
        <router-outlet></router-outlet>
        <footer></footer>
      </div>
    </div>
    
</main>

