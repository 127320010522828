import { Component, OnInit } from '@angular/core';
import { ReqResService } from '../../service/req-res.service';
import { PaginationInstance } from 'ngx-pagination';
import { PluginService } from '../../service/plugin.service';
import { AuthService } from '../../service/auth/auth.service'

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  loading = false;
  userData: any = [];
  sortReverse: boolean;
  sortType: string;
  searchUser: string='';
  userObj: any = {};
  userObjTemp: any;
  userLimit: number = 0;
  modalType: number = 0
  chkOpe: number = 0;
  disableButton: number = 0;
  changePasswordOpe: boolean = false;
  //del variables
  delId: number;
  delType: string;
  delCount: number;
  delBulkArray: any = [];
  delType2: string;
  modalTitle: string = '';
  userCount: number = 0;
  sortdirection: string = 'A';
  maxCount: number = 0;
  public _pluginservice;
  constructor(private reqRes: ReqResService, private pluginservice: PluginService, private authService: AuthService) {
    this._pluginservice = this.pluginservice;
  }
  ngOnInit(): void {
    this.sortReverse = true;
    this.sortType = 'createdate';

    let json = {
      "Search": '',
      "Count": this.userCount,
      "Direction": 'A',
      "SortType": this.sortType
    };

    this.getUserData(JSON.stringify(json), 0);
    this._pluginservice.masterSelectedRefresh('userBulk', []);
    this._pluginservice.checkedList = [];
  }
  searchInputOperation() {
    $('.custom-search-input').focus();
    $("[data-toggle='tooltip']").tooltip('hide');
  }
  openFilter() {
    $("#divSearchFilter").removeClass("d-none")
    $("#divSearchFilter").addClass("d-inline-block")
    $("#divSearchFilter").animate({ right: '0px' }, "slow");
    $("#btnSearchFilter").hide();
    $("#inputSearchFilter").focus();
  }
  closeFilter() {
    $("#divSearchFilter").removeClass("d-inline-block")
    $("#divSearchFilter").addClass("d-none")
    $("#btnSearchFilter").show();
  }
  getUserLimit() {
    this.reqRes.getData('/User/GetUserStaticData/UserLimit').subscribe(data => {
      this.userLimit = data[0].userLimit;
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      })
  }
  resetchkOpe() {
    this.chkOpe = 0;
  }
  checkUserEmail() {
    this.reqRes.getData('/User/GetUserStaticData/UserEmail/' + this.userObj.id + '/' + this.userObj.email).subscribe(data => {
      this.chkOpe = data[0].chkOpe;
      if (this.chkOpe == 0) {
        this.saveUser();
      }
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      })
  }
  modalUserClose() {
    let result = this.pluginservice.compareObjects(this.userObj, this.userObjTemp, ["id", "userRoles"])
    if (result) {
      if (confirm('Close without saving??')) {
        $('#userModal').modal('hide');
      }
    }
    else {
      $('#userModal').modal('hide');
    }
  }
  saveUser() {
    this._pluginservice.masterSelectedRefresh('userBulk');
    $("#userModal").modal('hide');
    this.disableButton = 1;
    this.reqRes.postData('/User/SaveUser', this.userObj).subscribe(data => {
      this.disableButton = 0;
      toastr.success('Done!', '', { timeOut: 1000 });
      if (data.length != 0) {
        let localStorage = data;
        this.authService.setUserData(localStorage);
       
      }

      let json = {
        "Search": '',
        "Count": '0',
        "Direction": 'A',
        "SortType": 'createdate'
      };
      this.userCount = 0;
      this.getUserData(JSON.stringify(json), 0);
    }, error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
    })
  }

  //getUserData(type) {
  //  this.loading = true;
  //  this.reqRes.getData('/Settings/GetUserData/' + type).subscribe(data => {
  //    this.loading = false;
  //    this.userData = data;
  //    this._pluginservice.masterSelectedRefresh('userBulk');
  //  },
  //    error => {
  //      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
  //      console.log(error)
  //    })
  //}

  sortData(type) {
    this.sortType = type;
    this.userCount = 0;
    this.sortdirection = (this.sortReverse) ? 'D' : 'A';
    this.searchUser = (this.searchUser) ? this.searchUser : '';
    let json = {
      "Search": this.searchUser,
      "Count": this.userCount,
      "Direction": this.sortdirection,
      "SortType": this.sortType
    };
    this.getUserData(JSON.stringify(json), 0);
  }

  getUserData(json, ope) {
    this.loading = true;

    let GridParametrs: any = {};
    GridParametrs.type = 'users';
    GridParametrs.json = json;

    this.reqRes.postData('/Settings/GetSettingsViewData', GridParametrs).subscribe(data => {
      this.loading = false;
      this.userData = (ope == 0) ? this.userData = data.userData : this.userData.concat(data.userData);
      this.maxCount = data.maxCount;
      //this._pluginservice.masterSelectedRefresh('roleBulk');
      if (this._pluginservice.checkedList.length == 1) {
        setTimeout(() => {
          $("#threeNo" + this.checkItemId + " button").prop('disabled', false);
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      } else if (this._pluginservice.checkedList.length > 1) {
        setTimeout(() => {
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      }
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }
  checkItemId: number = 0;
  getCheckedId(id) {
    this.checkItemId = id;
  }

  getSearchData(search) {
    this.searchUser = (search) ? search : '';
    this.userCount = 0;
    let json = {
      "Search": this.searchUser,
      "Count": this.userCount,
      "Direction":  'A',
      "SortType": this.sortType 
    };
 
    $("div[id^=threeNo] button").prop('disabled', false);
    this.getUserData(JSON.stringify(json), 0)
  }

  onScrollData(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight && this.userCount <= this.maxCount) {
      this.userCount = this.userCount + 25;
      let json = {
        "Search": this.searchUser,
        "Count": this.userCount,
        "Direction": this.sortdirection,
        "SortType": this.sortType
      };
      this.getUserData(JSON.stringify(json), 1);
      //$("div[id^=threeNo] button").prop('disabled', false);
      //this._pluginservice.UnCheckAll(this.userData);
    }
  }

  chagenPasswordPermission() {
    this.userObj.password = ''
    this.userObj.confirmationPassword = ''
  }

  editUser(id) {
    this.chkOpe = 0;
    this.modalType = id;
    this.modalTitle = (id == 0) ? 'Add User' : 'Edit User';
    this.changePasswordOpe = (this.modalType == 0) ? true : false;
    this.reqRes.getData('/User/GetUserEditData/' + id + '/user').subscribe(data => {
      this.userObj = data;
      //Clone User Modal Information to Temp Obj //
      const cloneOfObject = JSON.parse(JSON.stringify(this.userObj));
      this.userObjTemp = cloneOfObject;
      /////////////////////////////////////////////
      $("#userModal").modal('show');
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error);
      })
    this.getUserLimit();
  }
  changeStatus(param, id, status, param2): void {
    if (param2 == 'changeStatus') {
      status = status ? 1 : 0;
    }
    let statusObj: any = {};
    statusObj.param = param;
    statusObj.id = id;
    statusObj.status = status;
    this.reqRes.postData('/User/ChangeUserStatus', statusObj).subscribe(data => {
      if (param == 'user' && status == -1) {
        let index = this.userData.findIndex(item => item.id == id);
        this.userData.splice(index, 1);
        toastr.success('Done!', '', { timeOut: 1000 });
      }
      else if (param == 'user') {
        toastr.success('Done!', '', { timeOut: 1000 });
      }
    }, error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
    })
  }

  modalDelete(delId, delType, delType2, bulkArray) {
    this.delId = delId;
    this.delType = delType;
    this.delType2 = delType2;
    this.delBulkArray = bulkArray;
    if (bulkArray.length == 0) {
      this.delCount = 1;
    } else {
      this.delCount = this._pluginservice.checkedList.length;
    }
    $('#modalDelete').modal('show');
  }

  deleteItem(id, type, type2, array) {
    $("div[id^=threeNo] button").prop('disabled', false);
    $('#modalDelete').modal('hide');
    if (array.length == 0) {
      this.changeStatus(type, id, -1, type2);
    } else {
     let ids= this._pluginservice.bulkActions(array[0]);
      ids.forEach((id) => {
        let index = this.userData.findIndex(item => item.id == id);
        this.userData.splice(index, 1);
      });
    }
    this._pluginservice.masterSelectedRefresh('userBulk');
  }

  modalDeleteClose() {
    $('#modalDelete').modal('hide');
  }
}
