<main class="main my-4" id="top">
  <div class="container" data-layout="container" *ngIf="invoiceDetailData?.specification?.length>0">
    <div class="content">
      <div id="printDiv" class="col-md-12 bg-white p-3 p-lg-6 res-preview-height-left  order-sm-1 order-2">
        <div>
          <div class="buttons doNotPrint" style=" position: absolute !important; right: 64px !important; top: 28px !important;">
            <button class="btn btn-falcon-default btn-sm" (click)="print()">Print</button>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <div class="estimate-company-logo" *ngIf="invoiceDetailData.companyLogo">
                <img src="{{invoiceDetailData.companyLogo}}" title="Company Logo" alt="Company Logo" />
              </div>
              <h5 *ngIf="!invoiceDetailData.companyLogo">{{invoiceDetailData.company}}</h5>
              <div>
                <table class="table table-sm table-borderless fs-18 text-dark">
                  <tbody>
                    <tr>
                      <th class="text-sm-left fw-400 p-0">
                        {{invoiceDetailData.companyStreet}}
                        <br />{{invoiceDetailData.companyCity}}, {{invoiceDetailData.companyState}} {{invoiceDetailData.companyZip}}
                      </th>
                    </tr>
                    <tr>
                      <th class="text-sm-left fw-400 p-0">{{invoiceDetailData.webSite}}</th>
                    </tr>
                    <tr>
                      <th class="text-sm-left fw-400 p-0">{{invoiceDetailData.companyPhone}}</th>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-sm table-borderless fs-18 text-dark">
                  <tbody>
                    <tr>
                      <th class="text-sm-left fw-400 p-0 fs-22 fw-700">{{invoiceDetailData.customerName}}</th>
                    </tr>
                    <tr class="text-sm-left">
                      <th class="text-sm-left fw-400 p-0">
                        {{invoiceDetailData.customerStreet}}
                        <br />{{invoiceDetailData.customerCity}} {{invoiceDetailData.customerState}} {{invoiceDetailData.customerZip}}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-sm-auto ml-auto">
              <div class="table-responsive">
                <table class="table table-sm table-borderless fs-18 text-dark">
                  <tbody>
                    <tr>
                      <th class="text-sm-right fs-22 fw-700 p-0">INVOICE</th>
                    </tr>
                    <tr>
                      <th class="text-sm-right fw-400 p-0">{{invoiceDetailData.invoiceName}} #{{invoiceDetailData.code}}</th>
                    </tr>
                    <tr>
                      <th class="text-sm-right fw-400 p-0">Rep: <span *ngFor="let ass of invoiceDetailData.assigner;let lst = last;">{{ass.name}}<span *ngIf="!lst">, </span></span></th>
                    </tr>
                    <tr>
                      <th class="text-sm-right fw-400 p-0">Date: {{invoiceDetailData.date}}</th>
                    </tr>
                    <tr>
                      <th class="text-sm-right fw-400 p-0">Due date: {{invoiceDetailData.dueDate}}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="fs-18">
          <div *ngFor="let spec of invoiceDetailData.specification; let firstSpec = first; let indx = index;let lst = last;">
            <table class="table mb-0 table-bordered table-preview" [ngClass]="{'bt-0':!firstSpec}">
              <thead *ngIf="!(spec.specificationId==0 && invoiceDetailData?.specification.length==1)" class="group-color">
                <tr>
                  <th colspan="3" *ngIf="invoiceDetailData.prevBackendOpe" class="bt-0 fs-18 text-dark br-0">
                    {{spec.name}}
                    <br /><span class="fs-18 fw-400">{{spec.desc}}</span>
                  </th>
                  <th *ngIf="!invoiceDetailData.prevBackendOpe" class="bt-0 fs-18 bl-0 br-0">
                    {{spec.name}}
                    <br /><span class="fs-18 fw-400">{{spec.desc}}</span>
                  </th>
                  <th class="bt-0 fs-18 text-center bl-0 text-dark width-15-per align-middle"><span *ngIf="invoiceDetailData.prevBackendOpe">{{spec.price | currency:'USD':true}}</span></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cl of spec.costLine;">
                  <td class="fs-18 align-middle text-dark" *ngIf="cl.qty!=0">
                    {{cl.name}}<span *ngIf="cl.selectedVariationId!=0"> - {{cl.selectedVariationName}}</span>
                    <div class="fs-18 text-muted">{{cl.desc}}</div>
                  </td>
                  <td class="fs-18 width-15-per align-middle text-center text-dark" *ngIf="cl.qty!=0"><span>{{cl.qty}} {{cl.unit}}</span> </td>
                  <td class="fs-18 width-15-per align-middle text-center text-dark" *ngIf="cl.qty!=0 && invoiceDetailData.prevBackendOpe"><span>${{cl.rate | amountConverter:'us'}}</span></td>
                  <td class="fs-18 width-15-per align-middle text-center text-dark" *ngIf="cl.qty!=0 && invoiceDetailData.prevBackendOpe"><span>${{cl.price | amountConverter:'us'}}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row no-gutters justify-content-end">
          <div class="col-auto">
            <table class="table table-sm table-borderless mt-5 fs-18">
              <tr>
                <th class="text-dark fs-18 fw-400">Subtotal</th>
                <td class="text-dark text-right fs-18 fw-400">{{invoiceDetailData.subtotal | currency:'USD':true}}</td>
              </tr>
              <tr *ngIf="invoiceDetailData.tax!=0">
                <th class="text-dark fs-18 fw-400">Tax ({{invoiceDetailData.taxRate}}%)</th>
                <td class="text-dark text-right fs-18 fw-400">{{invoiceDetailData.taxAmount | currency:'USD':true}}</td>
              </tr>
              <tr>
                <th class="font-weight-bold text-dark font-size21px">Total</th>
                <td class="font-weight-bold text-right text-dark font-size21px">{{invoiceDetailData.total | currency:'USD':true}}</td>
              </tr>
              <tr *ngIf="invoiceDetailData.projectOpe!=1">
                <th class="text-dark fs-18 fw-400">Contract Value</th>
                <td class="text-dark text-right fs-18 fw-400">{{invoiceDetailData.contractPrice | currency:'USD':true}}</td>
              </tr>
              <tr *ngIf="invoiceDetailData.projectOpe!=1">
                <th class="text-dark fs-18 fw-400">Payments Applied</th>
                <td class="text-dark text-right fs-18 fw-400">{{invoiceDetailData.paymentApplied | currency:'USD':true}}</td>
              </tr>
              <tr *ngIf="invoiceDetailData.projectOpe!=1">
                <th class="text-dark fs-18 fw-400">Balance Due</th>
                <td class="text-dark text-right fs-18 fw-400">{{invoiceDetailData.balanceDue | currency:'USD':true}}</td>
              </tr>

              <tr *ngIf="invoiceDetailData.projectOpe==1">
                <th class="text-dark fs-18 fw-400">Payments Applied</th>
                <td class="text-dark text-right fs-18 fw-400">{{invoiceDetailData.paymentAppliedInvoice | currency:'USD':true}}</td>
              </tr>
              <tr *ngIf="invoiceDetailData.projectOpe==1">
                <th class="text-dark fs-18 fw-400">Balance Due</th>
                <td class="text-dark text-right fs-18 fw-400">{{invoiceDetailData.balanceDueInvoice | currency:'USD':true}}</td>
              </tr>

            </table>
          </div>
        </div>
        <div class="row mt-3" *ngIf="invoiceDetailData.prevAdditionalOpe">
          <div class="col-lg-12">
            <p class="fs-18 text-preview text-dark" [innerHTML]="invoiceDetailData.displayMessage"></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center mt-25-per" *ngIf="invoiceDetailData?.specification?.length==0 || invoiceDetailData?.specification==null">
    <h3 class="text-muted">You have no any invoice at the moment.</h3>
  </div>

</main>

