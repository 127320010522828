import { Directive, ElementRef, HostListener, Input, Renderer2, SimpleChanges } from '@angular/core';
declare var $: any;

@Directive({
  selector: '[maxLength]',
  host: { "(input)": 'onInputChange($event)' }
})
export class MaxLengthDirective {
  @Input('maxLength') maxLength: number;
  constructor(private renderer: Renderer2, private element: ElementRef) { }
  ngOnInit() {
    this.renderer.setAttribute(this.element.nativeElement, 'maxLength', this.maxLength.toString());
    let modelLength = this.element.nativeElement.value == null ? 0 : this.element.nativeElement.value.length;
    if (this.element.nativeElement.id == 'comment') {
      this.element.nativeElement.insertAdjacentHTML('afterend', '<div class="text-right"><span id="' + this.element.nativeElement.id + 'Span" style="color: #989696;font-size:13px; position: absolute;right: 80px;bottom: 13px;" >' + modelLength + '/' + this.maxLength + '</span></div>');
    }
    else {
      this.element.nativeElement.insertAdjacentHTML('afterend', '<div class="text-right"><span id="' + this.element.nativeElement.id + 'Span" style="color: #989696;font-size:13px" >' + modelLength + '/' + this.maxLength + '</span></div>');
    }
  }
  ngAfterViewChecked() {
    let modelLength = this.element.nativeElement.value == null ? 0 : this.element.nativeElement.value.length;
    $('#' + this.element.nativeElement.id + 'Span').html(modelLength + '/' + this.maxLength);
  }
  onInputChange(event) {
    let modelLength = this.element.nativeElement.value == null ? 0 : this.element.nativeElement.value.length;
    $('#' + this.element.nativeElement.id + 'Span').html(modelLength + '/' + this.maxLength);
  }
}

