import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
declare var $: any;

@Directive({
  selector: '[appTrimLeft]'
})
export class TrimLeftDirective {
  constructor(private element: ElementRef, private ngModel: NgModel) { }

  ngOnInit() {
    let that = this;
    $(that.element.nativeElement).bind("keyup", function () {
      setTimeout(() => {
        that.ngModel.update.emit(that.ngModel.value.trimLeft());
      })
    });

    $(that.element.nativeElement).bind("keydown.space", function () {
      setTimeout(() => {
        that.ngModel.update.emit(that.ngModel.value.trimLeft());
      })
    });

    $(that.element.nativeElement).bind("keydown", function () {
      setTimeout(() => {
        that.ngModel.update.emit(that.ngModel.value.trimLeft());
      })
    });
  }
}
