import { Component, OnInit } from '@angular/core';
import { ReqResService } from '../../service/req-res.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { PluginService } from '../../service/plugin.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {
  loading = false;
  itemData: any[];
  sortReverse: boolean;
  sortType: string;
  searchItem: string;
  itemObj: any = {};
  itemObjTemp: any;

  //del variables
  delId: number;
  delType: string;
  delCount: number;
  delBulkArray: any = [];
  delType2: string;

  _selectfirsttime: boolean = false;
  //del variables
  modalTitle: string = '';
  itemCount: number = 0;
  sortdirection: string = 'A';
  maxCount: number = 0;
  searchText: string = "";
  public _pluginservice;



  constructor(private reqRes: ReqResService, private pluginservice: PluginService) {
    this._pluginservice = this.pluginservice;
  }

  ngOnInit(): void {
    this.sortReverse = true;
    this.sortType = 'createdate';
    let json = {
      "Search": '',
      "Count": this.itemCount,
      "Direction": 'A',
      "SortType": 'createdate'
    };

    this.getItemData(JSON.stringify(json), 0);
    this._pluginservice.masterSelectedRefresh('itemBulk', []);
    this._pluginservice.checkedList = [];
  }
  //Item Group Data
  checkItemId: number = 0;
  getCheckedId(id) {
    this.checkItemId = id;
  }




  sortData(type) {
    this.sortType = type;
    this.itemCount = 0;
    this.sortdirection = (this.sortReverse) ? 'D' : 'A';
    this.searchItem = (this.searchItem) ? this.searchItem : '';
    let json = {
      "Search": this.searchItem,
      "Count": this.itemCount,
      "Direction": this.sortdirection,
      "SortType": this.sortType
    };
    this.getItemData(JSON.stringify(json), 0, 1);
  }

  getSearchData(search) {
    this.searchItem = (search) ? search : '';
    this.itemCount = 0;
    let json = {
      "Search": this.searchItem,
      "Count": this.itemCount,
      "Direction": this.sortdirection,
      "SortType": this.sortType
    };
    $("div[id^=threeNo] button").prop('disabled', false);
    this.getItemData(JSON.stringify(json), 0)
  }
  onScrollData(event: any) {
    this.searchItem = (this.searchItem) ? this.searchItem : '';
    if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight && this.itemCount <= this.maxCount) {
      this.itemCount = this.itemCount + 25;
      let json = {
        "Search": this.searchItem,
        "Count": this.itemCount,
        "Direction": this.sortdirection,
        "SortType": this.sortType
      };
      this.getItemData(JSON.stringify(json), 1);
    }
  }

  getItemData(json, ope, event = 0) {
    let gridParametrs: any = {};
    gridParametrs.type = 'item';
    gridParametrs.json = json;
    this.loading = true;
    this.reqRes.postData('/Item/GetItemList', gridParametrs).subscribe(data => {
      this.loading = false;
      this.itemData = (ope == 0) ? this.itemData = data.itemData : this.itemData.concat(data.itemData);
      this.maxCount = data.cnt;
      if (event == 0) {
        this.itemData = this.itemData.sort((a, b) => a.name.localeCompare(b.name));
        this.itemData = this.itemData.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      }
      if (this._pluginservice.checkedList.length == 1) {
        setTimeout(() => {
          $("#threeNo" + this.checkItemId + " button").prop('disabled', false);
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      } else if (this._pluginservice.checkedList.length > 1) {
        setTimeout(() => {
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      }
      this._pluginservice.masterSelectedRefresh('itemBulk');

    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }


  itemDuplicateOpe: boolean = false;
  itemOperation(id, ope) {
    this.spinner = false;
    this.itemDuplicateOpe = ope;
    this.itemObj.id = id;
    this.removeImage();
    this.modalTitle = (id == 0) ? 'Добавить товар ' : 'Изменит товар';
    this.reqRes.getData('/Global/GetModalData/' + id + '/item').subscribe(data => {
      this.itemObj = data;
      //JsBarcode("#barcode", this.itemObj.barcode, {
      //  format: "CODE128",

      //  width: 4,
      //  height: 40,
      //  displayValue: false

      //});
      const cloneOfObject = JSON.parse(JSON.stringify(this.itemObj));
      this.itemObjTemp = cloneOfObject;
      $('#modal_item').modal('show');
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }
  modalItemClose() {
    let result = this.pluginservice.compareObjects(this.itemObj, this.itemObjTemp, ["id"])
    if (result) {
      if (confirm('Вы хотите закрыть без сохранения??')) {
        $('#modal_item').modal('hide');
      }
    }
    else {
      $('#modal_item').modal('hide');
    }
  }
  spinner: boolean = false;

  printBarcode(id) {
    window.open('/prints/barcode/' + id, '_blank');
  }

  saveItem() {
    this.spinner = true;
    this.itemObj.id = this.itemDuplicateOpe ? 0 : this.itemObj.id;
    this.itemObj.subGroup = parseInt(this.itemObj.subGroup);
    this.itemObj.group = parseInt(this.itemObj.group);
    $('#btItemSave').attr('disabled', 'disabled');
    this.reqRes.postData('/Item/ItemOperation', this.itemObj).subscribe(data => {
      setTimeout(function () {
        $('#btItemSave').removeAttr('disabled');
      }, 1000)
      let returnedObj = data;
      if (this.itemObj.id == 0) {
        this.itemData.unshift(data)
      }
      else {
        let index = this.itemData.findIndex(item => item.id == returnedObj.id);
        this.itemData[index] = returnedObj;
      }
      this.itemData = this.itemData.sort((a, b) => a.name.localeCompare(b.name));
      toastr.success('Done!', '', { timeOut: 1000 });
      this.spinner = false;
      $("#modal_item").modal('hide');
    }, error => {
      toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
      console.log(error)
    })
  }



  modalDelete(delId, delType, delType2) {
    console.log(delId, delType, delType2)
    this.spinner = false;
    this._pluginservice.snipperFromBulk = false;
    this.delId = delId;
    this.delType = delType;
    this.delType2 = delType2;
      this.delCount = 1;
    $('#modalDelete').modal('show');
  }
  modalDeleteClose() {
    $('#modalDelete').modal('hide');
  }
  deleteItem(id, type, type2) {
    $("div[id^=threeNo] button").prop('disabled', false);
    $('#modalDelete').modal('hide');
      this.changeStatus(type, id, -1);
  }

  changeStatus(param, id, status): void {
    this.spinner = true;
    let statusObj: any = {};
    statusObj.param = param;
    statusObj.id = id;
    statusObj.status = status;
    this.reqRes.postData('/Global/ChangeStatus', statusObj).subscribe(data => {
      if (param == 'itemStatus' && status == -1) {
        let index = this.itemData.findIndex(item => item.id == id);
      this.itemData.splice(index, 1);
      toastr.success('Done!', '', { timeOut: 1000 });
      }
      this.spinner = false;
    }, error => {
      toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
      console.log(error)
    })
  }

  //#region General tab
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageData: any = '';
  showcroppedope: any = 0;
  imageSrc: string;
  errorImage: string;
  errorMessage: string;
  localStorageData: any = [];
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (event.target.files[0].type.includes("image/")) {
      $("#modal_item").modal('hide');
      $("#modal_file").modal('show');
      this.errorImage = '';
    } else {
      //this.userForm.patchValue({
      //  imgPath: ' '
      //})
      this.errorImage = 'Пожалуйста, введите допустимый формат изображения. '
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  selectImage() {
    this.spinner = true;
    this.itemObj.img = this.croppedImage;
    this.closeFile();
    this.spinner = false;
  }
  closeFile() {
    $("#modal_file").modal('hide');
    $("#modal_item").modal('show');
  }
  removeImage() {
    $('#file').val('');
    this.itemObj.img = '';
  }
  clickToUpload() {
    if (this.itemObj.img == '') {
      $('#file').click();
    }
  }
  //#endregion



  changeGroup(groupId) {
    this.reqRes.getData('/Item/GetSubgroupByGroupId/' + groupId).subscribe(data => {
      this.itemObj.subGroup = 0;
      this.itemObj.subGroupList = data;
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }


  vendorObj: any ={};
  addVendor() {
    this.reqRes.getData('/Global/GetModalData/0/vendor').subscribe(data => {
      this.vendorObj = data;
      $("#modal_item").modal('hide');
      $('#modal_vendor').modal('show');
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }
  saveVendor() {
    this.spinner = true;
    $('#btnVendorSave').attr('disabled', 'disabled');
    this.reqRes.postData('/Item/VendorOperation', this.vendorObj).subscribe(data => {
      setTimeout(function () {
        $('#btnVendorSave').removeAttr('disabled');
      }, 1000)
      this.itemObj.vendorList.push({ id: data, name: this.vendorObj.name });
      this.itemObj.vendor = data;
      this.modalVendorClose();
    }, error => {
      toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  modalVendorClose() {
    $('#modal_vendor').modal('hide');
    $("#modal_item").modal('show');
  }




  groupObj: any = {};
  addGroup() {
    this.reqRes.getData('/Global/GetModalData/0/group').subscribe(data => {
      this.groupObj = data;
      $("#modal_item").modal('hide');
      $('#modal_group').modal('show');
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }
  saveGroup() {
    this.spinner = true;
    $('#btnGroupSave').attr('disabled', 'disabled');
    this.reqRes.postData('/Item/GroupOperation', this.groupObj).subscribe(data => {
      setTimeout(function () {
        $('#btnGroupSave').removeAttr('disabled');
      }, 1000)
      this.itemObj.groupList.push({ id: data, name: this.groupObj.name });
      this.itemObj.group = data;
      this.itemObj.subGroupList = [];
      this.modalGroupClose();
    }, error => {
      toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  modalGroupClose() {
    $('#modal_group').modal('hide');
    $("#modal_item").modal('show');
  }

  subGroupObj: any = {};
  addSubGroup() {
    this.reqRes.getData('/Global/GetModalData/0/subgroup').subscribe(data => {
      this.subGroupObj = data;
      $("#modal_item").modal('hide');
      $('#modal_subgroup').modal('show');
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }
  saveSubGroup() {
    this.spinner = true;
    $('#btnSubGroupSave').attr('disabled', 'disabled');
    this.reqRes.postData('/Item/SubGroupOperation', this.subGroupObj).subscribe(data => {
      setTimeout(function () {
        $('#btnSubGroupSave').removeAttr('disabled');
      }, 1000)
      this.itemObj.subGroupList.push({ id: data, name: this.subGroupObj.name });
      this.itemObj.subGroup = data;
      this.modalSubGroupClose();
    }, error => {
      toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  modalSubGroupClose() {
    $('#modal_subgroup').modal('hide');
    $("#modal_item").modal('show');
  }
}
