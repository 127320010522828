<div class="row">
  <div class="col-12 mb-4">
    <div class="card mt-3">
      <div class="card-header">
        <div class="row align-items-center justify-content-between">
          <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
            <h5 class="mb-0 text-nowrap py-2 py-xl-0">Продажи</h5>
          </div>
          <div class="col-8 col-sm-auto ml-auto text-right pl-0">
            <div class="dashboard-actions">
              <div id="divSearchFilter" class="d-inline-block position-relative mr-2">
                <input type="search" class="form-control" placeholder="Поиск..." [(ngModel)]="searchInvoice" (change)="getSearchData(searchInvoice)" (keyup)="getSearchData(searchInvoice)">
              </div>
              <div class="btn-group" id="itemGroupButtons" style="margin-top: -3px;">
                <button class="btn btn-success" (click)="invoiceOperation(0)"
                        data-toggle="modal" type="button">
                  <span class="fas fa-plus d-inline-block d-sm-none"
                        data-fa-transform="shrink-3 down-2"></span>
                  <span class="d-none d-sm-inline-block">
                    Создать новый
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div [style.opacity]="loading ? '0' : '1'">
          <div class="card-body p-0 card-scroll" (scroll)="onScrollData($event)">
            <div class="falcon-data-table">
              <table class="table table-sm">
                <thead class="thead-dark sticky-header">
                  <tr>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-300px" (click)="sortType = 'code';sortReverse = !sortReverse;sortData('code');">
                      Номер
                      <span *ngIf="sortType == 'code' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'code' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-200px" (click)="sortType = 'customer';sortReverse = !sortReverse;sortData('customer');">
                      Покупатель
                      <span *ngIf="sortType == 'customer' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'customer' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>

                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-100px" (click)="sortType = 'total';sortReverse = !sortReverse;sortData('total');">
                      Сумма
                      <span *ngIf="sortType == 'total' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'total' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>

                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-200px" (click)="sortType = 'date';sortReverse = !sortReverse;sortData('date');">
                      Дата
                      <span *ngIf="sortType == 'date' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'date' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>

                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-100px" (click)="sortType = 'status';sortReverse = !sortReverse;sortData('status');">
                      Статус
                      <span *ngIf="sortType == 'status' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'status' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>


                    <th class="pr-1 py-2 align-middle min-w-60px">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="btn-reveal-trigger tr-hover" *ngFor="let i of invoiceData">
                    <th class="align-middle">
                      <a href="javascript:void(0)" (click)="invoiceOperation(i.id)">
                        {{i.code}}
                      </a>
                    </th>
                    <td class="align-middle">
                      {{i.customer}}
                    </td>
                    <td class="align-middle "> {{i.total | number : '1.2-2'}}<span> руб.</span></td>
                    <td class="align-middle ">
                      {{i.date}}
                    </td>
                    <td class="align-middle ">
                      <span class="badge badge-pill badge-soft-{{i.statusColor}} fs-14 align-middle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{i.statusName}}
                      </span>
                    </td>
                    <td class="min-w-60px">
                      <div class="d-flex justify-content-between">
                        <span class="px-1 text-danger grid-icons cursor-pointer" (click)="modalDelete(i.id,'invoiceStatus','invoiceStatus')">
                          <i class="fas fa-trash" title="Удалить"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody class="text-center w-100 py-3 bg-light" *ngIf="invoiceData?.length==0">
                  <tr>
                    <td colspan="8" class="px-2 py-3 bg-light text-center w-100">
                      <p class="text-center bold-text font-size18px m-0">Данные недоступны</p>
                      <p class="text-center m-0 text-gray font-size13px">Нам нечего показать прямо сейчас</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ngx-loading [show]="loading" class="z-index1000"></ngx-loading>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal fade z-index10000 px-0 overflow-auto" id="modal_invoice" tabindex="-1" role="dialog" aria-labelledby="modal_invoice" data-backdrop="static" data-keyboard="true"
     aria-hidden="true">
  <div class="modal-dialog modal-width100per m-0" role="document">
    <div class="modal-content border-0 outline-0border-radius0">
      <div class="modal-header sticky-panel-estimate-details">
        <div class="container_bs">
          <h5 class="mb-0 modal-title pl-2 pl-lg-6" id="exampleModalLabel">
            Инвойс #{{invoiceObj.code}}
            <br />
          </h5>

          <button class="close position-relative bottom27px" type="button" aria-label="Close" id="X-ModalActivity" data-dismiss="modal" (click)="invoiceReturnData()">
            <span class="font-weight-light" aria-hidden="true">&times;</span>
          </button>
          <button class="btn btn-falcon-default mr-1 f-xs-13 btn-sm position-relative bottom30px float-right" type="button" (click)="printInvoice()">
            <i class="fas fa-print"></i>  <span class="ml-2">Печать</span>
          </button>
          <button class="btn btn-danger mr-1 f-xs-13 btn-sm position-relative bottom30px float-right mr-3" type="button" (click)="modalDelete(invoiceObj.id,'invoiceStatus','invoiceStatus')">
            <i class="fas fa-trash"></i>  <span class="ml-2">Удалить</span>
          </button>

        </div>

      </div>
      <div class="modal-body bg-gray_ est-detail-body-pad">
        <div class="container_bs">
          <div class="card mb-3 shadow-none">
            <div class="card-body bg-gray_" style="min-height:85vh">
              <div class="row">
                <div id="printDiv" class="col-md-12 bg-white p-3 p-lg-6 res-preview-height-left  order-sm-1 order-2">

                  <div class="row">
                    <div class="col-lg-6">
                      <span class="badge badge-pill badge-soft-{{invoiceObj.statusColor}} fs-14 align-middle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{invoiceObj.statusName}}
                      </span>
                      <div class="mt-4">
                        <label>Комментарий</label>
                        <textarea class="form-control" rows="4" [(ngModel)]="invoiceObj.comment" (keyup)="invoiceChangeOperation('note',invoiceObj.comment,invoiceObj.id)"></textarea>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="float-right">

                        <div class="mb-2 text-right">
                          <button class="btn btn-sm btn-primary" *ngIf="invoiceObj.balance!=0" (click)="paymentApply()">Принять Оплату</button>
                        </div>


                        <div style="max-width:180px">
                          <label class="mb-0">Дата</label>
                          <input type="date" class="form-control" (focusout)="invoiceChangeOperation('date',invoiceObj.date+' '+ invoiceObj.time,invoiceObj.id)" [(ngModel)]="invoiceObj.date" />
                        </div>
                        <div class="mt-1" style="max-width: 180px">
                          <label class="mb-0">Время</label>
                          <input class="form-control datetimepicker" id="datetime" type="text" placeholder="H:i" data-options='{"enableTime":true,"noCalendar":true,"time_24hr":true,"dateFormat":"H:i","disableMobile":true}'
                                  [(ngModel)]="invoiceObj.time"/>
                        </div>
                        <div class="mt-1" style="max-width: 220px">

                          <div class="d-flex justify-content-between align-items-center">
                            <label for="groupp" class="mb-0">Покупатель</label>
                            <button class="btn btn-link btn-sm pr-0 pb-0 fs-12" type="button" (click)="addCustomer()">Добавить</button>
                          </div>
                          <select class="form-control select2 text-left" id="invoiceCustomer" [(ngModel)]="invoiceObj.customerId">
                          </select>
                        </div>

                      </div>
                    </div>
                  </div>



                  <div class="row mt-3">
                    <div class="col-lg-12 text-left">
                      <button class="btn btn-sm btn-success mb-3" (click)="addDetail()">Добавить</button>
                    </div>

                    <table class="table table-striped">
                      <thead class="bg-light sticky-header">
                        <tr>
                          <th>Товар</th>
                          <th>Количество</th>
                          <th>Цена</th>
                          <th *ngIf="invoiceObj.discount>0">Спец. цена</th>
                          <th>Сумма</th>
                          <th class="min-w-60px"></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let d of invoiceObj.detail; let ind_=index;" class="tr-hover">
                          <td>
                            {{d.item}}
                            <br />
                            <span class="text-muted"> {{d.group}}</span>
                          </td>
                          <td><input class="form-control" style="max-width:90px" type="number" min="1" max="{{d.stockItemQty}}" (keyup)="qtyChange(d.stockItemQty,ind_,'qty',d.qty,d.id)" title="Qty" name="Qty" id="Qty{{d.id}}" [(ngModel)]="d.qty" appSelectedInput  tabindex="{{ind_+1}}" /></td>
                          <td><span [ngClass]="{'line':invoiceObj.discount>0}">{{d.rate | number : '1.2-2'}}</span></td>
                          <td *ngIf="invoiceObj.discount>0">{{d.specRate | number : '1.2-2'}}</td>
                          <td>{{d.total | number : '1.2-2'}}</td>
                          <td class="w-30px">
                            <span class="px-1 text-danger grid-icons cursor-pointer" (click)="modalDelete(d.id,'invoiceDetailStatus','invoiceDetailStatus')">
                              <i class="fas fa-trash" title="Удалить"></i>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>


                  <div class="row mt-3">
                    <div class="col-md-8"></div>
                    <div class="col-md-4">
                      <table class="table table-sm table-borderless mt-5 fs-14">
                        <tr>
                          <th class="font-weight-bold text-dark font-size21px">Итого</th>
                          <td class="font-weight-bold text-right text-dark font-size21px"> {{invoiceObj.total | number : '1.2-2'}}<span *ngIf="invoiceObj.total"> руб.</span></td>
                        </tr>
                        <tr>
                          <th class="text-dark fs-14 fw-400">Скидка % </th>
                          <td class="text-dark text-right fs-14 fw-400" style="max-width:120px"> <input type="number" appSelectedInput class="form-control text-right" (keyup)="invoiceChangeOperation('discount',invoiceObj.discount,invoiceObj.id)" autocomplete="nope" appNumbersOnly appPercentLimit [(ngModel)]="invoiceObj.discount" /></td>
                        </tr>
                        <tr>
                          <th class="text-dark fs-14 fw-400">Оплачено</th>
                          <td class="text-dark text-right fs-14 fw-400"> {{invoiceObj.payed | number : '1.2-2'}}<span *ngIf="invoiceObj.total"> руб.</span></td>
                        </tr>
                        <tr>
                          <th class="text-dark fs-14 fw-400">Задолженность</th>
                          <td class="text-dark text-right fs-14 fw-400"> {{invoiceObj.balance | number : '1.2-2'}}<span *ngIf="invoiceObj.total"> руб.</span></td>
                        </tr>
                      </table>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Modal Customer-->
<div class="modal fade px-0" id="modalCustomer" tabindex="-1" role="dialog" aria-labelledby="modalCustomer" data-backdrop="static" data-keyboard="false"
     aria-hidden="true" (keydown.esc)="modalCustomerClose()">
  <div class="modal-dialog modal-width-300px" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <span>Новый покупатель</span>
        </h5>
        <button class="close" type="button" aria-label="Close" (click)="modalCustomerClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-padding">
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label for="name">Имя <span class="text-danger">*</span></label>
              <input class="form-control" id="name" type="text" [(ngModel)]="newCustomerObj.name" required appTrimLeft autocomplete="off" tabindex="1" />
            </div>
          </div>
          <div class="col-sm-12 col-12">
            <div class="form-group mb-1">
              <label for="leadPhone" class="fs-12 mb-0 text400">Телефон</label>
              <input type="text" name="phone" id="phone" [(ngModel)]="newCustomerObj.phone" class="form-control" mask="000-000-0000" [dropSpecialCharacters]="false" title="Customer Phone" tabindex="3" autocomplete="nope" appTrimLeft />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-falcon-default" type="button" (click)="modalCustomerClose()">Отменить</button>
        <button class="btn btn-primary" id="btnSaveCustomer" type="button" [disabled]="!newCustomerObj.name" (click)="saveCustomer()">
          <div class="g_spinner" *ngIf="spinner"></div>
          Сохранить
        </button>
      </div>
    </div>
  </div>
</div>
<!-- -->






<!--Modal Add Item-->
<div class="modal fade px-0" style="padding-left: 0 !important;padding-right: 0 !important;" id="detailModal" tabindex="-1" aria-labelledby="detailModal" data-backdrop="static" data-keyboard="false"
     aria-hidden="true" (keydown.esc)="modalAddDetailClose()">
  <div class="modal-dialog modal-width-450px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Добавить товар
        </h5>
        <button class="close" type="button" aria-label="Close" data-dismiss="modal" (click)="modalAddDetailClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-scroll mb-2 modal-el-scroll" style="padding: 1rem 2rem !important;">
        <div class="row">
          <div class="col-12">
            <div id="customerSection">
              <label for="customerName">
                Введите имя товара
                <span class="text-danger">*</span>
              </label>
              <input id="costLineName" name="costLineName" class="form-control form-control-sm" [(ngModel)]="addItemObj.search" title="Товар" tabindex="1" autofocus appTrimLeft (keyup)="searchItems(addItemObj.search);"  (keyup.enter)="searchEnterItems(addItemObj.search);" />
              <div style="max-height:200px;overflow-y:auto">
                <ul class="list-group" *ngFor="let itm of itemLists">
                  <li class="list-group-item cursor-pointer hover-200 py-1 px-2" (click)="clickItem(itm.id)">{{itm.name}}</li>
                </ul>
                <span class="text-danger" *ngIf="barcodeMessage">{{barcodeMessage}}</span>
              </div>

            </div>
          </div>
        </div>
        <!--<div class="row" *ngIf="addItemObj.item">
      <div class="col-6">
        <div class="form-group">
          <label for="qtyAddItem">Количество</label>
          <input id="qtyAddItem" autocomplete="off" type="number" max="{{addItemObj.stockItemQty}}" (keyup)="handleChange(addItemObj.stockItemQty)" min="0" style="min-width:80px" [(ngModel)]="addItemObj.qty" title="Кол-во" name="Qty" appSelectedInput class="form-control form-control-sm text-center" />
        </div>
      </div>
    </div>-->







        <!--<div class="row" *ngIf="addItemObj.stockItemQty!=0">
          <div class="col-12">
            <span class="text-muted">На нашем складе {{addItemObj.stockItemQty}} шт.</span>
          </div>
        </div>-->
      </div>






      <div class="modal-footer">
        <button class="btn btn-sm btn-falcon-default" type="button" (click)="modalAddDetailClose()">Закрыть</button>
        <!--<button class="btn btn-sm btn-primary" id="btnSpecificationSave2" type="button" (click)="saveDetailPopup()" [disabled]="!addItemObj.id && addItemObj.qty==0">
          <div class="g_spinner" *ngIf="spinner"></div>
         Добавить
        </button>-->
      </div>
    </div>
  </div>
</div>
<!---->








<!-- Modal Payment-->
<div class="modal fade px-0" style="padding-left:0!important;padding-right:0!important" id="modal_payment" tabindex="-1" attributes="dialog" aria-labelledby="modal_payment" data-backdrop="static" data-keyboard="false"
     aria-hidden="true">
  <div class="modal-dialog modal-width-400px" attributes="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Принять Оплату
        </h5>
        <button class="close" type="button" aria-label="Close" data-dismiss="modal">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-padding modal-body-scroll mb-0 modal-el-scroll" name="PaymentModal" id="PaymentModalBody">
        <div class="row">
          <div class="col-6">
            <div class="form-group mb-1 mb-07-rem">
              <label for="paymentAmount" class="fs-12 mb-0 text400">Сумма <span class="text-danger">*</span></label>
              <span class="dol-sign"><i class="fas fa-ruble-sign"></i></span>
              <input class="form-control text-right pl-25px" id="price" name="price" (keyup)="changeKupyura()" [(ngModel)]="paymentObj.price" mask="separator" appSelectedInput thousandSeparator="," autocomplete="off" tabindex="1">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="transactionDate" class="fs-12 mb-0 text400">Дата транзакции <span class="text-danger">*</span></label>
              <input type="date" id="transactionDate" name="transactionDate" class="form-control form-control" title="Дата транзакции" [(ngModel)]="paymentObj.transactionDate" tabindex="2" />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="lastdigit">Купюра</label>
              <input type="text" name="lastdigit" [(ngModel)]="paymentObj.kupyura" (keyup)="changeKupyura()" id="kupura" class="form-control form-control text-right" tabindex="3" appSelectedInput thousandSeparator="," autocomplete="nope" appTrimLeft />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="lastdigit">Сдача</label>
              <input type="number" name="lastdigit" [(ngModel)]="paymentObj.sdachi" id="sdachi" class="form-control form-control text-right" tabindex="4" autocomplete="nope" appTrimLeft disabled />
            </div>
          </div>


        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-falcon-default btn" type="button" data-dismiss="modal" tabindex="5">Отменить</button>
        <button class="btn btn-primary btn" id="btnPayment" (click)="savePayment()" [disabled]="paymentObj.price==0 || !paymentObj.transactionDate" type="button" tabindex="6">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</div>
<!---->
<!-- Modal Delete-->
<div class="modal fade z-index10001 pr-0" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     data-backdrop="static" data-keyboard="false" aria-hidden="true" (keydown.esc)="modalDeleteClose()">
  <div class="modal-dialog modal-dialog-center modal-delete-width" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <div class="modal-delete-header-icondiv">
          <i class="far fa-times-circle text-center modal-delete-circle-icon"></i>
        </div>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close"
                (click)="modalDeleteClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-center fs-20 fw-600">Ты уверен?</p>
        <p class="text-muted fs-13 pl-3 pr-3 text-center">Вы действительно хотите удалить эту запись? Этот процесс нельзя отменить.</p>
      </div>
      <div class="modal-delete-footer">
        <button class="btn btn-sm mr-2 modal-delete-footer-cancel" type="button" data-dismiss="modal" (click)="modalDeleteClose()">Отменить</button>
        <button class="btn btn-danger btn-sm" type="button" (click)="deleteItem(delId,delType,delType2)">
          <div class="g_spinner" *ngIf="spinner"></div>
          Удалить
        </button>
      </div>
    </div>
  </div>
</div>
<!---->
