import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth/auth.service';
import { ReqResService } from '../service/req-res.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  id: number = 0;
  constructor(private router: Router, private authService: AuthService, private reqRes: ReqResService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isAuthenticated()) {
      //let page = next.url?.length == 0 ? '' : next.url[0].path;
      //let detailid = next.url[1]?.path == undefined ? 0 : Number(next.url[1]?.path);
      //this.reqRes.getData('/Global/CheckPageOpe/' + detailid + '/' + page).subscribe(
      //  data => {
      //    let permissionOpe = data.permissionOpe;
      //    let detailPermissionOpe = data.detailPermissionOpe;
      //    if (permissionOpe == 0) {
      //      this.router.navigate(['/error403']);
      //    }
      //    else if (detailPermissionOpe == 0) {
      //      this.router.navigate(['/error-data-access']);
      //    }
      //  },
      //  error => { console.log(error); this.router.navigate(['/error403']); }
      //)
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
