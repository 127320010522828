<main class="main" id="top">
  <div class="container" data-layout="container">
    <div class="row flex-center min-vh-100 py-6 text-center">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
        <div class="card">
          <div class="card-body p-4 p-sm-5">
            <div class="display-1 text-300 fs-error">404</div>
            <p class="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">The page you're looking for is not found.</p>
            <hr />
            <a class="btn btn-primary btn-sm mt-3" [routerLink]="'/'"><span class="fas fa-home mr-2"></span>Take me home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
