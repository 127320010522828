<html>
<head>
  <style>
    @media print {
      @page {
        size: A4 portrait;
        margin: 2cm;
      }

      ::-webkit-scrollbar {
        display: none;
      }

      body, html {
        width: 100%;
        height: 100%;
        -webkit-print-color-adjust: exact;
      }

      #printDiv {
        overflow-y: scroll;
        width: 100%;
        min-height: 100vh;
        height: auto;
        z-index: 100000; /* CSS doesn't support infinity */
        /* Any other Print Properties */
      }
    }
  </style>
</head>
<body>
  <div id="printDiv" class="col-md-12 bg-white p-3 p-lg-6 overflow-y-scroll res-preview-height-left  order-sm-1 order-2">
    <div>
      <div class="row justify-content-between">
        <div class="col">
          <div class="estimate-company-logo" *ngIf="invoiceDetailData.companyLogo">
            <img src="{{invoiceDetailData.companyLogo}}" title="Company Logo" alt="Company Logo" />
          </div>
          <h5 *ngIf="!invoiceDetailData.companyLogo">{{invoiceDetailData.company}}</h5>
          <div>
            <table class="table table-sm table-borderless fs-18 text-dark">
              <tbody>
                <tr>
                  <th class="text-sm-left fw-400 p-0">
                    {{invoiceDetailData.companyStreet}}
                    <br />{{invoiceDetailData.companyCity}}, {{invoiceDetailData.companyState}} {{invoiceDetailData.companyZip}}
                  </th>
                </tr>
                <tr>
                  <th class="text-sm-left fw-400 p-0">{{invoiceDetailData.webSite}}</th>
                </tr>
                <tr>
                  <th class="text-sm-left fw-400 p-0">{{invoiceDetailData.companyPhone}}</th>
                </tr>
              </tbody>
            </table>
            <table class="table table-sm table-borderless fs-18 text-dark">
              <tbody>
                <tr>
                  <th class="text-sm-left fw-400 p-0 fs-22 fw-700">{{invoiceDetailData.customerName}}</th>
                </tr>
                <tr class="text-sm-left">
                  <th class="text-sm-left fw-400 p-0">
                    {{invoiceDetailData.customerStreet}}
                    <br />{{invoiceDetailData.customerCity}} {{invoiceDetailData.customerState}} {{invoiceDetailData.customerZip}}
                  </th>
                </tr>
                <tr class="text-sm-left">
                  <th class="text-sm-left fw-400 p-0">
                    {{invoiceDetailData.customerPhone}}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="col-sm-auto ml-auto">
          <div class="table-responsive">
            <table class="table table-sm table-borderless fs-18 text-dark">
              <tbody>
                <tr>
                  <th class="text-sm-right fs-22 fw-700 p-0">WORK ORDER</th>
                </tr>
                <tr>
                  <th class="text-sm-right fw-400 p-0">{{invoiceDetailData.invoiceName}} #{{invoiceDetailData.code}}</th>
                </tr>
                <tr>
                  <th class="text-sm-right fw-400 p-0">Rep: <span *ngFor="let ass of invoiceDetailData.assigner;let lst = last;">{{ass.name}}<span *ngIf="!lst">, </span></span></th>
                </tr>
                <tr>
                  <th class="text-sm-right fw-400 p-0">Date: {{invoiceDetailData.date}}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="fs-18">
      <div *ngFor="let spec of invoiceDetailData?.specification; let firstSpec = first; let indx = index;let lst = last;">
        <table class="table mb-0 table-bordered table-preview" [ngClass]="{'bt-0':!firstSpec}">
          <thead *ngIf="!(spec.specificationId==0 && invoiceDetailData?.specification.length==1)" class="group-color">
            <tr>
              <th  class="bt-0 fs-18 bl-0 text-dark" style="border-right:none !important">
                {{spec.name}}
                <br /><span class="fs-18 fw-400">{{spec.desc}}</span>
              </th>
              <th class="bt-0 fs-18 text-center bl-0 text-dark width-15-per align-middle" style="border-left:none !important"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cl of spec.costLine;">
              <td class="fs-18 align-middle text-dark" *ngIf="cl.qty!=0">
                {{cl.name}}<span *ngIf="cl.selectedVariationId!=0"> - {{cl.selectedVariationName}}</span>
                <div class="fs-18 text-muted">{{cl.desc}}</div>
              </td>
              <td class="fs-18 width-15-per align-middle text-center text-dark" *ngIf="cl.qty!=0"><span>{{cl.qty}} {{cl.unit}}</span> </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</body>
</html>
