<main class="main bgclr-white" id="top">
  <div class="container-fluid" data-layout="container">
    <div class="flex-center min-vh-100">
      <div class="p-sm-5 p-3" [hidden]="savedOpe==1">
        <div class="row">
          <div class="col-md-4 col-12">
            <div class="form-group">
              <!--[ngClass]="{'invalid':!webLeadObj.name}"-->
              <label>Name <span class="required-star">*</span></label>
              <input class="form-control" type="text" name="name" [(ngModel)]="webLeadObj.name" appTrimLeft autocomplete="off" autofocus tabindex="1" required />
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="form-group">
              <!--[ngClass]="{'invalid':!webLeadObj.phone}"-->
              <label>Phone</label>
              <input class="form-control" id="phone" type="tel" name="phone" mask="000-000-0000" [dropSpecialCharacters]="false" [(ngModel)]="webLeadObj.phone" autocomplete="off" tabindex="2" />
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="form-group">
              <!--[ngClass]="{'invalid':(!webLeadObj.email || (emailCustomer.invalid && (emailCustomer.dirty || emailCustomer.touched)))}"-->
              <label>Email <span class="required-star">*</span></label>
              <input class="form-control" id="emailCustomer" name="emailCustomer" type="email" [(ngModel)]="webLeadObj.email"
                     pattern="^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$"
                     #emailCustomer="ngModel" appTrimLeft autocomplete="off" tabindex="3" required  (focusout)="fcsOutEmail()" (keyup)="chngEmail()"/>
              <div *ngIf="!showAlertEmail" class="validation">
                Please input valid e-mail
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-12">
            <div class="form-group">
              <!--[ngClass]="{'invalid':!webLeadObj.projectType}"-->
              <label>Type <span class="required-star">*</span></label>
              <select class="form-control" id="projectType" name="project-type" [(ngModel)]="webLeadObj.projectType" tabindex="4">
                <option [ngValue]="0">Choose</option>
                <option [ngValue]="pl.id" *ngFor="let pl of webLeadObj.projectList">{{pl.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>City <span class="required-star">*</span></label>
              <input class="form-control" id="city" name="city" type="text" [(ngModel)]="webLeadObj.city" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1)" appTrimLeft autocomplete="off" tabindex="7" />
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>ZIP Code <span class="required-star">*</span></label>
              <input class="form-control" id="zipCode" name="zipCode" type="text" mask="00000" [(ngModel)]="webLeadObj.zipCode" appTrimLeft autocomplete="off" tabindex="8" />
            </div>
          </div>
          <!--<div class="col-md-4 col-12">
    <div class="form-group">
      <label>Budget</label>
      <div class="form-group mb-3 mb-07-rem">
        <span class="dol-sign"><i class="fas fa-dollar-sign"></i></span>
        <input class="form-control text-right pl-25px" id="budget" name="budget" [(ngModel)]="webLeadObj.budget" mask="separator" thousandSeparator="," appSelectedInput autocomplete="off" tabindex="5">
      </div>
    </div>
  </div>
  <div class="col-md-4 col-12">
    <div class="form-group">
      <label for="expectStartdate">Expected Start Date</label>
      <input type="date" id="expectStartdate" name="expectStartdate" class="form-control" title="Expected Start Date" max="2099-01-01" min="{{webLeadObj.expectMinStartdate}}" [(ngModel)]="webLeadObj.expectStartdate" tabindex="6" />
    </div>
  </div>-->
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Message</label>
              <textarea name="note" id="note" cols="30" rows="4" class="form-control description-style" [(ngModel)]="webLeadObj.note" maxLength="500" appTrimLeft autocomplete="off" tabindex="9"></textarea>
            </div>
          </div>
        </div>
        <!--<div class="row">
          <div class="col-12">
            <div id="dropzone" class="dropzone dropzone-multiple p-0" data-dropzone data-options='{"url":"Client/UploadFile/"}'>
              <div class="fallback">
                <input type="file" name="file2">
              </div>
              <div class="dz-message" data-dz-message> <img class="mr-2" src="../assets/img/icons/cloud-upload.svg" width="25" alt="">Drop your files here</div>
              <div class="dz-preview dz-preview-multiple m-0 d-flex flex-column">
              </div>
            </div>
          </div>
        </div>-->
        <div class="row">
          <div class="col-12">
            <div class="form-group text-center">
              <!--fileUploadOpe==0 || webLeadObj.fileUploadOpe==0 ||-->
              <button class="mt-1 btn btn-primary " id="btnSubmit" [disabled]="!webLeadObj.name || !webLeadObj.city || !webLeadObj.zipCode || !webLeadObj.projectType || disableButton==1 || !webLeadObj.email || !showAlertEmail" (click)="saveWebLead()">Submit</button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" [hidden]="savedOpe==0">
        <img class="d-block mx-auto pt-10 mb-5" src="assets/img/icons/sent.svg" alt="shield" width="70">
        <h4 class="mb-3">Done!</h4>
        <p>We appreciate you contacting us. <br />One of our managers will get back in touch with you soon.</p>
      </div>
    </div>
  </div>
</main>
