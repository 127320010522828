import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReqResService } from '../../service/req-res.service';
import { PaginationInstance } from 'ngx-pagination';
import { PluginService } from '../../service/plugin.service';
import { AuthService } from '../../service/auth/auth.service'
import * as moment from 'moment';
import { OrderPipe } from 'ngx-order-pipe';
import { Title } from '@angular/platform-browser';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-tradePartners',
  templateUrl: './tradePartners.component.html',
  styleUrls: ['./tradePartners.component.css']
})
export class TradepartnersComponent implements OnInit {

  loading = false;
  tabid: number = 1;
  sortReverse: boolean;
  sortType: string;
  searchTradePartner: string = '';
  tradePartnerData: any[];
  statusObj: any = {};
  //del variables
  delId: number;
  delType: string;
  delCount: number;
  delType2: string;
  delBulkArray: any = [];

  tradePartnerCount: number = 0;
  tradePartnerMaxCount: number = 0;
  sortdirection: string = 'A';
  public _authService;
  public _pluginservice;
  constructor(private titleService: Title,private router: Router, private route: ActivatedRoute, private reqRes: ReqResService, private pluginservice: PluginService, private authService: AuthService, private orderPipe: OrderPipe) {
    this._authService = this.authService;
    this._pluginservice = this.pluginservice;
    this.titleService.setTitle('Trade Partners');
  }
  ngOnInit(): void {
    this.sortReverse = true;
    this.sortType = 'createdate';

    let json = {
      "Search": '',
      "Count": this.tradePartnerCount,
      "Direction": 'A',
      "SortType": this.sortType
    };

    this.getTradePartnersData(JSON.stringify(json), 0);
    this._pluginservice.masterSelectedRefresh('tradePartnerBulk', []);
    this._pluginservice.checkedList = [];
  }
  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  searchInputOperation() {
    $('.custom-search-input').focus();
    $("[data-toggle='tooltip']").tooltip('hide');
  }

  openFilter() {
    $("#divSearchFilter").removeClass("d-none")
    $("#divSearchFilter").addClass("d-inline-block")
    $("#divSearchFilter").animate({ right: '0px' }, "slow");
    $("#btnSearchFilter").hide();
    $("#inputSearchFilter").focus();
  }
  closeFilter() {
    $("#divSearchFilter").removeClass("d-inline-block")
    $("#divSearchFilter").addClass("d-none")
    $("#btnSearchFilter").show();
  }

  sortData(type) {
    this.sortType = type;
    this.tradePartnerCount = 0;
    this.sortdirection = (this.sortReverse) ? 'D' : 'A';
    this.searchTradePartner = (this.searchTradePartner) ? this.searchTradePartner : '';
    let json = {
      "Search": this.searchTradePartner,
      "Count": this.tradePartnerCount,
      "Direction": this.sortdirection,
      "SortType": this.sortType
    };
    this.getTradePartnersData(JSON.stringify(json), 0);
    //this._pluginservice.masterSelectedRefresh('listviewBulk', []);
    //this._pluginservice.checkedList = [];
  }

  onScrollData(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight && this.tradePartnerCount <= this.tradePartnerMaxCount) {
      this.tradePartnerCount = this.tradePartnerCount + 25;
      let json = {
        "Search": this.searchTradePartner,
        "Count": this.tradePartnerCount,
        "Direction": this.sortdirection,
        "SortType": this.sortType
      };
      this.getTradePartnersData(JSON.stringify(json), 1);
      //$("div[id^=threeNo] button").prop('disabled', false);
    //  this._pluginservice.UnCheckAll(this.tradePartnerData);
    }
  }

  getSearchData(search) {
    this.searchTradePartner = (search && (search != "'" || search != "/")) ? search : '';
    this.tradePartnerCount = 0;
    this.sortType = (this.searchTradePartner) ? 'name_' : 'createdate';
    let json = {
      "Search": this.searchTradePartner,
      "Count": this.tradePartnerCount,
      "Direction": (this.searchTradePartner) ? 'D' : 'A',
      "SortType": this.sortType
    };
    $("div[id^=threeNo] button").prop('disabled', false);
    //this._pluginservice.masterSelectedRefresh('listviewBulk', []);
    //this._pluginservice.checkedList = [];
    this.getTradePartnersData(JSON.stringify(json), 0)
  }

  getTradePartnersData(json, ope) {
    this.loading = true;

    let GridParametrs: any = {};
    GridParametrs.json = json;

    this.reqRes.postData('/People/GetTradePartnersData', GridParametrs).subscribe(data => {
      this.loading = false;
      this.tradePartnerData = (ope == 0) ? this.tradePartnerData = data.data : this.tradePartnerData.concat(data.data);
      this.tradePartnerMaxCount = data.cnt;
   //   this._pluginservice.masterSelectedRefresh('tradePartnerBulk');
      if (this._pluginservice.checkedList.length == 1) {
        setTimeout(() => {
          $("#threeNo" + this.checkItemId + " button").prop('disabled', false);
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      } else if (this._pluginservice.checkedList.length > 1) {
        setTimeout(() => {
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      }
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }
  checkItemId: number = 0;
  getCheckedId(id) {
    this.checkItemId = id;
  }

  changeStatus(param, id, status, param2): void {
    if (param2 == 'changeStatus') {
      status = status ? 1 : 0;
    }
    let statusObj: any = {};
    statusObj.param = param;
    statusObj.id = id;
    statusObj.status = status;
    this.reqRes.postData('/Global/ChangeStatus', statusObj).subscribe(data => {
      if (param == 'tradePartner') {
        let index = this.tradePartnerData.findIndex(item => item.id == id);
        this.tradePartnerData.splice(index, 1);
        toastr.success('Done!', '', { timeOut: 1000 });
      }
    }, error => {
      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  modalDelete(delId, delType, delType2, bulkArray) {
    this.delId = delId;
    this.delType = delType;
    this.delType2 = delType2;
    this.delBulkArray = bulkArray;
    if (bulkArray.length == 0) {
      this.delCount = 1;
    } else {
      this.delCount = this._pluginservice.checkedList.length;
    }
    $('#modalDelete').modal('show');
  }

  deleteItem(id, type, type2, array) {
    $("div[id^=threeNo] button").prop('disabled', false);
    $('#modalDelete').modal('hide');
    if (array.length == 0) {
      this.changeStatus(type, id, -1, type2);
    } else {
      this._pluginservice.bulkActions(array[0]);
      let $this = this;
      this._pluginservice.checkedList.forEach(function (val2, key2) {
        $this.tradePartnerData.forEach(function (val, key) {
          if (val.id == val2.id) {
            $this.tradePartnerData.splice(key, 1);
          }
        })
      })
    }
    this._pluginservice.masterSelectedRefresh('tradePartnerBulk');
  }

  modalDeleteClose() {
    $('#modalDelete').modal('hide');
  }
  tradePartnerObj: any = {};
  tradePartnerObjTemp: any = {};
  modalTradePartnerHeader = '';
  editTradePartner(id) {
    this.reqRes.getData('/People/GetTradePartnerDetailData/' + id).subscribe(data => {
      this.tradePartnerObj = data;
      //Clone Trade Partner Modal Information to Temp Obj //
      const cloneOfObject = JSON.parse(JSON.stringify(this.tradePartnerObj));
      this.tradePartnerObjTemp = cloneOfObject;
      /////////////////////////////////////////////
      if (id == 0) {
        this.modalTradePartnerHeader = 'Partner information';
      }
      else {
        this.modalTradePartnerHeader = 'Edit Partner information';
      }
      $('#modalTradePartner').modal('show');
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      })
  }
  saveTradePartner() {
    $('#btnSaveTradePartner').attr("disabled", "disabled");
    this.reqRes.postData('/People/TradePartnerOperation', this.tradePartnerObj).subscribe(data => {
      setTimeout(function () {
        $('#btnSaveTradePartner').removeAttr("disabled");
      }, 1000)
      toastr.success('Done!', '', { timeOut: 1000 });
      $('#modalTradePartner').modal('hide');
      if (this.tradePartnerObj.id == 0) {
        this.tradePartnerData.unshift(data);
      }
      else {
        let index = this.tradePartnerData.findIndex(item => item.id == this.tradePartnerObj.id);
        this.tradePartnerData[index] = data;
      }
    }, error => {
      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  modalTradePartnerClose() {
    let result = this.pluginservice.compareObjects(this.tradePartnerObj, this.tradePartnerObjTemp, ["id"]);
    if (result) {
      if (confirm('Close without saving??')) {
        $("#modalTradePartner").modal('hide');
      }
    }
    else {
      $('#modalTradePartner').modal('hide');
    }
  }
}

