import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-data-access',
  templateUrl: './error-data-access.component.html',
  styleUrls: ['./error-data-access.component.css']
})
export class ErrorDataAccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
