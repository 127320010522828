import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReqResService } from '../../service/req-res.service';
declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  id: number = 0;
  hash: string = "";
  warningMessage: string = "";
  invoiceDetailData: any = {};
  invoiceAmounts: any = {}
  constructor(private router: Router, private route: ActivatedRoute, private reqRes: ReqResService,) {
    this.route.paramMap.subscribe(paramMap => {
      this.id = Number(paramMap.get('id'));
      this.hash = paramMap.get('key');
    });
  }
  ngOnInit(): void {
    this.getClientInvoiceData(this.id, this.hash)
  }

  readInvoice() {
    let obj: any = {};
    obj.id = this.id
    obj.hash = this.hash;
    this.reqRes.postData('/Client/InvoiceRead', obj).subscribe(data => {
    }, error => { console.log(error); toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 }); })
  }


  getClientInvoiceData(id, hash) {
    this.reqRes.getData('/Client/GetInvoiceData/' + id + "/" + hash).subscribe(
      result => {
        this.readInvoice();
        this.invoiceDetailData = result;
        if (this.invoiceDetailData.specification) {
          this.getInvoiceCalc();
        }
        if (this.invoiceDetailData.length == 0) {
          this.warningMessage = "Opps Something Went Wrong !!!";
        }
      },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      }
    )
  }
  calculatePrices() {
    this.invoiceDetailData.total = this.invoiceAmounts.total;
    this.invoiceDetailData.subtotal = this.invoiceAmounts.subtotal;
    this.invoiceDetailData.taxAmount = this.invoiceAmounts.taxAmount;
    this.invoiceDetailData.paymentApplied = this.invoiceAmounts.paymentApplied;
    this.invoiceDetailData.paymentAppliedInvoice = this.invoiceAmounts.paymentAppliedInvoice;
    this.invoiceDetailData.balanceDue = this.invoiceAmounts.balanceDue;
    this.invoiceDetailData.balanceDueInvoice = this.invoiceAmounts.balanceDueInvoice;
  }
  calculateDetailPrices() {
    this.invoiceDetailData.specification.forEach((Vspec, Kspec) => {
      this.invoiceAmounts.specification.forEach((VspecAmount, KspecAmount) => {
        if (Vspec.id == VspecAmount.id) {
          Vspec.price = VspecAmount.price;
          Vspec.costLine.forEach((VcostLine, KcostLine) => {
            VspecAmount.costLine.forEach((VcostLineAmount, KcostLineAmount) => {
              if (VcostLine.id == VcostLineAmount.id) {
                VcostLine.price = VcostLineAmount.price;
              }
            });
          });
        }
      });
    });
  }
  getInvoiceCalc() {
    this.reqRes.getData('/Client/GetInvoiceDetailCalculationData/' + this.id + "/" + this.hash).subscribe(data => {
      this.invoiceAmounts = data;
      this.calculatePrices();
      this.calculateDetailPrices();
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      })
  }


  print() {
    window.print();
  }
}
