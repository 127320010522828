<div class="row">
  <div class="col-12">
    <div class="card mt-3">
      <div class="card-header">
        <div class="row align-items-center justify-content-between">
          <div class="col-6 col-sm-auto d-flex align-items-center pr-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Users</h5>
          </div>
          <div class="col-6 col-sm-auto ml-auto text-right pl-0">
            <div id="divSearchFilter" class="d-none position-relative mr-2" (click)="searchInputOperation()">
              <input type="search" id="inputSearchFilter" class="form-control form-control-sm" [(ngModel)]="searchUser"
                     (focusout)="closeFilter()" (keyup)="getSearchData(searchUser)" (input)="getSearchData(searchUser)"
                     placeholder="search..." autocomplete="off" appTrimLeft />
            </div>
            <button class="btn rounded mr-2 border btn-sm search-filter-btn" id="btnSearchFilter" data-toggle="tooltip" data-placement="top" title="Search" (click)="openFilter()">
              <span>
                <i class="fas fa-search"></i>
              </span>
            </button>
            <button class="btn btn-sm btn-danger" (click)="modalDelete(0,'','',['userStatus'])" [hidden]="_pluginservice.checkedList?.length==0">
              <span class="fas fa-trash-alt d-inline-block d-sm-none"
                    data-fa-transform="shrink-3 down-2"></span>
              <span class="d-none d-sm-inline-block">
                Delete
              </span>
            </button>
            <button class="btn btn-success btn-sm" [hidden]="_pluginservice.checkedList?.length!=0"
                    data-toggle="modal" type="button" (click)="editUser(0)">
              <span class="fas fa-plus d-inline-block d-sm-none"
                    data-fa-transform="shrink-3 down-2"></span>
              <span class="d-none d-sm-inline-block">
                Create new
              </span>
            </button>
          </div>
        </div>
      </div>
      <div [style.opacity]="loading ? '0' : '1'">
        <div class="card-body p-0 card-scroll" (scroll)="onScrollData($event)">
          <div class="falcon-data-table">
            <div class="falcon-data-table table-responsive">
              <table class="table table-sm mb-0 table-striped border-bottom">
                <thead class="bg-200 text-900 sticky-header">
                  <tr>
                    <th class="pr-1 align-middle w-30px">
                      <div class="custom-control custom-checkbox">
                        <input class="custom-control-input checkbox-bulk-select" id="userBulk" type="checkbox"
                               [(ngModel)]="_pluginservice.masterSelected"
                               (change)="_pluginservice.checkAll(userData,'userBulk')"
                               [disabled]="userData?.length==0"
                               [attr.checked]="_pluginservice.checkedList.length==userData?.length ? 'checked' : null"
                               [attr.indeterminate]="(_pluginservice.checkedList.length>0) && _pluginservice.checkedList.length!=userData?.length ? 'indeterminate' : false"
                               [indeterminate]="(_pluginservice.checkedList.length>0) && _pluginservice.checkedList.length!=userData?.length ? 'indeterminate' : false">
                        <label class="custom-control-label" for="userBulk"></label>
                      </div>
                    </th>
                    <th class="pr-1 align-middle cursor-pointer" (click)="sortType = 'name'; sortReverse = !sortReverse;sortData('name');">
                      Name
                      <span *ngIf="sortType == 'name' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'name' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 align-middle cursor-pointer" (click)="sortType = 'role'; sortReverse = !sortReverse;sortData('role');">
                      Role
                      <span *ngIf="sortType == 'role' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'role' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 align-middle cursor-pointer" (click)="sortType = 'email'; sortReverse = !sortReverse;sortData('email');">
                      Email
                      <span *ngIf="sortType == 'email' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'email' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 align-middle cursor-pointer" (click)="sortType = 'status'; sortReverse = !sortReverse;sortData('status');">
                      Status
                      <span *ngIf="sortType == 'status' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'status' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 align-middle">
                    </th>
                  </tr>
                </thead>
                <tbody id="userTbody">
                  <tr class="btn-reveal-trigger" *ngFor="let u of userData">
                    <td class="align-middle">
                      <div class="custom-control custom-checkbox">
                        <input class="custom-control-input checkbox-bulk-select-target" type="checkbox" id="u{{u.id}}"
                               [(ngModel)]="u.checkboxOpe"
                               (change)="_pluginservice.isAllSelected(userData);getCheckedId(u.id)" />
                        <label class="custom-control-label" for="u{{u.id}}"></label>
                      </div>
                    </td>
                    <th class="align-middle">
                      <a href="javascript:void(0)" (click)="editUser(u.id)">
                        {{u.name}}
                      </a>
                    </th>
                    <td class="align-middle">
                      {{u.role}}
                    </td>
                    <td class="align-middle">
                      {{u.email}}
                    </td>
                    <td class="align-middle">
                      <div class="custom-control custom-switch">
                        <input class="custom-control-input" type="checkbox" id="switch{{u.id}}" [(ngModel)]="u.status" (change)="changeStatus('user', u.id, u.status,'changeStatus')" />
                        <label class="custom-control-label cursor-pointer" for="switch{{u.id}}"></label>
                      </div>
                    </td>
                    <td class="align-middle white-space-nowrap">
                      <div class="dropdown text-sans-serif" id="threeNo{{u.id}}">
                        <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal mr-3"
                                type="button" id="dropdown0"
                                data-toggle="dropdown"
                                data-boundary="html"
                                aria-haspopup="true"
                                aria-expanded="false">
                          <span class="fas fa-ellipsis-h fs--1"></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right border py-0"
                             aria-labelledby="dropdown0">
                          <div class="bg-white py-2">
                            <a class="dropdown-item cursor-pointer" href="javascript:void(0)" (click)="editUser(u.id)">Edit</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item text-danger" href="javascript:void(0)" (click)="modalDelete(u.id,'user','deleteUser',[])">Delete</a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody class="text-center w-100 py-3 bg-light" *ngIf="(userData | filter: searchUser).length === 0">
                  <tr>
                    <td colspan="8" class="px-2 py-3 bg-light text-center w-100">
                      <p class="text-center bold-text font-size18px m-0">No data available</p>
                      <p class="text-center m-0 text-gray font-size13px">There is nothing to show you right now</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ngx-loading [show]="loading" class="z-index1000"></ngx-loading>
    </div>
    </div>
</div>
<!--Modal User-->
<div class="modal fade px-0" style="padding-left:0!important;padding-right:0!important" id="userModal" tabindex="-1" role="dialog" aria-labelledby="userModal" data-backdrop="static" data-keyboard="false"
     aria-hidden="true" (keydown.esc)="modalUserClose()">
  <div class="modal-dialog activity-select-modal-width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <span *ngIf="modalType!=0 || userLimit!=userData.length">{{modalTitle}}</span>
          <span *ngIf="modalType==0 &&  userLimit==userData.length">Confirmation</span>
        </h5>
        <button class="close" type="button" aria-label="Close" (click)="modalUserClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-padding">
        <div class="row">
          <div class="col-12 text-right text-danger bold-text" *ngIf="modalType==0 && userLimit-userData.length<=5 && userLimit!=userData.length">
            You can add last {{userLimit-userData.length}} user<span *ngIf="userLimit-userData.length!=1">s</span>
          </div>
          <div class="col-12 text-right text-danger bold-text" *ngIf="modalType==0 && userLimit==userData.length">Sorry, Your user limit is full</div>
        </div>
        <div class="row" *ngIf="modalType!=0 || userLimit!=userData.length">
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="name">First name <span class="text-danger">*</span></label>
              <input class="form-control form-control-sm" id="name" type="text" [(ngModel)]="userObj.name" required appTrimLeft autocomplete="off" />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="lastName">Last name <span class="text-danger">*</span></label>
              <input class="form-control  form-control-sm" id="lastName" type="text" [(ngModel)]="userObj.lastName" required appTrimLeft autocomplete="off" />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="emailUser">Email <span class="text-danger">*</span></label>
              <input class="form-control  form-control-sm" id="emailUser" type="email" [(ngModel)]="userObj.email" email
                    appTrimLeft  pattern="^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$" #emailUser="ngModel" required autocomplete="off" autofocus (keyup)="resetchkOpe()" />
              <div *ngIf="emailUser.invalid && (emailUser.dirty || emailUser.touched)" class="validation" >
                Please input valid e-mail
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="userType">Role <span class="text-danger">*</span></label>
              <select class="form-control  form-control-sm" id="userType" name="userType" [(ngModel)]="userObj.userType" required>
                <option [ngValue]="0">Choose</option>
                <option [ngValue]="ur.id" *ngFor="let ur of userObj.userRoles">{{ur.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-12 text-left mt-2" *ngIf="modalType!=0">
            <span class="form-check">
              <input type="checkbox" class="form-check-input cursor-pointer" id="letChangePassword"
                     [(ngModel)]="changePasswordOpe" (change)="chagenPasswordPermission()">
              <label class="form-check-label label-completed" for="letChangePassword">Change Password</label>
            </span>
          </div>
          <div class="col-md-6 col-12" *ngIf="modalType==0 || changePasswordOpe">
            <div class="form-group">
              <label for="password">Password <span class="text-danger">*</span></label>
              <input class="form-control  form-control-sm" id="password" type="password" [(ngModel)]="userObj.password" required autocomplete="off"/>
            </div>
          </div>
          <div class="col-md-6 col-12"  *ngIf="modalType==0 || changePasswordOpe">
            <div class="form-group">
              <label for="confirtmationPassword">Re-type Password <span class="text-danger">*</span></label>
              <input class="form-control  form-control-sm" id="confirtmationPassword" type="password" minlength="5" [(ngModel)]="userObj.confirmationPassword" required autocomplete="off"/>
            </div>
          </div>
          <div class="col-12">
            <div *ngIf="userObj.password && userObj.password?.length<6" class="validation text-right">
              Password is required 6 characters minimum
            </div>
            <div *ngIf="userObj.password && userObj.confirmationPassword && userObj.password?.length>=6 && userObj.password!=userObj.confirmationPassword" class="validation text-right">
              Password and Confirmation Password must be match
            </div>
            <div *ngIf="chkOpe==1" class="validation bold-text text-right">
              This e-mail address is currently in use
            </div>
          </div>
          <div class="dis-button-group position-absolute" *ngIf="modalType!=0 || userLimit!=userData.length">
            <button class="btn btn-falcon-default btn-sm mr-2" type="button" (click)="modalUserClose()">Close</button>
            <button class="btn btn-primary btn-sm" id="btnUser" type="button"
                    [disabled]="!userObj.name || !userObj.lastName || userObj.userType==0 || (!userObj.email || (emailUser.invalid && (emailUser.dirty || emailUser.touched))) || disableButton==1 || (changePasswordOpe && (userObj.password?.length<6 || !userObj.password || !userObj.confirmationPassword || userObj.password!=userObj.confirmationPassword))" (click)="checkUserEmail()">
              Save
            </button>
          </div>
        </div>
      </div>
      <div class="modal-footer" *ngIf="modalType!=0 || userLimit!=userData.length">
        <button class="btn btn-primary btn-sm invisible">Save</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal Delete-->
<div class="modal fade z-index10001 pr-0" style="padding-right:0!important" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     data-backdrop="static" data-keyboard="false" aria-hidden="true" (keydown.esc)="modalDeleteClose()">
  <div class="modal-dialog modal-dialog-center modal-delete-width" role="document">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom: none">
        <div class="modal-delete-header-icondiv">
          <i class="far fa-times-circle text-center modal-delete-circle-icon"></i>
        </div>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close"
                (click)="modalDeleteClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-center fs-20 fw-600">Are you sure?</p>
        <p class="text-muted fs-13 pl-3 pr-3 text-center">Do you really want to delete {{delCount==1 ? 'this record' : 'these records'}}? This process can not be undone.</p>
      </div>
      <div class="modal-delete-footer">
        <button class="btn btn-sm mr-2 modal-delete-footer-cancel" type="button" data-dismiss="modal" (click)="modalDeleteClose()">Cancel</button>
        <button class="btn btn-danger btn-sm" type="button" (click)="deleteItem(delId,delType,delType2,delBulkArray)">Delete</button>
      </div>
    </div>
  </div>
</div>
