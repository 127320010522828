<html>
<head>
  <style>
    @media print {
      @page {
        size: A4 portrait;
        margin: 2cm;
      }

      ::-webkit-scrollbar {
        display: none;
      }

      body, html {
        width: 100%;
        height: 100%;
        -webkit-print-color-adjust: exact;
      }

      #printDiv {
        overflow-y: scroll;
        width: 100%;
        min-height: 100vh;
        height: auto;
        z-index: 100000; /* CSS doesn't support infinity */
        /* Any other Print Properties */
      }
    }
  </style>
</head>
<body>

  <div id="printDiv" class="col-md-12 bg-white p-3 p-lg-6 overflow-y-scroll res-preview-height-left  order-sm-1 order-2 text-dark">

    <div>
      <div class="d-flex justify-content-between">


        <div>
          <h5>Инвойс №{{invoiceObj.code}}</h5>
          <span class="mb-0 fs-13">{{invoiceObj.statusName}}</span>
          <div class="mb-0 fs-13">Распечатано: {{invoiceObj.currentDate}}</div>
        </div>

        <div>
          <div>
            <div style="max-width:180px">
              <div class="mb-0 fs-13">Дата: {{invoiceObj.date_}}</div>
            </div>
            <div class="mt-1" style="max-width: 180px">
              <div class="mb-0  fs-13">Время: {{invoiceObj.time}}</div>
            </div>
            <div class="mt-1" style="max-width: 300px">
              <div class="d-flex justify-content-between align-items-center">
                <div for="groupp" class="mb-0 fs-13">	Покупатель: {{invoiceObj.customerName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-12">
        <table id="tblMain" class="w-100 mt-2 fs-13">
          <thead>
            <tr>
              <th class="border-1px-solid px-2 min-w-300px">Товар</th>
              <th class="border-1px-solid px-2">Кол-во</th>
              <th class="border-1px-solid px-2">Цена</th>
              <th class="border-1px-solid px-2" *ngIf="invoiceObj.discount>0">Спец. цена</th>
              <th class="border-1px-solid px-2">Сумма</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let d of invoiceObj.detail; let ind_=index;" class="tr-hover">
              <td class="border-1px-solid px-2">
                {{d.group}} - {{d.item}}
              </td>
              <td class="border-1px-solid px-2">{{d.qty}}</td>
              <td class="border-1px-solid px-2"><span [ngClass]="{'line':invoiceObj.discount>0}">{{d.rate | number : '1.2-2'}}</span></td>
              <td class="border-1px-solid px-2" *ngIf="invoiceObj.discount>0">{{d.specRate | number : '1.2-2'}}</td>
              <td class="border-1px-solid px-2">{{d.total | number : '1.2-2'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div class="row mt-2">
      <div class="col-md-7"></div>
      <div class="col-md-5">
        <table class="table table-sm table-borderless mt-3 fs-14">
          <tr>
            <th class="font-weight-bold text-dark fs-16">Итого</th>
            <td class="font-weight-bold text-right text-dark fs-16"> {{invoiceObj.total | number : '1.2-2'}}<span *ngIf="invoiceObj.total"> руб.</span></td>
          </tr>
          <tr>
            <th class="text-dark fs-13 fw-400">Скидка </th>
            <td class="text-dark text-right fs-13 fw-400" style="max-width:120px" *ngIf="invoiceObj.discount>0">{{invoiceObj.discount}} %</td>
          </tr>
          <tr>
            <th class="text-dark fs-13 fw-400">Оплачено</th>
            <td class="text-dark text-right fs-13 fw-400"> {{invoiceObj.payed | number : '1.2-2'}}<span *ngIf="invoiceObj.total"> руб.</span></td>
          </tr>
          <tr>
            <th class="text-dark fs-13 fw-400">Задолженность</th>
            <td class="text-dark text-right fs-13 fw-400"> {{invoiceObj.balance | number : '1.2-2'}}<span *ngIf="invoiceObj.total"> руб.</span></td>
          </tr>
        </table>
      </div>
    </div>

    <div class="row mt-2" *ngIf="invoiceObj.comment!=''">
      <div class="col-sm-12 mt-2">
        <label class="fs-13">Комментарий: </label>
        <div class="mt-1 fs-13">{{invoiceObj.comment}}</div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-12 mt-3 fs-13 text-center">
        <div>
          <b>Магазин Бижутерии "Фаворит"</b> 
        </div>
        <div>г. Хасавюрт, Центральный рынок, Кожанный ряд.</div>
        <div>
          <span>WhatsApp 89289696070</span> |
          <span>Камиль 89288076070</span> |
          <span>Заур 89604207020</span>
        </div>
      </div>
    </div>


  </div>


  
</body>
</html>
