import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../service/auth/auth.service';

declare var toastr: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loading = false;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  errorMessage: string;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userinfo');
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get loginFormControl() { return this.loginForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    // this.loading = true;
    const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
    this.authService.login(this.loginForm.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.islogin == 1) {
            let _isNotAuthencatedUrl = this.authService.isNotAuthencatedUrl.replace("/", "");
            if (_isNotAuthencatedUrl != "login" && _isNotAuthencatedUrl != "error403") {
              this.router.navigate([this.authService.isNotAuthencatedUrl]);
            } else {
              this.router.navigate([returnUrl]);
            }
          }
          else {
            this.errorMessage = data.error;
            this.loginForm.setErrors({
              invalidLogin: true
            });
          }
        },
        err => {
          toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
          console.log(err)
        },
        () => {
        });
  }
}
