<main class="main" id="top">
  <div class="container" data-layout="container">
    <div class="row flex-center min-vh-100 py-6">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
          <div class="card-body p-4 p-sm-5">
            <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <h1 class="h3 mb-3 font-weight-normal text-center">Login</h1>
              <label for="inputEmail" class="sr-only  mt-1">Email address</label>
              <input id="inputEmail" class="form-control" type="email" placeholder="Email" formControlName="email" tabindex="1"
                     [ngClass]="{ 'is-invalid': submitted && loginFormControl.email.errors }" autocomplete="new-password" />
              <div *ngIf="submitted && loginFormControl.email.errors" class="invalid-feedback">
                <div *ngIf="loginFormControl.email.errors.required">Email is required</div>
              </div>
              <label for="inputPassword" class="sr-only  mt-1">Password</label>
              <input class="form-control mt-2" id="inputPassword" type="password" placeholder="Password" formControlName="password" tabindex="2"
                     [ngClass]="{ 'is-invalid': submitted && loginFormControl.password.errors }" autocomplete="new-password" />
              <div *ngIf="submitted && loginFormControl.password.errors" class="invalid-feedback">
                <div *ngIf="loginFormControl.password.errors.required">Password is required</div>
              </div>
              <button class="btn btn-lg btn-primary btn-block mt-2 fs-16" >Sign in</button>
            </form>
          </div>
        <div *ngIf="loginForm.errors?.invalidLogin" class="alert alert-danger mt-3 mb-0">
          {{errorMessage}}
        </div>
      </div>
    </div>
  </div>
</main>
