<div class="row">
  <div class="col-12">
    <div class="card mt-3">
      <div class="card-header">
        <div class="row align-items-center justify-content-between">
          <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Specodes</h5>
          </div>
          <div class="col-8 col-sm-auto ml-auto text-right pl-0">
            <div id="divSearchFilter" class="d-none position-relative mr-2" (click)="searchInputOperation()">
              <input type="search" id="inputSearchFilter" class="form-control form-control-sm" [(ngModel)]="searchSpecode"
                     (focusout)="closeFilter()" (keyup)="getSearchData(searchSpecode)" (input)="getSearchData(searchSpecode)"
                     placeholder="search..." autocomplete="off" appTrimLeft />
            </div>
            <button class="btn rounded mr-2 border btn-sm search-filter-btn" id="btnSearchFilter" data-toggle="tooltip" data-placement="top" title="Search" (click)="openFilter()">
              <span>
                <i class="fas fa-search"></i>
              </span>
            </button>
            <button class="btn btn-sm btn-danger" [hidden]="_pluginservice.checkedList?.length==0"
                    data-toggle="modal" type="button" (click)="modalDelete(0,'','',['specodeStatus'])">
              <span class="fas fa-trash-alt d-inline-block d-sm-none"
                    data-fa-transform="shrink-3 down-2"></span>
              <span class="d-none d-sm-inline-block">
                Delete
              </span>
            </button>
            <button class="btn btn-success rounded btn-sm" type="button" [hidden]="_pluginservice.checkedList?.length!=0" (click)="editSpecode(0)">
              <span class="fas fa-plus d-inline-block d-sm-none"
                    data-fa-transform="shrink-3 down-2"></span>
              <span class="d-none d-sm-inline-block">
                Create new
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body bg-light p-0">
        <div [style.opacity]="loading ? '0' : '1'">
          <div class="card-body p-0 card-scroll" (scroll)="onScrollData($event)">
            <div class="falcon-data-table">
              <div class="falcon-data-table table-responsive">
                <table class="table table-sm mb-0 table-striped border-bottom">
                  <thead class="bg-200 text-900 sticky-header">
                    <tr>
                      <th class="pr-1 align-middle w-30px">
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input checkbox-bulk-select"
                                 id="specodeBulk" type="checkbox"
                                 [(ngModel)]="_pluginservice.masterSelected"
                                 (change)="_pluginservice.checkAll(specode,'specodeBulk')"
                                 [disabled]="specode?.length==0"
                                 [attr.checked]="_pluginservice.checkedList.length==specode?.length ? 'checked' : null"
                                 [attr.indeterminate]="(_pluginservice.checkedList.length>0) && _pluginservice.checkedList.length!=specode?.length ? 'indeterminate' : false"
                                 [indeterminate]="(_pluginservice.checkedList.length>0) && _pluginservice.checkedList.length!=specode?.length ? 'indeterminate' : false" />
                          <label class="custom-control-label" for="specodeBulk"></label>
                        </div>
                      </th>
                      <th class="pr-1 align-middle cursor-pointer text-left" (click)="sortType = 'name'; sortReverse = !sortReverse;sortData('name');">
                        Name
                        <span *ngIf="sortType == 'name' && sortReverse">
                          <i class="fas fa-sort-up"></i>
                        </span>
                        <span *ngIf="sortType == 'name' && !sortReverse">
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </th>
                      <th class="pr-1 align-middle cursor-pointer text-left" (click)="sortType = 'type'; sortReverse = !sortReverse;sortData('type');">
                        Type
                        <span *ngIf="sortType == 'type' && sortReverse">
                          <i class="fas fa-sort-up"></i>
                        </span>
                        <span *ngIf="sortType == 'type' && !sortReverse">
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </th>
                      <th class="pr-1 align-middle cursor-pointer text-left min-w-400px" (click)="sortType = 'desc'; sortReverse = !sortReverse;sortData('desc');">
                        Description
                        <span *ngIf="sortType == 'desc' && sortReverse">
                          <i class="fas fa-sort-up"></i>
                        </span>
                        <span *ngIf="sortType == 'desc' && !sortReverse">
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </th>
                      <th class="pr-1 align-middle cursor-pointer text-left min-w-30px" (click)="sortType = 'order'; sortReverse = !sortReverse;sortData('order');">
                        Order
                        <span *ngIf="sortType == 'order' && sortReverse">
                          <i class="fas fa-sort-up"></i>
                        </span>
                        <span *ngIf="sortType == 'order' && !sortReverse">
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </th>
                      <th class="pr-1 align-middle cursor-pointer text-left min-w-30px" (click)="sortType = 'color'; sortReverse = !sortReverse;sortData('color');">
                        Color
                        <span *ngIf="sortType == 'color' && sortReverse">
                          <i class="fas fa-sort-up"></i>
                        </span>
                        <span *ngIf="sortType == 'color' && !sortReverse">
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </th>
                      <th class="pr-1 align-middle cursor-pointer text-left min-w-30px" (click)="sortType = 'css'; sortReverse = !sortReverse;sortData('css');">
                        CSS
                        <span *ngIf="sortType == 'css' && sortReverse">
                          <i class="fas fa-sort-up"></i>
                        </span>
                        <span *ngIf="sortType == 'css' && !sortReverse">
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </th>
                      <th class="pr-1 align-middle cursor-pointer text-left min-w-30px" (click)="sortType = 'icon'; sortReverse = !sortReverse;sortData('icon');">
                        Icon
                        <span *ngIf="sortType == 'icon' && sortReverse">
                          <i class="fas fa-sort-up"></i>
                        </span>
                        <span *ngIf="sortType == 'icon' && !sortReverse">
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </th>
                      <th class="pr-1 align-middle cursor-pointer text-left" (click)="sortType = 'status'; sortReverse = !sortReverse;sortData('status');">
                        Status
                        <span *ngIf="sortType == 'status' && sortReverse">
                          <i class="fas fa-sort-up"></i>
                        </span>
                        <span *ngIf="sortType == 'status' && !sortReverse">
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </th>
                      <th class="pr-1 align-middle">
                      </th>
                    </tr>
                  </thead>
                  <tbody id="specodeBody">
                    <tr class="btn-reveal-trigger" *ngFor="let ld of specode">
                      <td class="align-middle">
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input checkbox-bulk-select-target" type="checkbox" id="ld{{ld.id}}"
                                 [(ngModel)]="ld.checkboxOpe"
                                 (change)="_pluginservice.isAllSelected(specode);getCheckedId(ld.id)" />
                          <label class="custom-control-label" for="ld{{ld.id}}"></label>
                        </div>
                      </td>
                      <th class="align-middle text-left">
                        <a href="javascript:void(0)" (click)="editSpecode(ld.id)">
                          {{ld.name}}
                        </a>
                      </th>
                      <td class="align-middle text-left">
                          {{ld.type}}
                      </td>
                      <td class="align-middle text-left">
                        {{ld.desc}}
                      </td>
                      <td class="align-middle text-left">
                        {{ld.order}}
                      </td>
                      <td class="align-middle text-left">
                        {{ld.color}}
                      </td>
                      <td class="align-middle text-left">
                        {{ld.css}}
                      </td>
                      <td class="align-middle text-left">
                        {{ld.icon}}
                      </td>
                      <td class="align-middle text-left">
                        <div class="custom-control custom-switch">
                          <input class="custom-control-input" type="checkbox" id="specodeSwitch{{ld.id}}" [(ngModel)]="ld.status" (change)="changeStatus('specode', ld.id, ld.status,'changeStatus')" />
                          <label class="custom-control-label cursor-pointer" for="specodeSwitch{{ld.id}}"></label>
                        </div>
                      </td>
                      <td class="align-middle white-space-nowrap">
                        <div class="dropdown text-sans-serif"  id="threeNo{{ld.id}}">
                          <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal mr-3"
                                  type="button" id="dropdown0"
                                  data-toggle="dropdown"
                                  data-boundary="html"
                                  aria-haspopup="true"
                                  aria-expanded="false">
                            <span class="fas fa-ellipsis-h fs--1"></span>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right border py-0"
                               aria-labelledby="dropdown0">
                            <div class="bg-white py-2">
                              <a class="dropdown-item cursor-pointer" (click)="editSpecode(ld.id)">Edit</a>
                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item text-danger" href="javascript:void(0)" (click)="modalDelete(ld.id,'specode','delete',[])">Delete</a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="text-center w-100 py-3 bg-light" *ngIf="(specode | filter: searchSpecode)?.length === 0">
                    <tr>
                      <td colspan="4" class="px-2 py-3 bg-light text-center w-100">
                        <p class="text-center bold-text font-size18px m-0">No data available</p>
                        <p class="text-center m-0 text-gray font-size13px">There is nothing to show you right now</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <ngx-loading [show]="loading"></ngx-loading>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Specode-->
<div class="modal fade" id="specodeModal" tabindex="-1" role="dialog" aria-labelledby="specodeModal" data-backdrop="static" data-keyboard="false"
     aria-hidden="true" (keydown.esc)="modalSpecodeClose()">
  <div class="modal-dialog activity-select-modal-width" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title">{{modalTitle}}</h5>
        <button class="close" type="button" aria-label="Close" (click)="modalSpecodeClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-padding" name="specodeModalModal" id="specodeModalBody">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="name">Name <span class="text-danger">*</span></label>
              <input class="form-control form-control-sm" id="name" type="text" title="name" name="title" [(ngModel)]="specodeObj.name" required="required" appTrimLeft autocomplete="off" tabindex="1" />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="type">Type <span class="text-danger">*</span></label>
              <select [(ngModel)]="specodeObj.type" id="type" title="type" class="form-control form-control-sm" required (ngModelChange)="changeType()" tabindex="2">
                <option value="">Choose</option>
                <option *ngFor="let t of specodeObj.typeList" value="{{t.id}}">{{t.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-12" *ngIf="specodeObj.specodeTitle?.length>0">
            <p class="mt-1 fs-14">Description: {{specodeObj.specodeTitle}}</p>
          </div>
          <div class="col-sm-6 col-12">
            <div class="form-group">
              <label for="color">Color</label>
              <input class="form-control form-control-sm" id="color" type="text" title="color" name="color" [(ngModel)]="specodeObj.color" appTrimLeft autocomplete="off" tabindex="3" />
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="form-group">
              <label for="css">Css </label>
              <input class="form-control form-control-sm" id="css" type="text" title="css" name="css" [(ngModel)]="specodeObj.css" appTrimLeft autocomplete="off" tabindex="4" />
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="form-group">
              <label for="icon">Icon</label>
              <input class="form-control form-control-sm" id="icon" type="text" title="icon" name="icon" [(ngModel)]="specodeObj.icon" appTrimLeft autocomplete="off" tabindex="5" />
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="form-group">
              <label for="order">Order</label>
              <input class="form-control form-control-sm" id="order" appNumbersOnly appSelectedInput title="order" name="order" [(ngModel)]="specodeObj.order" tabindex="6">
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="desc">Description</label>
              <textarea class="form-control form-control-sm" id="desc" rows="3" [(ngModel)]="specodeObj.desc" appTrimLeft autocomplete="off" maxLength="250" tabindex="7"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-falcon-default btn-sm" type="button" (click)="modalSpecodeClose()">Close</button>
        <button class="btn btn-primary btn-sm" id="btnspecode" type="button" [disabled]="!specodeObj.name || !specodeObj.type" (click)="saveSpecode()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal Delete-->
<div class="modal fade z-index10001 pr-0" style="padding-right:0!important" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     data-backdrop="static" data-keyboard="false" aria-hidden="true" (keydown.esc)="modalDeleteClose()">
  <div class="modal-dialog modal-dialog-center modal-delete-width" role="document">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom: none">
        <div class="modal-delete-header-icondiv">
          <i class="far fa-times-circle text-center modal-delete-circle-icon"></i>
        </div>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close"
                (click)="modalDeleteClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-center fs-20 fw-600">Are you sure?</p>
        <p class="text-muted fs-13 pl-3 pr-3 text-center">Do you really want to delete {{delCount==1 ? 'this record' : 'these records'}}? This process can not be undone.</p>
      </div>
      <div class="modal-delete-footer">
        <button class="btn btn-sm mr-2 modal-delete-footer-cancel" type="button" data-dismiss="modal" (click)="modalDeleteClose()">Cancel</button>
        <button class="btn btn-danger btn-sm" type="button" (click)="deleteItem(delId,delType,delType2,delBulkArray)">Delete</button>
      </div>
    </div>
  </div>
</div>
