import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReqResService } from '../../service/req-res.service';
import { Title } from '@angular/platform-browser';
import { PluginService } from '../../service/plugin.service';
import { debounce } from 'lodash';

declare var $: any;
declare var toastr: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  loading = false;
  invoiceData: any[];
  sortReverse: boolean;
  sortType: string;
  searchInvoice: string;
  invoiceObj: any = {};


  //del variables
  delId: number;
  delType: string;
  delCount: number;
  delBulkArray: any = [];
  delType2: string;

  _selectfirsttime: boolean = false;
  //del variables
  modalTitle: string = '';
  itemCount: number = 0;
  sortdirection: string = 'A';
  maxCount: number = 0;
  searchText: string = "";
  public _pluginservice;

  constructor(private titleService: Title, private route: ActivatedRoute, private router: Router, private reqRes: ReqResService, private pluginservice: PluginService) {
    this._pluginservice = this.pluginservice;
    this.titleService.setTitle('Invoice');
    this.qtyChange = debounce(this.qtyChange, 1000)
  }
  itemLists: any = [];


  ngOnInit(): void {
    let $this = this;


     




    $('#invoiceCustomer').select2({
      minimumInputLength: 2,
      placeholder: 'Выбирать',
      ajax: {
        delay: 250,
        url: '/Invoice/GetCustomerList',
        dataType: 'json',
        quietMillis: 50,
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('authToken') },
        type: "GET",
        data: function (term) {
          return {
            searchText: term.term
          };
        },
        processResults: function (data) {
          return {
            results: $.map(data, function (item) {
              return {
                text: item.name,
                id: item.id,
              }
            })
          };
        },
      },
      escapeMarkup: function (m) {
        return m;
      },
    }).on("change", function () {
      $this.invoiceChangeOperation('customer', parseInt($(this).val()), $this.invoiceObj.id);
    });






    $('.modal').on('shown.bs.modal', function () {
      $(this).find('[autofocus]').focus();
    });



    



    $("#datetime").change(function () {
      $this.invoiceObj.time = $("#datetime").val();
      $this.invoiceChangeOperation('date', $this.invoiceObj.date + ' ' + $this.invoiceObj.time, $this.invoiceObj.id)
    });

    //$('#datetime').datetimepicker({
    //  format: 'h:mm'
    //});


    //$('#costLineNameT').select2({
    //  minimumInputLength: 2,
    //  placeholder: 'Пишите для поиска',
    //  ajax: {
    //    delay: 250,
    //    url: '/Invoice/GetStockItemList',
    //    dataType: 'json',
    //    quietMillis: 50,
    //    headers: { "Authorization": 'Bearer ' + localStorage.getItem('authToken') },
    //    type: "GET",
    //    data: function (term) {
    //      return {
    //        searchText: term.term
    //      };
    //    },
    //    processResults: function (data) {
    //      return {
    //        results: $.map(data, function (item) {
    //          return {
    //            text: item.name,
    //            id: item.id,
    //          }
    //        })
    //      };
    //    },
    //  },
    //  escapeMarkup: function (m) {
    //    return m;
    //  },
    //}).on("change", function () {

    //  $this.reqRes.getData('/Invoice/GetStockItemDetail/' + parseInt($(this).val())).subscribe(data => {
    //    $this.addItemObj.invoice = $this.invoiceObj.id;
    //    $this.addItemObj.qty = 1;
    //    $this.addItemObj.stockItemQty = data.qty;
    //    $this.addItemObj.item = parseInt($(this).val());
    //    $this.addItemObj.price = data.price;
    //    $this.addItemObj.description = "";
    //    console.log($this.addItemObj)
    //  })
    //});



   






    this.sortReverse = true;
    this.sortType = 'createdate';
    let json = {
      "Search": '',
      "Count": this.itemCount,
      "Direction": 'A',
      "SortType": 'createdate'
    };

    this.getInvoiceData(JSON.stringify(json), 0);
    this._pluginservice.masterSelectedRefresh('invoiceBulk', []);
    this._pluginservice.checkedList = [];
  }

  checkInvoiceId: number = 0;
  getCheckedId(id) {
    this.checkInvoiceId = id;
  }



  sortData(type) {
    this.sortType = type;
    this.itemCount = 0;
    this.sortdirection = (this.sortReverse) ? 'D' : 'A';
    this.searchInvoice = (this.searchInvoice) ? this.searchInvoice : '';
    let json = {
      "Search": this.searchInvoice,
      "Count": this.itemCount,
      "Direction": this.sortdirection,
      "SortType": this.sortType
    };
    this.getInvoiceData(JSON.stringify(json), 0, 1);
  }

  getSearchData(search) {
    this.searchInvoice = (search) ? search : '';
    this.itemCount = 0;
    let json = {
      "Search": this.searchInvoice,
      "Count": this.itemCount,
      "Direction": this.sortdirection,
      "SortType": this.sortType
    };
    $("div[id^=threeNo] button").prop('disabled', false);
    this.getInvoiceData(JSON.stringify(json), 0)
  }


  onScrollData(event: any) {
    this.searchInvoice = (this.searchInvoice) ? this.searchInvoice : '';
    if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight && this.itemCount <= this.maxCount) {
      this.itemCount = this.itemCount + 25;
      let json = {
        "Search": this.searchInvoice,
        "Count": this.itemCount,
        "Direction": this.sortdirection,
        "SortType": this.sortType
      };
      this.getInvoiceData(JSON.stringify(json), 1);
    }
  }

  invoiceReturnData() {
    this.spinner = false;
    this.reqRes.getData('/Global/GetReturnData/' + this.invoiceObj.id + '/INVOICE').subscribe(data => {
      if (this.invoiceNewOpe) {
        this.invoiceData.unshift(data);
      }
      else {
        let index = this.invoiceData.findIndex(item => item.id == this.invoiceObj.id);
        this.invoiceData[index] = data;
      }
    
      $('#modal_invoice').modal('hide');
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }

  


  getInvoiceData(json, ope, event = 0) {
    let gridParametrs: any = {};
    gridParametrs.type = 'invoice';
    gridParametrs.json = json;
    this.loading = true;
    this.reqRes.postData('/Invoice/GetInvoiceList', gridParametrs).subscribe(data => {
      this.loading = false;
      this.invoiceData = (ope == 0) ? this.invoiceData = data.invoiceData : this.invoiceData.concat(data.invoiceData);
      this.maxCount = data.cnt;
      if (event == 0) {
        this.invoiceData = this.invoiceData.sort((a, b) => a.name.localeCompare(b.name));
        this.invoiceData = this.invoiceData.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      }
      if (this._pluginservice.checkedList.length == 1) {
        setTimeout(() => {
          $("#threeNo" + this.checkInvoiceId + " button").prop('disabled', false);
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      } else if (this._pluginservice.checkedList.length > 1) {
        setTimeout(() => {
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      }
      this._pluginservice.masterSelectedRefresh('invoiceBulk');

    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }

  spinner: boolean = false;
  invoiceNewOpe: boolean = false;
  invoiceOperation(id) {
    if (id == 0) {
      this.invoiceNewOpe = true;
    }
    else {
      this.invoiceNewOpe = false;
    }
    this.spinner = false;
    this.invoiceObj.id = id;
    this.reqRes.getData('/Invoice/GetInvoiceDetail/' + id).subscribe(data => {
      console.log(data)
      this.invoiceObj = data;
      var $newOption = $("<option selected='selected'></option>").val(this.invoiceObj.customerId).text(this.invoiceObj.customerName)
      setTimeout(function () { $("#invoiceCustomer").append($newOption); }, 100)
      $('#modal_invoice').modal('show');
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }

  qtyChange(val, index, type, value, id) {
    if (this.invoiceObj.detail[index].qty > val) {
      this.invoiceObj.detail[index].qty = val
    }
    let _obj: any = {};
    _obj.id = id;
    _obj.type = type;
    _obj.value = value.toString();
    this.reqRes.postData('/Invoice/InvoiceOperations', _obj).subscribe(data => {
      if (type == 'discount' || type == 'qty') {
        this.invoiceOperation(this.invoiceObj.id)
      }
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }



 

  






  invoiceChangeOperation(type,value,id) {
    let _obj: any = {};
    _obj.id = id;
    _obj.type = type;
    _obj.value = value.toString();
    this.reqRes.postData('/Invoice/InvoiceOperations', _obj).subscribe(data => {
      if (type == 'discount' || type == 'qty') {
        this.invoiceOperation(this.invoiceObj.id)
      }
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }


  newCustomerObj: any = {};
  addCustomer() {
    this.spinner = false;
    this.newCustomerObj.name = "";
    this.newCustomerObj.email = "";
    this.newCustomerObj.phone = "";
    $('#modal_invoice').modal('hide');
      $('#modalCustomer').modal('show');
  }
  saveCustomer() {
    let $this = this;
    this.spinner = true;
    $('#btnSaveCustomer').attr("disabled", "disabled");
    this.reqRes.postData('/Invoice/CustomerOperation', this.newCustomerObj).subscribe(data => {
      setTimeout(function () {
        $('#btnSaveCustomer').removeAttr("disabled");
      }, 1000)
      toastr.success('Done!', '', { timeOut: 1000 });
      $('#modal_invoice').modal('show');
      $('#modalCustomer').modal('hide');
      $this.invoiceObj.customerId = data;
      var $newOption = $("<option selected='selected'></option>").val(data).text($this.newCustomerObj.name)
      setTimeout(function () { $("#invoiceCustomer").append($newOption); }, 100)
      $('#invoiceCustomer').val(data);
      $this.invoiceChangeOperation('customer', parseInt(data), $this.invoiceObj.id);
      this.spinner = false;
    }, error => {
      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  modalCustomerClose() {
    $('#modal_invoice').modal('show');
    $('#modalCustomer').modal('hide');
  }


  modalDelete(delId, delType, delType2) {
    this.spinner = false;
    this._pluginservice.snipperFromBulk = false;
    this.delId = delId;
    this.delType = delType;
    this.delType2 = delType2;
    this.delCount = 1;
    $('#modalDelete').modal('show');
  }
  modalDeleteClose() {
    $('#modalDelete').modal('hide');
  }
  deleteItem(id, type, type2) {
    $("div[id^=threeNo] button").prop('disabled', false);
    $('#modalDelete').modal('hide');
    this.changeStatus(type, id, -1);
  }

  changeStatus(param, id, status): void {
    this.spinner = true;
    let statusObj: any = {};
    statusObj.param = param;
    statusObj.id = id;
    statusObj.status = status;
    this.reqRes.postData('/Global/ChangeStatus', statusObj).subscribe(data => {
      if (param == 'invoiceStatus' && status == -1) {
        let index = this.invoiceData.findIndex(item => item.id == id);
        this.invoiceData.splice(index, 1);
        $('#modal_invoice').modal('hide');
        toastr.success('Done!', '', { timeOut: 1000 });
      }
      else if (param == 'invoiceDetailStatus') {
        this.invoiceOperation(this.invoiceObj.id);
      }
      this.spinner = false;
    }, error => {
      toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  addItemObj: any = {};

  modalAddDetailClose() {
    $("#detailModal").modal('hide');
  }

  addDetail() {
    this.addItemObj.invoice = this.invoiceObj.id;
    this.addItemObj.item = 0;
    setTimeout(function () { $("#costLineNameT").text(0); }, 100);
    this.addItemObj.description = "";
    this.addItemObj.qty = 1;
    this.addItemObj.stockItemQty = 0;
    this.addItemObj.price = 0;
    $('#detailModal').modal('show');
  }

  changeItemObj(id) {
    this.reqRes.getData('/Invoice/GetStockItemDetail/' + id).subscribe(data => {
         this.addItemObj.invoice = this.invoiceObj.id;
         this.addItemObj.qty = 1;
         this.addItemObj.stockItemQty = data.qty;
         this.addItemObj.item = id;
         this.addItemObj.price = data.price;
      this.addItemObj.description = "";
      this.addItemObj.search = "";
      this.saveDetailPopup();
    }) 
  }


  itemListPopup: any = [];
  searchItems(str) {
    this.reqRes.getData('/Invoice/GetStockItemList/' + str).subscribe(data => {
      this.itemLists = data;
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }

  barcodeMessage: string = "";
  searchEnterItems(str) {
    this.barcodeMessage = "";
    this.reqRes.getData('/Invoice/GetStockItemList/' + str).subscribe(data => {
      if (data?.length == 0) {
        this.addItemObj.search = "";
        this.barcodeMessage = "Нет такого товара!";
      }
      else {
        this.changeItemObj(data[0].id);
      }
      setTimeout(() => { this.itemLists = [];},100)
      
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }


  clickItem(id) {
    this.changeItemObj(id);
    this.addItemObj.search = "";
    this.itemLists = [];
  }


  saveDetailPopup() {
    this.spinner = true;
    let _obj = this.addItemObj
    this.reqRes.postData('/Invoice/InvoiceDetailOperation', _obj).subscribe(data => {
      this.spinner = false;
      this.addDetail();
      this.invoiceOperation(this.invoiceObj.id)
    }, error => {
      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  handleChange(val) {
    if (this.addItemObj.qty > val) {
      this.addItemObj.qty = val
    }
  }
  printInvoice() {
    window.open('/prints/invoice/' + this.invoiceObj.id, '_blank');
  }




  paymentObj: any = {}
  savePayment() {
    this.spinner = true;
    this.reqRes.postData('/Invoice/InvoicePaymentOperation', this.paymentObj ).subscribe(data => {
      this.spinner = false;
      $("#modal_payment").modal('hide');
      this.invoiceOperation(this.invoiceObj.id)
    }, error => {
      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });

      console.log(error)
    })
  }

  changeKupyura() {
    if (this.paymentObj.kupyura > 0) {
      this.paymentObj.sdachi = parseFloat(this.paymentObj.kupyura) - parseFloat(this.paymentObj.price)
    }
  }
  paymentApply() {
   
      this.spinner = false;
    this.reqRes.getData('/Global/GetModalData/' + this.invoiceObj.id + '/payment').subscribe(data => {
      this.paymentObj = data;
        $('#modal_payment').modal('show');
      },
        error => {
          toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
          console.log(error);
        })


  }
}
