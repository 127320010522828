import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './login/login/login.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpInterceptorService } from './service/auth/http-interceptor.service';
import { ErrorInterceptorService } from './service/auth/error-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TrimLeftDirective } from './directives/trim-left.directive';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ClipboardModule } from 'ngx-clipboard';
import { PercentLimitDirective } from './directives/percent-limit.directive';
import { SortablejsModule } from 'ngx-sortablejs';
import { UsersComponent } from './pages/users/users.component';
import { RolesComponent } from './pages/roles/roles.component';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { MaxLengthDirective } from './directives/max-length.directive';
import { WebleadComponent } from './client/weblead/weblead.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CKEditorModule } from 'ng2-ckeditor';
import { AceEditorModule } from 'ng2-ace-editor';
import { CustomersComponent } from './pages/customers/customers.component';
import { Error403Component } from './login/error403/error403.component';
import { Error404Component } from './login/error404/error404.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { ClearInjectionSymbolDirective } from './directives/clear-injection-symbol.directive';
import { SelectedInputDirective } from './directives/selected-input.directive';
import { NgxFilesizeModule } from 'ngx-filesize';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AmountConverterPipe } from './pipes/amount-converter.pipe';
import { TradepartnersComponent } from './pages/tradepartners/tradepartners.component';
import { ErrorDataAccessComponent } from './login/error-data-access/error-data-access.component';
import { SpecodesComponent } from './pages/specodes/specodes.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelect2Module } from 'ng-select2';
import { AgmCoreModule } from '@agm/core';
import { } from 'googlemaps';
/** Import Alyle UI */
import { LyTheme2, StyleRenderer, LY_THEME, LY_THEME_NAME, LyHammerGestureConfig } from '@alyle/ui';
import { LyButtonModule } from '@alyle/ui/button';
import { LyToolbarModule } from '@alyle/ui/toolbar';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider'
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { AutofocusDirective } from './directives/autofocus.directive';
import { MinnumberoneDirective } from './directives/minnumberone.directive';
import { InvoiceComponent } from './client/invoice/invoice.component';
import { InvoiceprintComponent } from './prints/invoiceprint/invoiceprint.component';
import { FilterPipe } from './pipes/filter.pipe';


import { InvoiceworkorderComponent } from './prints/invoiceworkorder/invoiceworkorder.component';
import { BarcodeprintComponent } from './prints/barcodeprint/barcodeprint.component';
import { ItemsComponent } from './pages/Items/items.component';
import { WarehousemanagementComponent } from './pages/warehousemanagement/warehousemanagement.component';


/**/

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AppLayoutComponent,
    AccountSettingsComponent,
    TrimLeftDirective,
    PercentLimitDirective,
    UsersComponent,
    RolesComponent,
    MaxLengthDirective,
    WebleadComponent,
    CustomersComponent,
    Error403Component,
    Error404Component,
    NumbersOnlyDirective,
    ClearInjectionSymbolDirective,
    SelectedInputDirective,
    ConfirmationDialogComponent,
    AmountConverterPipe,
    TradepartnersComponent,
    ErrorDataAccessComponent,
    SpecodesComponent,
    MinnumberoneDirective,
    InvoiceComponent,
    InvoiceprintComponent,
    FilterPipe,
    InvoiceworkorderComponent,
    BarcodeprintComponent,
    ItemsComponent,
    WarehousemanagementComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ImageCropperModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    OrderModule,
    CKEditorModule,
    SignaturePadModule,
    NgSelectModule,
    NgSelect2Module,
    AgmCoreModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCwQK7J77w8ych1OR4m7kIKdPF0m-dqZgY',
      libraries: ['places']
    }),
    NgxMaskModule.forRoot(),
    NgxLoadingModule.forRoot({
      backdropBackgroundColour: '#ffffff',
      backdropBorderRadius: '4px',
      primaryColour: '#A6ACAF',
      secondaryColour: '#A6ACAF',
      tertiaryColour: '#A6ACAF'
    }),
    ClipboardModule,
    SortablejsModule.forRoot({
      animation: 200,
    }),
    PdfViewerModule,
    NgxFilesizeModule,
    DragDropModule,
    AceEditorModule,
    LyToolbarModule,
    LyButtonModule,
    LyImageCropperModule,
    LySliderModule,
    HammerModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
  //{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcAi9oZAAAAALAwnLC8gfPp1RE4SVOcF5KYORsk' },
  [LyTheme2],
  [StyleRenderer],
  { provide: LY_THEME_NAME, useValue: 'minima-light' },
  { provide: LY_THEME, useClass: MinimaLight, multi: true }, 
  { provide: LY_THEME, useClass: MinimaDark, multi: true }, 
  { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
