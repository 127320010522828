import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PluginService } from '../../service/plugin.service';
import { ReqResService } from '../../service/req-res.service';
declare var $: any;
declare var window: any;
declare var toastr: any;

@Component({
  selector: 'app-invoiceworkorder',
  templateUrl: './invoiceworkorder.component.html',
  styleUrls: ['./invoiceworkorder.component.css']
})
export class InvoiceworkorderComponent implements OnInit {
  id: number = 0;
  invoiceDetailData: any = {};
  invoiceAmounts: any = {}
  constructor(private titleService: Title, private router: Router, private route: ActivatedRoute, private reqRes: ReqResService, private pluginservice: PluginService) {
    this.route.paramMap.subscribe(paramMap => {
      this.id = Number(paramMap.get('id'));
      this.getInvoiceDetailData(this.id);
    });
  }
  ngOnInit(): void { }
  getInvoiceDetailData(id) {
    this.reqRes.getData('/Project/GetInvoiceDetailData/' + id).subscribe(data => {
      this.invoiceDetailData = data;
      this.titleService.setTitle(this.invoiceDetailData.invoiceName);
      this.getInvoiceCalc();
      setTimeout(function () {
        window.print();
      }, 1500)
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error);
      })
  }
  calculatePrices() {
    this.invoiceDetailData.total = this.invoiceAmounts.total;
    this.invoiceDetailData.subtotal = this.invoiceAmounts.subtotal;
    this.invoiceDetailData.taxAmount = this.invoiceAmounts.taxAmount;
    this.invoiceDetailData.paymentApplied = this.invoiceAmounts.paymentApplied;
    this.invoiceDetailData.balanceDue = this.invoiceAmounts.balanceDue;
  }
  calculateDetailPrices() {
    this.invoiceDetailData.specification.forEach((Vspec, Kspec) => {
      this.invoiceAmounts.specification.forEach((VspecAmount, KspecAmount) => {
        if (Vspec.id == VspecAmount.id) {
          Vspec.price = VspecAmount.price;
          Vspec.costLine.forEach((VcostLine, KcostLine) => {
            VspecAmount.costLine.forEach((VcostLineAmount, KcostLineAmount) => {
              if (VcostLine.id == VcostLineAmount.id) {
                VcostLine.price = VcostLineAmount.price;
              }
            });
          });
        }
      });
    });
  }
  getInvoiceCalc() {
    this.reqRes.getData('/Project/GetInvoiceDetailCalculationData/' + this.id).subscribe(data => {
      this.invoiceAmounts = data;
      this.calculatePrices();
      this.calculateDetailPrices();
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      })
  }
}
