import { Component, OnInit } from '@angular/core';
import { ReqResService } from '../../service/req-res.service';
import { PluginService } from '../../service/plugin.service';
declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-warehousemanagement',
  templateUrl: './warehousemanagement.component.html',
  styleUrls: ['./warehousemanagement.component.css']
})
export class WarehousemanagementComponent implements OnInit {

  loading = false;
  warehouseData: any[];
  wareHouseListData: any[];
  sortReverse: boolean;
  sortType: string;
  searchWarehouse: string;
  warehouseManagementObj: any = {};
  warehouseManagementObjTemp: any;

  //del variables
  delId: number;
  delType: string;
  delCount: number;
  delBulkArray: any = [];
  delType2: string;

  _selectfirsttime: boolean = false;
  //del variables
  modalTitle: string = '';
  warehouseCount: number = 0;
  sortdirection: string = 'A';
  maxCount: number = 0;
  searchText: string = "";
  wareHouseList: number = 0;
  public _pluginservice;



  constructor(private reqRes: ReqResService, private pluginservice: PluginService) {
    this._pluginservice = this.pluginservice;
  }

  ngOnInit(): void {
    this.sortReverse = true;
    this.wareHouseList = 0;
    this.sortType = 'name';
    let json = {
      "Search": '',
      "Count": this.warehouseCount,
      "Direction": 'A',
      "WareHouse": '0',
      "SortType": 'name'
    };

    var $this = this
    $('#item').select2();
    $('#item').on("change", function () {
      $this.warehouseManagementObj.itemId = parseInt($(this).val());
      
    });



    $('#warehouse').select2();
    $('#warehouse').on("change", function () {
      $this.warehouseManagementObj.warehouse = parseInt($(this).val());
      $this.changeWareHouse();
    });


    $('#section').select2();
    $('#section').on("change", function () {
      $this.warehouseManagementObj.section = parseInt($(this).val());
      $this.changeSection();
    });


    $('#cell').select2();
    $('#cell').on("change", function () {
      $this.warehouseManagementObj.cell = parseInt($(this).val());
    });







    this.getWarehoiseList();
    this.getWarehouseData(JSON.stringify(json), 0);
    this._pluginservice.masterSelectedRefresh('warehouseBulk', []);
    this._pluginservice.checkedList = [];
  }
  //Warehouse Group Data
  checkWarehouseId: number = 0;
  getCheckedId(id) {
    this.checkWarehouseId = id;
  }




  sortData(type) {
    this.sortType = type;
    this.warehouseCount = 0;
    this.sortdirection = (this.sortReverse) ? 'D' : 'A';
    this.searchWarehouse = (this.searchWarehouse) ? this.searchWarehouse : '';
    let json = {
      "Search": this.searchWarehouse,
      "Count": this.warehouseCount,
      "Direction": this.sortdirection,
      "SortType": this.sortType,
      "WareHouse": this.wareHouseList,
    };
    this.getWarehouseData(JSON.stringify(json), 0, 1);
  }

  getSearchData(search) {
    this.searchWarehouse = (search) ? search : '';
    this.warehouseCount = 0;
    let json = {
      "Search": this.searchWarehouse,
      "Count": this.warehouseCount,
      "Direction": this.sortdirection,
      "WareHouse": this.wareHouseList,
      "SortType": this.sortType
    };
    $("div[id^=threeNo] button").prop('disabled', false);
    this.getWarehouseData(JSON.stringify(json), 0)
  }
  onScrollData(event: any) {
    this.searchWarehouse = (this.searchWarehouse) ? this.searchWarehouse : '';
    if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight && this.warehouseCount <= this.maxCount) {
      this.warehouseCount = this.warehouseCount + 25;
      let json = {
        "Search": this.searchWarehouse,
        "Count": this.warehouseCount,
        "Direction": this.sortdirection,
        "SortType": this.sortType,
        "WareHouse": this.wareHouseList,
      };
      this.getWarehouseData(JSON.stringify(json), 1);
    }
  }


  getWarehoiseList() {
    this.reqRes.getData('/Global/GetGeneralArrays/0/WAREHOUSELIST').subscribe(data => {
      this.wareHouseListData = data;
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }

  getWarehouseData(json, ope, event = 0) {
    let gridParametrs: any = {};
    gridParametrs.type = 'warehouse';
    gridParametrs.json = json;
    this.loading = true;
    this.reqRes.postData('/Stock/StockList', gridParametrs).subscribe(data => {
      console.log(data)
      console.log(ope)
      console.log(data.warehouseData)
      this.loading = false;
      if (ope == 0) {
        this.warehouseData = data.warehouseData
      } else {
        this.warehouseData.concat(data.warehouseData)
      };
      this.maxCount = data.cnt;
      //if (event == 0) {
      //  this.warehouseData = this.warehouseData.sort((a, b) => a.name.localeCompare(b.name));
      //  this.warehouseData = this.warehouseData.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      //}
     
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }


  warehouseDuplicateOpe: boolean = false;
  param: number = 0;
  warehouseOperation(id, ope) {
    this.spinner = false;
    this.param = id;
    this.warehouseDuplicateOpe = ope;
    this.warehouseManagementObj.id = id;
    this.modalTitle = (id == 0) ? 'Принять товар ' : 'Удалить товар';
    this.reqRes.getData('/Global/GetModalData/' + id + '/warehouse').subscribe(data => {
      this.warehouseManagementObj = data;
      const cloneOfObject = JSON.parse(JSON.stringify(this.warehouseManagementObj));
      this.warehouseManagementObjTemp = cloneOfObject;
      $('#modal_warehouse').modal('show');
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }
  modalWarehouseClose() {
    let result = this.pluginservice.compareObjects(this.warehouseManagementObj, this.warehouseManagementObjTemp, ["id"])
    if (result) {
      if (confirm('Вы хотите закрыть без сохранения??')) {
        $('#modal_warehouse').modal('hide');
      }
    }
    else {
      $('#modal_warehouse').modal('hide');
    }
  }
  spinner: boolean = false;

  modalItemClose() {
    let result = this.pluginservice.compareObjects(this.warehouseManagementObj, this.warehouseManagementObjTemp, ["itemId"])
    if (result) {
      if (confirm('Вы хотите закрыть без сохранения??')) {
        $('#modal_warehouse').modal('hide');
      }
    }
    else {
      $('#modal_warehouse').modal('hide');
    }
  }

  saveWarehouse() {
    this.spinner = true;
    this.warehouseManagementObj.itemId = parseInt(this.warehouseManagementObj.itemId);
    this.warehouseManagementObj.warehouse = parseInt(this.warehouseManagementObj.warehouse);
    this.warehouseManagementObj.section = parseInt(this.warehouseManagementObj.section);
    this.warehouseManagementObj.cell = parseInt(this.warehouseManagementObj.cell);
    this.warehouseManagementObj.param = this.param;
    $('#btWarehouseSave').attr('disabled', 'disabled');
    this.reqRes.postData('/Stock/StockOperation', this.warehouseManagementObj).subscribe(data => {
      setTimeout(function () {
        $('#btWarehouseSave').removeAttr('disabled');
      }, 1000)


      this.sortReverse = true;
      this.wareHouseList = 0;
      this.sortType = 'name';
      let json = {
        "Search": '',
        "Count": this.warehouseCount,
        "Direction": 'A',
        "WareHouse": '0',
        "SortType": 'name'
      };

      this.getWarehouseData(JSON.stringify(json), 0);



      toastr.success('Done!', '', { timeOut: 1000 });
      this.spinner = false;
      $("#modal_warehouse").modal('hide');
    }, error => {
      toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
      console.log(error)
    })
  }


  changeWareHouse() {
    this.reqRes.getData('/Global/GetGeneralArrays/' + this.warehouseManagementObj.warehouse +'/SECTIONBYWAREHOUSE').subscribe(data => {
      this.warehouseManagementObj.sectionList = data;
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }
  changeSection() {
    this.reqRes.getData('/Global/GetGeneralArrays/' + this.warehouseManagementObj.section + '/CELLBYSECTION').subscribe(data => {
      this.warehouseManagementObj.cellList = data;
    },
      error => {
        toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
        console.log(error);
      })
  }







  modalDelete(delId, delType, delType2) {
    this.spinner = false;
    this._pluginservice.snipperFromBulk = false;
    this.delId = delId;
    this.delType = delType;
    this.delType2 = delType2;
    this.delCount = 1;
    $('#modalDelete').modal('show');
  }
  modalDeleteClose() {
    $('#modalDelete').modal('hide');
  }
  deleteWarehouse(id, type, type2) {
    $("div[id^=threeNo] button").prop('disabled', false);
    $('#modalDelete').modal('hide');
    this.changeStatus(type, id, -1);
  }

  changeStatus(param, id, status): void {
    this.spinner = true;
    let statusObj: any = {};
    statusObj.param = param;
    statusObj.id = id;
    statusObj.status = status;
    this.reqRes.postData('/Global/ChangeStatus', statusObj).subscribe(data => {
      if (param == 'warehouseStatus' && status == -1) {
        let index = this.warehouseData.findIndex(warehouse => warehouse.id == id);
        this.warehouseData.splice(index, 1);
        toastr.success('Done!', '', { timeOut: 1000 });
      }
      this.spinner = false;
    }, error => {
      toastr.error("​Oops! Что-то пошло не так.", '', { timeOut: 1000 });
      console.log(error)
    })
  }

}
