<div class="row">
  <div class="col-12 mb-4">
    <div class="card mt-3">
      <div class="card-header">
        <div class="row align-warehouses-center justify-content-between">
          <div class="col-3 d-flex align-warehouses-center pr-0">
            <h5 class="mb-0 text-nowrap py-2 py-xl-0">Склад</h5>
          </div>
          <div class="col-9 ml-auto text-right pl-0">
            <div class="dashboard-actions d-flex justify-content-end">


              <select class="form-control max-width-200px mr-2" [(ngModel)]="wareHouseList">
                <option [ngValue]="0">Все склады</option>
                <option *ngFor="let whl of wareHouseListData" [ngValue]="whl.id">{{whl.name}}</option>
              </select>


              <div id="divSearchFilter" class="d-inline-block position-relative mr-2">
                <input type="search" class="form-control" placeholder="Поиск..." [(ngModel)]="searchWarehouse" (change)="getSearchData(searchWarehouse)" (keyup)="getSearchData(searchWarehouse)">
              </div>
              <div  id="warehouseGroupButtons">
                <button class="btn btn-success mr-2" (click)="warehouseOperation(0,false)"
                        data-toggle="modal" type="button">
                  <span class="fas fa-plus d-inline-block d-sm-none"
                        data-fa-transform="shrink-3 down-2"></span>
                  <span class="d-none d-sm-inline-block">
                    Принять товар
                  </span>
                </button>


                <button class="btn btn-danger" (click)="warehouseOperation(-1,false)"
                        data-toggle="modal" type="button">
                  <span class="fas fa-plus d-inline-block d-sm-none"
                        data-fa-transform="shrink-3 down-2"></span>
                  <span class="d-none d-sm-inline-block">
                    Удалить товар
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div [style.opacity]="loading ? '0' : '1'">
          <div class="card-body p-0 card-scroll" (scroll)="onScrollData($event)">
            <div class="falcon-data-table">
              <table class="table table-sm">
                <thead class="thead-dark sticky-header">
                  <tr>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-300px" (click)="sortType = 'name';sortReverse = !sortReverse;sortData('name');">
                      Наименования
                      <span *ngIf="sortType == 'name' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'name' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-200px" (click)="sortType = 'warehouse';sortReverse = !sortReverse;sortData('warehouse');">
                      Склад
                      <span *ngIf="sortType == 'warehouse' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'warehouse' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-200px" (click)="sortType = 'section';sortReverse = !sortReverse;sortData('section');">
                      Ряд
                      <span *ngIf="sortType == 'section' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'section' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-100px" (click)="sortType = 'cell';sortReverse = !sortReverse;sortData('cell');">
                      Полка
                      <span *ngIf="sortType == 'cell' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'cell' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 py-2 align-middle cursor-pointer min-w-100px" (click)="sortType = 'qty';sortReverse = !sortReverse;sortData('qty');">
                      Количество
                      <span *ngIf="sortType == 'qty' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'qty' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <!--<th class="pr-1 py-2 align-middle min-w-60px">
                    </th>-->
                  </tr>
                </thead>
                <tbody>
                  <tr class="btn-reveal-trigger tr-hover" *ngFor="let wh of warehouseData">
                    <th class="align-middle">
                        {{wh.name}}
                      <br />
                      <span class="text-muted">
                        {{wh.group}}
                      </span>
                    </th>

                    <td class="align-middle">
                      {{wh.wareHouse}}
                    </td>
                    <td class="align-middle">
                      {{wh.section}}
                    </td>
                    <td class="align-middle">
                      {{wh.cell}}
                    </td>
                    <td class="align-middle">
                      {{wh.qty}}
                    </td>
                    <!--<td class="min-w-60px">
                      <div class="d-flex justify-content-between">
                        <span class="px-1 text-danger grid-icons cursor-pointer" (click)="modalDelete(i.id,'warehouseStatus','warehouseStatus')">
                          <i class="fas fa-trash" title="Удалить"></i>
                        </span>
                      </div>
                    </td>-->
                  </tr>
                </tbody>

                <tbody class="text-center w-100 py-3 bg-light" *ngIf="warehouseData?.length==0">
                  <tr>
                    <td colspan="8" class="px-2 py-3 bg-light text-center w-100">
                      <p class="text-center bold-text font-size18px m-0">Данные недоступны</p>
                      <p class="text-center m-0 text-gray font-size13px">Нам нечего показать прямо сейчас</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ngx-loading [show]="loading" class="z-index1000"></ngx-loading>
        </div>
      </div>
    </div>
  </div>
</div>






<!--Modal Add Item-->
<div class="modal fade px-0" id="modal_warehouse" tabindex="-1" costCatalogue="dialog" aria-labelledby="modal_warehouse" data-backdrop="static" data-keyboard="false"
     aria-hidden="true" (keydown.esc)="modalItemClose()">
  <div class="modal-dialog modal-width-500px" costCatalogue="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{modalTitle}}
        </h5>
        <button class="close" type="button" aria-label="Close" (click)="modalItemClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-scroll modal-padding" name="costCatalogueModal" id="costCatalogueModalBody">
        <div class="row">
          <div class="col-12">
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <label for="item" >
                  Товар <span class="text-danger">*</span>
                </label>
               <!-- <button class="btn btn-link btn-sm pr-0 pb-0 fs-13" type="button" tabindex="2" (click)="addGroup()">Добавить</button>-->
              </div>
              <select class="form-control select2" id="item" name="item" title="Товар" [(ngModel)]="warehouseManagementObj.itemId" tabindex="1">
                <option [ngValue]="0">Выберите</option>
                <option value="{{il.id}}" *ngFor="let il of warehouseManagementObj.itemList">{{il.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div >
              <div class="d-flex justify-content-between align-items-center">
                <label for="warehouse">
                  Склад <span class="text-danger">*</span>
                </label>
                <!--<button class="btn btn-link btn-sm pr-0 pb-0 fs-13" type="button" tabindex="2" (click)="addSubGroup()">Добавить</button>-->
              </div>
              <select class="form-control" id="warehouse" name="warehouse" title="Склад" [(ngModel)]="warehouseManagementObj.warehouse" tabindex="2">
                <option [ngValue]="0">Выберите</option>
                <option value="{{whl.id}}" *ngFor="let whl of warehouseManagementObj.warehouseList">{{whl.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <label for="section">
                  Ряд <span class="text-danger">*</span>
                </label>
                <!--<button class="btn btn-link btn-sm pr-0 pb-0 fs-13" type="button" tabindex="2" (click)="addSubGroup()">Добавить</button>-->
              </div>
              <select class="form-control" id="section" name="section" title="Ряд" [(ngModel)]="warehouseManagementObj.section" tabindex="3">
                <option [ngValue]="0">Выберите</option>
                <option value="{{sl.id}}" *ngFor="let sl of warehouseManagementObj.sectionList">{{sl.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <label for="cell">
                  Полка <span class="text-danger">*</span>
                </label>
                <!--<button class="btn btn-link btn-sm pr-0 pb-0 fs-13" type="button" tabindex="2" (click)="addSubGroup()">Добавить</button>-->
              </div>
              <select class="form-control" id="cell" name="cell" title="Полка" [(ngModel)]="warehouseManagementObj.cell" tabindex="4">
                <option [ngValue]="0">Выберите</option>
                <option value="{{whl.id}}" *ngFor="let whl of warehouseManagementObj.cellList">{{whl.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div>
              <label >Количество  <span class="text-danger">*</span></label>
                <input class="form-control text-right pl-25px" id="qty" name="qty" title="Количество" [(ngModel)]="warehouseManagementObj.qty" mask="separator" appSelectedInput thousandSeparator="," autocomplete="off" tabindex="5">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footer-fixed">
        <button class="btn btn-falcon-default" type="button" (click)="modalItemClose()">Отмена</button>
        <button class="btn btn-primary" id="btWarehouseSave" type="button" [disabled]="warehouseManagementObj.itemId==0 || warehouseManagementObj.warehouse==0 || warehouseManagementObj.section==0|| warehouseManagementObj.cell==0 || warehouseManagementObj.qty==0" (click)="saveWarehouse()">
          <div class="g_spinner" *ngIf="spinner"></div>
          Сохранить
        </button>
      </div>
    </div>
  </div>
</div>
<!---->
