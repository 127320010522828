  <div class="modal-header bg-warning">
    <h5 class="modal-title text-white title-calendar" id="examplemodallabel">{{title}}</h5>
    <button class="close text-white" type="button" data-dismiss="modal" (click)="decline()" (keydown.esc)="decline()">
      <span class="font-weight-light" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p> {{message}}</p>
    </div>
    <div class="overflow-search-scroll cross-body-margin">
      <div class="dashboard-data-table table-responsive">
        <table class="table table-sm table-dashboard fs--1 data-table mb-0">
          <tbody id="purchases">
            <tr class="btn-reveal-trigger cursor-pointer" *ngFor="let c of crossData">
              <td class="align-middle text-900">
                <a href="javascript:void(0)" (click)="LeadLocation(c.leadId)"> {{c.leadName}}</a>
                <p class="mb-0 text-500">{{c.cusName}}</p>
              </td>
              <td class="align-middle text-500" style="width:45%">
                {{c.projectType}} @ {{c.begDate}} <br /> {{c.duration}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-falcon-default btn-sm" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="accept()">{{ btnOkText }}</button>
  </div>
