import { Component, OnInit } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { ReqResService } from '../../service/req-res.service';
import { PluginService } from '../../service/plugin.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  loading = false;
  roleData: any = [];
  sortReverse: boolean;
  sortType: string;
  roleObj: any = {};
  roleObjTemp: any;
  disableButton: number = 0;
  modalType: number = 0;
  //del variables
  delId: number;
  delType: string;
  delCount: number;
  delBulkArray: any = [];
  delType2: string;
  modalTitle: string = '';
  roleCount: number = 0;
  searchRole: string = '';
  maxCount: number = 0;
  sortdirection: string = 'A';
  //public roleConfig: PaginationInstance = {
  //  id: 'users',
  //  itemsPerPage: 10,
  //  currentPage: 1
  //};
  public _pluginservice;
  constructor(private reqRes: ReqResService, private pluginservice: PluginService) {
    this._pluginservice = this.pluginservice;
  }
  ngOnInit(): void {
    this.sortReverse = true;
    this.sortType = 'createdate';

    let json = {
      "Search": '',
      "Count": this.roleCount,
      "Direction": 'A',
      "SortType": this.sortType
    };

    this.getRoleData(JSON.stringify(json), 0);
    this._pluginservice.masterSelectedRefresh('roleBulk', []);
    this._pluginservice.checkedList = [];
  }
  searchInputOperation() {
    $('.custom-search-input').focus();
    $("[data-toggle='tooltip']").tooltip('hide');
  }

  openFilter() {
    $("#divSearchFilter").removeClass("d-none")
    $("#divSearchFilter").addClass("d-inline-block")
    $("#divSearchFilter").animate({ right: '0px' }, "slow");
    $("#btnSearchFilter").hide();
    $("#inputSearchFilter").focus();
  }

  closeFilter() {
    $("#divSearchFilter").removeClass("d-inline-block")
    $("#divSearchFilter").addClass("d-none")
    $("#btnSearchFilter").show();
  }

  sortData(type) {
    this.sortType = type;
    this.roleCount = 0;
    this.sortdirection = (this.sortReverse) ? 'D' : 'A';
    this.searchRole = (this.searchRole) ? this.searchRole : '';
    let json = {
      "Search": this.searchRole,
      "Count": this.roleCount,
      "Direction": this.sortdirection,
      "SortType": this.sortType
    };
    this.getRoleData(JSON.stringify(json), 0);
  }

  getSearchData(search) {
    this.searchRole = (search) ? search : '';
    this.roleCount = 0;
    this.sortType = (this.searchRole) ? ' ' : 'createdate';
    let json = {
      "Search": this.searchRole,
      "Count": this.roleCount,
      "Direction": (this.searchRole) ? 'D' : 'A',
      "SortType": this.sortType
    };
    $("div[id^=threeNo] button").prop('disabled', false);
    this.getRoleData(JSON.stringify(json), 0)
  }

  getRoleData(json, ope) {
    this.loading = true;

    let GridParametrs: any = {};
    GridParametrs.type = 'role';
    GridParametrs.json = json;

    this.reqRes.postData('/Settings/GetSettingsViewData', GridParametrs).subscribe(data => {
      this.loading = false;
      this.roleData = (ope == 0) ? this.roleData = data.roleData : this.roleData.concat(data.roleData);
      this.maxCount = data.maxCount;
    //  this._pluginservice.masterSelectedRefresh('roleBulk');
      if (this._pluginservice.checkedList.length == 1) {
        setTimeout(() => {
          $("#threeNo" + this.checkItemId + " button").prop('disabled', false);
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      } else if (this._pluginservice.checkedList.length > 1) {
        setTimeout(() => {
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      }

    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }
  checkItemId: number = 0;
  getCheckedId(id) {
    this.checkItemId = id;
  }
  onScrollData(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight && this.roleCount <= this.maxCount) {
      this.roleCount = this.roleCount + 25;
      let json = {
        "Search": this.searchRole,
        "Count": this.roleCount,
        "Direction": this.sortdirection,
        "SortType": this.sortType
      };
      this.getRoleData(JSON.stringify(json), 1);


      //$("div[id^=threeNo] button").prop('disabled', false);
      //this._pluginservice.UnCheckAll(this.roleData);
    }
  }

  editRole(id) {
    this.modalType = id;
    this.roleObj.id = id
    this.modalTitle = (id == 0) ? 'New Role' : 'Edit Role';
    this.reqRes.getData('/Settings/GetSettingsEditData/' + id + '/role').subscribe(data => {
      this.roleObj = data;
      //Clone Role Modal Information to Temp Obj //
      const cloneOfObject = JSON.parse(JSON.stringify(this.roleObj));
      this.roleObjTemp = cloneOfObject;
      /////////////////////////////////////////////
      $("#roleModal").modal('show');
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error);
      })
  }
  modalRoleClose() {
    let result = this.pluginservice.compareObjects(this.roleObj, this.roleObjTemp, ["id"])
    if (result) {
      if (confirm('Close without saving??')) {
        $('#roleModal').modal('hide');
      }
    }
    else {
      $('#roleModal').modal('hide');
    }
  }
  saveRole() {
    $("#btnSaveRole").attr("disabled", "disabled");
    this._pluginservice.masterSelectedRefresh('roleBulk');
    this.disableButton = 1;
    this.reqRes.postData('/Settings/SaveRole', this.roleObj).subscribe(data => {
      setTimeout(function () {
        $("#btnSaveRole").removeAttr("disabled");
      }, 1000);
      this.disableButton = 0;
      let returnedObj = data;
      if (this.roleObj.id == 0) {
        this.roleData.unshift(data)
      }
      else {
        let index = this.roleData.findIndex(item => item.id == returnedObj.id);
        this.roleData[index].name = returnedObj.name;
        this.roleData[index].count = returnedObj.count;
      }
      toastr.success('Done!', '', { timeOut: 1000 });
      $("#roleModal").modal('hide');
      //this.getRoleData();
    }, error => {
      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  changeStatus(param, id, status, param2): void {
    if (param2 == 'changeStatus') {
      status = status ? 1 : 0;
    }
    let statusObj: any = {};
    statusObj.param = param;
    statusObj.id = id;
    statusObj.status = status;
    this.reqRes.postData('/Global/ChangeStatus', statusObj).subscribe(data => {
      if (param == 'role' && status == -1) {
        let index = this.roleData.findIndex(item => item.id == id);
        this.roleData.splice(index, 1);
        toastr.success('Done!', '', { timeOut: 1000 });
      }
      else if (param == 'role') {
        toastr.success('Done!', '', { timeOut: 1000 });
      }
    }, error => {
      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
      console.log(error)
    })
  }

  modalDelete(delId, delType, delType2, bulkArray) {
    this.delId = delId;
    this.delType = delType;
    this.delType2 = delType2;
    this.delBulkArray = bulkArray;
    if (bulkArray.length == 0) {
      this.delCount = 1;
    } else {
      this.delCount = this._pluginservice.checkedList.length;
    }
    $('#modalDelete').modal('show');
  }

  deleteItem(id, type, type2, array) {
    $("div[id^=threeNo] button").prop('disabled', false);
    $('#modalDelete').modal('hide');
    if (array.length == 0) {
      this.changeStatus(type, id, -1, type2);
    } else {
      let ids = this._pluginservice.bulkActions(array[0]);
      ids.forEach((id) => {
        let index = this.roleData.findIndex(item => item.id == id);
        this.roleData.splice(index, 1);
      });
    }
    this._pluginservice.masterSelectedRefresh('roleBulk');
  }

  modalDeleteClose() {
    $('#modalDelete').modal('hide');
  }

  changeMenuStatus(index, ope) {
    if (!ope) {
      this.roleObj.menus[index].subMenus.forEach(function (val, key) {
        val.status = false;
      });
    }
    else {
      this.roleObj.menus[index].subMenus.forEach(function (val, key) {
        val.status = true;
      });
    }
  }
}
