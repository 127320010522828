<div class="row">
  <div class="col-12">
    <div class="card mt-3">
      <div class="card-header">
        <div class="row align-items-center justify-content-between">
          <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Roles & Permissions</h5>
          </div>
          <div class="col-8 col-sm-auto ml-auto text-right pl-0">
            <div id="divSearchFilter" class="d-none position-relative mr-2" (click)="searchInputOperation()">
              <input type="search" id="inputSearchFilter" class="form-control form-control-sm" [(ngModel)]="searchRole"
                     (focusout)="closeFilter()" (keyup)="getSearchData(searchRole)" (input)="getSearchData(searchRole)"
                     placeholder="search..." autocomplete="off" appTrimLeft />
            </div>
            <button class="btn rounded mr-2 border btn-sm search-filter-btn" id="btnSearchFilter" data-toggle="tooltip" data-placement="top" title="Search" (click)="openFilter()">
              <span>
                <i class="fas fa-search"></i>
              </span>
            </button>
            <button class="btn btn-sm btn-danger" [hidden]="_pluginservice.checkedList?.length==0" (click)="modalDelete(0,'','',['roleStatus'])">
              <span class="fas fa-trash-alt d-inline-block d-sm-none"
                    data-fa-transform="shrink-3 down-2"></span>
              <span class="d-none d-sm-inline-block">
                Delete
              </span>
            </button>
            <button class="btn btn-success btn-sm" [hidden]="_pluginservice.checkedList?.length!=0"
                    data-toggle="modal" type="button" (click)="editRole(0)">
              <span class="fas fa-plus d-inline-block d-sm-none"
                    data-fa-transform="shrink-3 down-2"></span>
              <span class="d-none d-sm-inline-block">
                Create new
              </span>
            </button>
          </div>
        </div>
      </div>
      <div [style.opacity]="loading ? '0' : '1'">
        <div class="card-body p-0 card-scroll" (scroll)="onScrollData($event)">
          <div class="falcon-data-table">
            <div class="falcon-data-table table-responsive">
              <table class="table table-sm mb-0 table-striped border-bottom">
                <thead class="bg-200 text-900 sticky-header">
                  <tr>
                    <th class="pr-1 align-middle w-30px">
                      <div class="custom-control custom-checkbox">
                        <input class="custom-control-input checkbox-bulk-select" id="roleBulk" type="checkbox"
                               [(ngModel)]="_pluginservice.masterSelected"
                               (change)="_pluginservice.checkAll(roleData,'roleBulk')"
                               [disabled]="roleData?.length==0"
                               [attr.checked]="_pluginservice.checkedList.length==roleData?.length ? 'checked' : null"
                               [attr.indeterminate]="(_pluginservice.checkedList.length>0) && _pluginservice.checkedList.length!=roleData?.length ? 'indeterminate' : false"
                               [indeterminate]="(_pluginservice.checkedList.length>0) && _pluginservice.checkedList.length!=roleData?.length ? 'indeterminate' : false">
                        <label class="custom-control-label" for="roleBulk"></label>
                      </div>
                    </th>
                    <th class="pr-1 align-middle cursor-pointer  min-w-300px" (click)="sortType = 'name';sortReverse = !sortReverse;sortData('name');">
                      Role
                      <span *ngIf="sortType == 'name' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'name' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 align-middle cursor-pointer  min-w-300px" (click)="sortType = 'count'; sortReverse = !sortReverse;sortData('count');">
                      Users
                      <span *ngIf="sortType == 'count' && sortReverse">
                        <i class="fas fa-sort-up"></i>
                      </span>
                      <span *ngIf="sortType == 'count' && !sortReverse">
                        <i class="fas fa-sort-down"></i>
                      </span>
                    </th>
                    <th class="pr-1 align-middle">
                    </th>
                  </tr>
                </thead>
                <tbody id="roleTbody">
                  <tr class="btn-reveal-trigger" *ngFor="let r of roleData">
                    <td class="align-middle">
                      <div class="custom-control custom-checkbox">
                        <input class="custom-control-input checkbox-bulk-select-target" type="checkbox" id="r{{r.id}}"
                               [(ngModel)]="r.checkboxOpe"
                               (change)="_pluginservice.isAllSelected(roleData);getCheckedId(r.id)" />
                        <label class="custom-control-label" for="r{{r.id}}"></label>
                      </div>
                    </td>
                    <th class="align-middle">
                      <a href="javascript:void(0)" (click)="editRole(r.id)">
                        {{r.name}}
                      </a>
                    </th>
                    <td class="align-middle">
                      {{r.count}}
                    </td>
                    <td class="align-middle white-space-nowrap">
                      <div class="dropdown text-sans-serif" id="threeNo{{r.id}}">
                        <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal mr-3"
                                type="button" id="dropdown0"
                                data-toggle="dropdown"
                                data-boundary="html"
                                aria-haspopup="true"
                                aria-expanded="false">
                          <span class="fas fa-ellipsis-h fs--1"></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right border py-0"
                             aria-labelledby="dropdown0">
                          <div class="bg-white py-2">
                            <a class="dropdown-item cursor-pointer" href="javascript:void(0)" (click)="editRole(r.id)">Edit</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item text-danger" href="javascript:void(0)" (click)="modalDelete( r.id,'role','deleteRole',[])">Delete</a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody class="text-center w-100 py-3 bg-light" *ngIf="(roleData | filter: searchRole).length === 0">
                  <tr>
                    <td colspan="4" class="px-2 py-3 bg-light text-center w-100">
                      <p class="text-center bold-text font-size18px m-0">No data available</p>
                      <p class="text-center m-0 text-gray font-size13px">There is nothing to show you right now</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ngx-loading [show]="loading"></ngx-loading>
    </div>
  </div>
</div>
<!--Modal User-->
<div class="modal fade px-0" style="padding-left:0!important;padding-right:0!important" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="roleModal" data-backdrop="static" data-keyboard="false"
     aria-hidden="true" (keydown.esc)="modalRoleClose()">
  <div class="modal-dialog activity-select-modal-width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{modalTitle}}
        </h5>
        <button class="close" type="button" aria-label="Close" (click)="modalRoleClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-scroll modal-padding" name="userModal" id="userModalBody">
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label for="name">Name <span class="text-danger">*</span></label>
              <input class="form-control form-control-sm" id="name" type="text" [(ngModel)]="roleObj.name" required appTrimLeft autocomplete="off" tabindex="1" title="name" />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group form-check mt-4">
              <input class="form-check-input cursor-pointer" id="fullaccess" type="checkbox" [(ngModel)]="roleObj.fullAccess">
              <label class="form-check-label cursor-pointer position-relative top--2px" for="fullaccess">Sees all data</label>
            </div>
          </div>
            <div class="col-12 mt-3-4">
              <b>Permissions</b>
              <span class="text-muted d-block fs-12">Manage permissions for the role</span>
              <hr class="border-dashed border-bottom-0 mt-1 mb-3">
            </div>
            <div class="col-12">
              <div class="card-body pt-2 pb-0 px-0" *ngFor="let mn of roleObj.menus;let index = index">
                <div class="row">
                  <div class="col-sm-3 fw-600">
                    {{mn.caption}}
                  </div>
                  <div class="col-sm-7">
                  </div>
                  <div class="col-sm-2">
                    <div class="custom-control custom-switch mb-2">
                      <input class="custom-control-input" type="checkbox" id="menu{{mn.id}}" [(ngModel)]="mn.status" (change)="changeMenuStatus(index,mn.status)" />
                      <label class="custom-control-label cursor-pointer" for="menu{{mn.id}}">
                      </label>
                    </div>
                  </div>
                </div>
                <hr style="margin-top: unset;margin-bottom: 0.5rem" />
                <div class="row" *ngFor="let sbm of mn.subMenus;let index2 = index" [hidden]="!mn.status">
                  <div class="col-sm-10">
                    <span class="ml-3 fs-13">{{sbm.caption}}</span>
                  </div>
                  <div class="col-sm-2">
                    <div class="custom-control custom-switch mb-2">
                      <input class="custom-control-input" type="checkbox" id="subMenu{{sbm.id}}" [(ngModel)]="sbm.status" [disabled]="!mn.status" />
                      <label class="custom-control-label cursor-pointer" for="subMenu{{sbm.id}}">
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="modal-footer modal-footer-fixed">
        <button class="btn btn-falcon-default btn-sm" type="button" (click)="modalRoleClose()">Close</button>
        <button class="btn btn-primary btn-sm" id="btnSaveRole" type="button" [disabled]="!roleObj.name" (click)="saveRole()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal Delete-->
<div class="modal fade z-index10001 pr-0" style="padding-right:0!important" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     data-backdrop="static" data-keyboard="false" aria-hidden="true" (keydown.esc)="modalDeleteClose()">
  <div class="modal-dialog modal-dialog-center modal-delete-width" role="document">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom: none">
        <div class="modal-delete-header-icondiv">
          <i class="far fa-times-circle text-center modal-delete-circle-icon"></i>
        </div>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close"
                (click)="modalDeleteClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-center fs-20 fw-600">Are you sure?</p>
        <p class="text-muted fs-13 pl-3 pr-3 text-center">Do you really want to delete {{delCount==1 ? 'this record' : 'these records'}}? This process can not be undone.</p>
      </div>
      <div class="modal-delete-footer">
        <button class="btn btn-sm mr-2 modal-delete-footer-cancel" type="button" data-dismiss="modal" (click)="modalDeleteClose()">Cancel</button>
        <button class="btn btn-danger btn-sm" type="button" (click)="deleteItem(delId,delType,delType2,delBulkArray)">Delete</button>
      </div>
    </div>
  </div>
</div>

