import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReqResService } from '../../service/req-res.service';
import { PaginationInstance } from 'ngx-pagination';
import { PluginService } from '../../service/plugin.service';
import { AuthService } from '../../service/auth/auth.service'
import * as moment from 'moment';
import { OrderPipe } from 'ngx-order-pipe';
import { Title } from '@angular/platform-browser';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})

export class CustomersComponent implements OnInit {
  spinner: boolean = false;
  loading = false;
  tabid: number = 1;
  sortReverse: boolean;
  sortType: string;
  searchCustomer: string = '';
  customerData: any[];
  customersTemp: any;
  statusObj: any = {};
  //del variables
  delId: number;
  delType: string;
  delCount: number;
  delType2: string;
  delBulkArray: any = [];

  customerCount: number = 0;
  customerMaxCount: number = 0;
  sortdirection: string = 'A';
  public listviewconfig: PaginationInstance = {
    id: 'listview',
    itemsPerPage: 10,
    currentPage: 1
  };
  public _authService;
  public _pluginservice;
  constructor(private titleService: Title,private router: Router, private route: ActivatedRoute, private reqRes: ReqResService, private pluginservice: PluginService, private authService: AuthService, private orderPipe: OrderPipe) {
    this._authService = this.authService;
    this._pluginservice = this.pluginservice;
    this.titleService.setTitle('Customers');
  }
  ngOnInit(): void {
    this.sortReverse = true;
    this.sortType = 'createdate';

    let json = {
      "Search": '',
      "Count": this.customerCount,
      "Direction": 'A',
      "SortType": this.sortType
    };

    this.getCustomersData(JSON.stringify(json), 0);

    this._pluginservice.masterSelectedRefresh('customerBulk', []);
    this._pluginservice.checkedList = [];
  }
  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  searchInputOperation() {
    $('.custom-search-input').focus();
    $("[data-toggle='tooltip']").tooltip('hide');
  }

  sortData(type) {
    this.sortType = type;
    this.customerCount = 0;
    this.sortdirection = (this.sortReverse) ? 'D' : 'A';
    this.searchCustomer = (this.searchCustomer) ? this.searchCustomer : '';
    let json = {
      "Search": this.searchCustomer,
      "Count": this.customerCount,
      "Direction": this.sortdirection,
      "SortType": this.sortType
    };
    this.getCustomersData(JSON.stringify(json), 0);
  }
  openFilter() {
    $("#divSearchFilter").removeClass("d-none")
    $("#divSearchFilter").addClass("d-inline-block")
    $("#divSearchFilter").animate({ right: '0px' }, "slow");
    $("#btnSearchFilter").hide();
    $("#inputSearchFilter").focus();
  }
  closeFilter() {
    $("#divSearchFilter").removeClass("d-inline-block")
    $("#divSearchFilter").addClass("d-none")
    $("#btnSearchFilter").show();
  }
  onScrollData(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight && this.customerCount <= this.customerMaxCount) {
      this.customerCount = this.customerCount + 25;
      let json = {
        "Search": this.searchCustomer,
        "Count": this.customerCount,
        "Direction": this.sortdirection,
        "SortType": this.sortType
      };
      this.getCustomersData(JSON.stringify(json), 1);
      // $("div[id^=threeNo] button").prop('disabled', false);
      // this._pluginservice.UnCheckAll(this.customerData);
    }
  }
  getSearchData(search) {
    this.searchCustomer = (search && (search != "'" || search != "/")) ? search : '';
    this.customerCount = 0;
    this.sortType = (this.searchCustomer) ? 'name_' : 'createdate';
    let json = {
      "Search": this.searchCustomer,
      "Count": this.customerCount,
      "Direction": (this.searchCustomer) ? 'D' : 'A',
      "SortType": this.sortType
    };
    $("div[id^=threeNo] button").prop('disabled', false);
    this.getCustomersData(JSON.stringify(json), 0)
    //this._pluginservice.masterSelectedRefresh('customerBulk', []);
    //this._pluginservice.checkedList = [];
  }
  getCustomersData(json, ope): void {
    this.loading = true;
    let GridParametrs: any = {};
    GridParametrs.json = json;

    this.reqRes.postData('/People/GetCustomersData', GridParametrs).subscribe(data => {
      this.loading = false;
      this.customerData = (ope == 0) ? this.customerData = data.data : this.customerData.concat(data.data);
      this.customerMaxCount = data.cnt;
      //this._pluginservice.masterSelectedRefresh('customerBulk');
      if (this._pluginservice.checkedList.length == 1) {
        setTimeout(() => {
          $("#threeNo" + this.checkItemId + " button").prop('disabled', false);
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      } else if (this._pluginservice.checkedList.length > 1) {
        setTimeout(() => {
          $("div[id^= 'threeNo'] button").prop('disabled', true);
        }, 500)
      }

    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error); this.loading = false;
      })
  }
  checkItemId: number = 0;
  getCheckedId(id) {
    this.checkItemId = id;
  }
  changeStatus(param, id, status, param2): void {
    this.spinner = true;
    if (param2 == 'changeStatus') {
      status = status ? 1 : 0;
    }
    let statusObj: any = {};
    statusObj.param = param;
    statusObj.id = id;
    statusObj.status = status;
    this.reqRes.postData('/Global/ChangeStatus', statusObj).subscribe(data => {
      if (param == 'customerList' && status == -1) {
        let index = this.customerData.findIndex(item => item.id == id);
        this.customerData.splice(index, 1);
        toastr.success('Done!', '', { timeOut: 1000 });
      }
      else if (param == 'customerList') {
        toastr.success('Done!', '', { timeOut: 1000 });
      }
      this.spinner = false;
    }, error => {
      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  modalDelete(delId, delType, delType2, bulkArray) {
    this.spinner = false;
    this._pluginservice.snipperFromBulk = false;
    this.delId = delId;
    this.delType = delType;
    this.delType2 = delType2;
    this.delBulkArray = bulkArray;
    if (bulkArray.length == 0) {
      this.delCount = 1;
    } else {
      this.delCount = this._pluginservice.checkedList.length;
    }
    $('#modalDelete').modal('show');
  }

  deleteItem(id, type, type2, array) {
    $("div[id^=threeNo] button").prop('disabled', false);
    $('#modalDelete').modal('hide');
    if (array.length == 0) {
      this.changeStatus(type, id, -1, type2);
    } else {
      this._pluginservice.bulkActions(array[0]);
      let $this = this;
      this._pluginservice.checkedList.forEach(function (val2, key2) {
        $this.customerData.forEach(function (val, key) {
          if (val.id == val2.id) {
            $this.customerData.splice(key, 1);
          }
        })
      })
    }
    this._pluginservice.masterSelectedRefresh('customerBulk');
  }

  modalDeleteClose() {
    $('#modalDelete').modal('hide');
  }
  customerObj: any = {};
  modalCustomerHeader = '';
  editCustomer(id) {
    this.spinner = false;
    this.reqRes.getData('/People/GetCustomerDetailData/' + id).subscribe(data => {
      this.customerObj = data;
      if (id == 0) {
        this.modalCustomerHeader = 'Customer information';
      }
      else {
        this.modalCustomerHeader = 'Edit customer information';
      }
      $('#modalCustomer').modal('show');
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      })
  }
  saveCustomer() {
    this.spinner = true;
    $('#btnSaveCustomer').attr("disabled", "disabled");
    this.reqRes.postData('/People/CustomerOperation', this.customerObj).subscribe(data => {
      setTimeout(function () {
        $('#btnSaveCustomer').removeAttr("disabled");
      }, 1000)
      toastr.success('Done!', '', { timeOut: 1000 });
      $('#modalCustomer').modal('hide');
      if (this.customerObj.id == 0) {
        this.customerData.unshift(data);
      }
      else {
        let index = this.customerData.findIndex(item => item.id == this.customerObj.id);
        this.customerData[index] = data;
      }
      this.spinner = false;
    }, error => {
      toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
      console.log(error)
    })
  }
  modalCustomerClose() {
    $('#modalCustomer').modal('hide');
  }
}
