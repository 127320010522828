import { Directive, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
declare var $: any;
@Directive({
  selector: '[appMinnumberone]'
})
export class MinnumberoneDirective {

  constructor(private element: ElementRef, private ngModel: NgModel) { }
  ngOnInit() {
    let that = this;
    $(that.element.nativeElement).bind("click", function () {
      $(that.element.nativeElement).select();
    });
    $(that.element.nativeElement).bind("focusout", function () {
      if (!that.ngModel.value) {
        that.ngModel.update.emit(1);
      }
    });
    $(that.element.nativeElement).bind("keyup", function () {
      if (that.ngModel.value < 1) {
        that.ngModel.update.emit(1);
      }
    });
  }

}
