import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PluginService } from '../../service/plugin.service';
import { ReqResService } from '../../service/req-res.service';
declare var $: any;
declare var window: any;
declare var toastr: any;
@Component({
  selector: 'app-invoiceprint',
  templateUrl: './invoiceprint.component.html',
  styleUrls: ['./invoiceprint.component.css']
})
export class InvoiceprintComponent implements OnInit {

  id: number = 0;
  invoiceObj: any = {};
  constructor(private titleService: Title,private router: Router, private route: ActivatedRoute, private reqRes: ReqResService, private pluginservice: PluginService) {
    this.route.paramMap.subscribe(paramMap => {
      this.id = Number(paramMap.get('id'));
      this.getInvoiceDetailData(this.id);
    });
  }
  ngOnInit(): void {}
  getInvoiceDetailData(id) {
    this.reqRes.getData('/Invoice/GetInvoiceDetail/' + id).subscribe(data => {
      this.invoiceObj = data;
      this.titleService.setTitle(this.invoiceObj.invoiceName);
      setTimeout(function () {
        window.print();
      }, 1500)
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error);
      })
  }

}
