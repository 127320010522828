import { Component, OnInit } from '@angular/core';
import { ReqResService } from '../../service/req-res.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormBuilder, FormGroup, Validators, FormControl, } from '@angular/forms';
import { AuthService } from '../../service/auth/auth.service'
import { map, first } from 'rxjs/operators';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})

export class AccountSettingsComponent implements OnInit {
  //variable
  userData: any = [];
  formValue: any;
  userForm: FormGroup;
  submitted = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageData: any = '';
  showcroppedope: any = 0;
  imageSrc: string;
  errorImage: string;
  errorMessage: string;
  localStorageData: any = [];
  spinner: boolean = false;
  //
  constructor(private reqRes: ReqResService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) { }
  ngOnInit() {
    this.userForm = this.formBuilder.group({
      ope: 1,
      firstName: ['', Validators.required],
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      lastName: ['', Validators.required],
      phone: [''],
      imgPath: ['']
    });
    this.getUserData(1);
  }
  get userFormControl() { return this.userForm.controls; }
  //user bind
  getUserData(type) {
    this.reqRes.getData('/Settings/GetUserData/' + type).subscribe(data => {
      this.userData = data;
      this.imageSrc = data[0].imgPath;
      this.userForm.setValue({
        ope: 1,
        firstName: data[0].firstName,
        email: data[0].email,
        lastName: data[0].lastName,
        phone: data[0].phone,
        imgPath: ''
      });
      this.spinner = false;
    },
      error => {
        toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        console.log(error)
      })
  }
  //fileUpload start
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (event.target.files[0].type.includes("image/")) {
      $("#modal_file").modal('show');
      this.errorImage = '';
    } else {
      this.userForm.patchValue({
        imgPath: ' '
      })
      this.errorImage = 'Please input valid image format '
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  SelectImage() {
    this.spinner = true;
    this.imageSrc = this.croppedImage;
    $("#modal_file").modal('hide');
    this.spinner = false;
  }

  //user post
  UserOperation(userDetails) {
    return this.reqRes.postData('/Settings/UserOperation', userDetails).pipe(map(response => {
      return response;
    }))
  };

  onSubmit() {
    this.spinner = true;
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    };

    this.userForm.patchValue({
      imgPath: (this.imageSrc.length > 300) ? this.imageSrc : this.authService.image()
    })

    this.formValue = this.userForm.value
    this.UserOperation(this.formValue)
      .pipe(first())
      .subscribe(
        data => {
          let localStorage = data;
          this.spinner = false;
          this.authService.setUserData(localStorage);
          AppLayoutComponent._firstName = data[0].firstName;
          AppLayoutComponent._lastName = data[0].lastName;
          AppLayoutComponent._image = data[0].imgPath;
          toastr.success("Done!");
          this.getUserData(1);
        },
        err => {
          toastr.error("​Oops! Something went wrong", '', { timeOut: 1000 });
        },
        () => {
        });
  }
}


