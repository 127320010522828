
<div class="card mt-3">
  <div [style.opacity]="loading ? '0' : '1'">
    <div class="card-header">
      <div class="row align-items-center justify-content-between">
        <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
          <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Customers</h5>
        </div>
        <div class="col-8 col-sm-auto ml-auto text-right pl-0">
          <div id="divSearchFilter" class="d-none position-relative mr-2" (click)="searchInputOperation()">
            <input type="search" id="inputSearchFilter" class="form-control form-control-sm" [(ngModel)]="searchCustomer" (focusout)="closeFilter()" (input)="getSearchData(searchCustomer)"
                   placeholder="search..." autocomplete="off" appTrimLeft />
          </div>
          <button class="btn rounded mr-2 border btn-sm search-filter-btn" id="btnSearchFilter" data-toggle="tooltip" data-placement="top" title="Search" (click)="openFilter()">
            <span>
              <i class="fas fa-search"></i>
            </span>
          </button>
          <button class="btn btn-success btn-sm" [hidden]="_pluginservice.checkedList?.length!=0"
                  data-toggle="modal" type="button" (click)="editCustomer(0)">
            <span class="fas fa-plus d-inline-block d-sm-none"
                  data-fa-transform="shrink-3 down-2"></span>
            <span class="d-none d-sm-inline-block">
              Create new
            </span>
          </button>
          <button class="btn btn-sm btn-danger" [hidden]="_pluginservice.checkedList?.length==0" (click)="modalDelete(0,'','',['customerStatus'])"
                  data-toggle="tooltip" data-placement="top" title="Delete Lead" id="listviewBulkActions">
            <span class="fas fa-trash-alt d-block d-lg-none"
                  data-fa-transform="shrink-3 down-2"></span>
            <span class="d-none d-lg-inline-block">
              Delete
            </span>
          </button>

        </div>
      </div>
    </div>
  </div>
  <div [style.opacity]="loading ? '0' : '1'">
    <div class="card-body p-0 card-scroll" (scroll)="onScrollData($event)">
      <div class="falcon-data-table">
        <table class="table table-sm mb-0 table-striped  border-bottom">
          <thead class="bg-200 text-900 sticky-header">
            <tr>
              <th class="pr-1 align-middle w-30px">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input"
                         id="customerBulk" type="checkbox"
                         [(ngModel)]="_pluginservice.masterSelected"
                         (change)="_pluginservice.checkAll(customerData,'customerBulk')"
                         [disabled]="customerData?.length==0"
                         [attr.checked]="_pluginservice.checkedList.length==customerData?.length ? 'checked' : null"
                         [attr.indeterminate]="(_pluginservice.checkedList.length>0) && _pluginservice.checkedList.length!=customerData?.length ? 'indeterminate' : false"
                         [indeterminate]="(_pluginservice.checkedList.length>0) && _pluginservice.checkedList.length!=customerData?.length ? 'indeterminate' : false">
                  <label class="custom-control-label" for="customerBulk"></label>
                </div>
              </th>
              <th class="pr-1 align-middle cursor-pointer min-w-100px" (click)="sortType = 'name'; sortReverse = !sortReverse;sortData('name');">
                Name
                <span *ngIf="sortType == 'name' && sortReverse">
                  <i class="fas fa-sort-up"></i>
                </span>
                <span *ngIf="sortType == 'name' && !sortReverse">
                  <i class="fas fa-sort-down"></i>
                </span>
              </th>
              <th class="pr-1 pl-4 align-middle cursor-pointer min-w-150px" (click)="sortType = 'email'; sortReverse = !sortReverse;sortData('email');">
                Email
                <span *ngIf="sortType == 'email' && sortReverse">
                  <i class="fas fa-sort-up"></i>
                </span>
                <span *ngIf="sortType == 'email' && !sortReverse">
                  <i class="fas fa-sort-down"></i>
                </span>
              </th>
              <th class="pr-1 align-middle cursor-pointer min-w-200px" (click)="sortType = 'phone'; sortReverse = !sortReverse;sortData('phone');">
                Phone
                <span *ngIf="sortType == 'phone' && sortReverse">
                  <i class="fas fa-sort-up"></i>
                </span>
                <span *ngIf="sortType == 'phone' && !sortReverse">
                  <i class="fas fa-sort-down"></i>
                </span>
              </th>
              <th class="pr-1 align-middle min-w-50px">
              </th>
            </tr>
          </thead>
          <tbody id="customerTbody">
            <tr class="btn-reveal-trigger" *ngFor="let c of customerData">
              <td class="py-2 align-middle">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input checkbox-bulk-select-target" type="checkbox" id="c{{c.id}}" [(ngModel)]="c.checkboxOpe"
                         (change)="_pluginservice.isAllSelected(customerData);getCheckedId(c.id)" />
                  <label class="custom-control-label" for="c{{c.id}}"></label>
                </div>
              </td>
              <th class="py-2 align-middle white-space-nowrap">
                <a href="javascript:void(0)" (click)="editCustomer(c.id)">
                  <span *ngIf="c.name!=''">{{c.name}}</span>
                  <span *ngIf="c.name==' '" class="text-table-no-data">No Name</span>
                </a>
              </th>
              <td class="py-2 pl-4 pr-2 align-middle">
                <a *ngIf="c.email!=''" href="mailto:{{c.email}}">{{c.email}}</a>
                <span *ngIf="c.email==''" class="text-table-no-data">No Email</span>
              </td>
              <td class="py-2 align-middle">
                <a *ngIf="c.phone!=''" href="tel:{{c.phone}}">{{c.phone}}</a>
                <span *ngIf="c.phone==''" class="text-table-no-data">No Phone</span>
              </td>
              <td class="py-2 align-middle white-space-nowrap">
                <div class="dropdown text-sans-serif" id="threeNo{{c.id}}">
                  <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal mr-3"
                          type="button" id="dropdown0"
                          data-toggle="dropdown"
                          data-boundary="html"
                          aria-haspopup="true"
                          aria-expanded="false">
                    <span class="fas fa-ellipsis-h fs--1"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right border py-0"
                       aria-labelledby="dropdown0">
                    <div class="bg-white py-2">
                      <a class="dropdown-item cursor-pointer" href="javascript:void(0)" (click)="editCustomer(c.id)">Edit</a>
                      <a class="dropdown-item cursor-pointer" target="_blank" *ngIf="c.showCp" href="{{c.portalUrl}}">Client Portal</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item text-danger" href="javascript:void(0)" (click)="modalDelete(c.id,'customerList','',[])">Delete</a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="customerData?.length==0" class="w-100 text-center">
            <tr>
              <td colspan="10" class="p-2">
                <p class="text-center bold-text font-size18px m-0">No data available</p>
                <p class="text-center m-0 text-gray font-size13px">There is nothing to show you right now</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading" class="z-index1000"></ngx-loading>
</div>

<!-- Modal Delete-->
<div class="modal fade z-index10001 pr-0" style="padding-right:0!important" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     data-backdrop="static" data-keyboard="false" aria-hidden="true" (keydown.esc)="modalDeleteClose()">
  <div class="modal-dialog modal-dialog-center modal-delete-width" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <div class="modal-delete-header-icondiv">
          <i class="far fa-times-circle text-center modal-delete-circle-icon"></i>
        </div>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close"
                (click)="modalDeleteClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-center fs-20 fw-600">Are you sure?</p>
        <p class="text-muted fs-13 pl-3 pr-3 text-center">Do you really want to delete {{delCount==1 ? 'this record' : 'these records'}}? This process can not be undone.</p>
      </div>
      <div class="modal-delete-footer">
        <button class="btn btn-sm mr-2 modal-delete-footer-cancel" type="button" data-dismiss="modal" (click)="modalDeleteClose()">Cancel</button>
        <button class="btn btn-danger btn-sm" type="button" (click)="deleteItem(delId,delType,delType2,delBulkArray)">
          <div class="g_spinner" *ngIf="spinner"></div>
          <div class="g_spinner" *ngIf="_pluginservice.snipperFromBulk"></div>
          Delete
        </button>
      </div>
    </div>
  </div>
</div>
<!--Modal Customer-->
<div class="modal fade px-0" id="modalCustomer" tabindex="-1" role="dialog" aria-labelledby="modalCustomer" data-backdrop="static" data-keyboard="false"
     aria-hidden="true" (keydown.esc)="modalCustomerClose()">
  <div class="modal-dialog activity-select-modal-width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <span>{{modalCustomerHeader}}</span>
        </h5>
        <button class="close" type="button" aria-label="Close" (click)="modalCustomerClose()">
          <span class="font-weight-light" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-padding">
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label for="name">Name <span class="text-danger">*</span></label>
              <input class="form-control form-control-sm" id="name" type="text" [(ngModel)]="customerObj.name" required appTrimLeft autocomplete="off" />
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="form-group mb-1">
              <label for="leadEmail" class="fs-12 mb-0 text400">Email <span class="text-danger" *ngIf="customerObj.email &&  (email.invalid && (email.dirty || email.touched))">*</span></label>  <span *ngIf="customerObj.email &&  (email.invalid && (email.dirty || email.touched))" class="validation pull-right">Please input valid e-mail</span>
              <input class="form-control form-control-sm" id="email" name="email" type="email" [(ngModel)]="customerObj.email" title="Customer Email" tabindex="9"
                     #email="ngModel"
                     pattern="^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$"
                     required
                     autocomplete="nope" appTrimLeft />
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="form-group mb-1">
              <label for="leadPhone" class="fs-12 mb-0 text400">Phone</label>
              <input type="text" name="phone" id="phone" [(ngModel)]="customerObj.phone" class="form-control form-control-sm" mask="000-000-0000" [dropSpecialCharacters]="false" title="Customer Phone" tabindex="10" autocomplete="nope" appTrimLeft />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-falcon-default btn-sm" type="button" (click)="modalCustomerClose()">Close</button>
        <button class="btn btn-primary btn-sm" id="btnSaveCustomer" type="button" [disabled]="!customerObj.name" (click)="saveCustomer()">
          <div class="g_spinner" *ngIf="spinner"></div>
          Save
        </button>
      </div>
    </div>
  </div>
</div>




