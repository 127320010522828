import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './login/login/login.component';
import { WebleadComponent } from './client/weblead/weblead.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { AuthGuard } from './guard/authG.guard';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { UsersComponent } from './pages/users/users.component';
import { RolesComponent } from './pages/roles/roles.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { Error403Component } from './login/error403/error403.component';
import { Error404Component } from './login/error404/error404.component';
import { TradepartnersComponent } from './pages/tradepartners/tradepartners.component';
import { ErrorDataAccessComponent } from './login/error-data-access/error-data-access.component';

import { SpecodesComponent } from './pages/specodes/specodes.component';
import { InvoiceComponent } from './client/invoice/invoice.component';
import { InvoiceprintComponent } from './prints/invoiceprint/invoiceprint.component';
import { BarcodeprintComponent } from './prints/barcodeprint/barcodeprint.component';
import { InvoiceworkorderComponent } from './prints/invoiceworkorder/invoiceworkorder.component';
import { ItemsComponent } from './pages/Items/items.component';
import { WarehousemanagementComponent } from './pages/warehousemanagement/warehousemanagement.component';



const routes: Routes = [
  // App routes goes here here
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', component: HomeComponent, canActivate: [AuthGuard], pathMatch: 'full' },
      { path: 'account-settings', component: AccountSettingsComponent, canActivate: [AuthGuard] },
      { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
      { path: 'roles', component: RolesComponent, canActivate: [AuthGuard] },
      { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard] },
      { path: 'tradepartners', component: TradepartnersComponent, canActivate: [AuthGuard] },
      { path: 'specodes', component: SpecodesComponent, canActivate: [AuthGuard] },
      { path: 'items', component: ItemsComponent, canActivate: [AuthGuard] },
      { path: 'warehouse', component: WarehousemanagementComponent, canActivate: [AuthGuard] },
      
    ]
  },

  //no layout routes
  { path: 'login', component: LoginComponent },
  { path: 'error403', component: Error403Component },
  { path: 'error404', component: Error404Component },
  { path: 'error-data-access', component: ErrorDataAccessComponent },
  // otherwise redirect to home

  //no layout routes
  { path: 'client/weblead/:cmpn', component: WebleadComponent },
  { path: 'client/invoice/:id/:key', component: InvoiceComponent },
  { path: 'prints/invoice/:id', component: InvoiceprintComponent },
  { path: 'prints/invoiceworkorder/:id', component: InvoiceworkorderComponent },
  { path: 'prints/barcode/:id', component: BarcodeprintComponent },
  
  { path: '**', redirectTo: '' }
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
